import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import medicalServiceCompaniesMain from '../../images/medical-service-companies-main.jpg'
import changjiang from '../../images/changjiang.png'
import hezuolanmu from '../../images/hezuolanmu.png'
import libin from '../../images/libin.png'
import zhuxuejun from '../../images/zhuxuejun.png'
import './index.scss';
class Latestdevelopments4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >“麦”步全球！优麦医生APP海外应用市场上架</div>
                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                <p>6月30日，致力于科技创新改善皮肤健康的优麦科技宣布，旗下皮肤医生专属的学习平台“优麦医生APP”正式于海外应用市场上架，在为全球华人皮肤科医生提供更多优质学术内容的同时，亦在助力中国皮肤领域学术“出海”。</p> 
                                <p>On June 30th, Umer Technology, a company dedicated to scientific and technological innovation to improve skin health, announced that the Umer Doctor App, an exclusive learning platform for dermatologists, has been officially launched on international app stores. This move not only enables Chinese doctors around the world to have broader access to high-quality academic resources in their field, but also helps promote Chinese dermatology academia globalization.</p>
                            </div>
                            <div className='latestdevelopments_main_div'>
                                <p>优麦医生作为即将在新加坡召开的第25届世界皮肤科大会（WCD2023）中国独家媒体战略合作伙伴，在“出征”大会前夕完成海外应用市场上架，亦是为满足海外华人皮肤科医生及产业人士的学习、协作等应用所需。</p> 
                                <p>As the Exclusive Strategic Media Partner in China of 25th World Congress of Dermatology (WCD2023) to be held in Singapore, the Umer Doctor App is now available on international app stores ahead of the summit. This is to meet the learning and collaboration needs of overseas Chinese dermatologists and industry professionals.</p>
                            </div>

                            <img src='https://cdn.umer.com.cn/umer-doctor/20230713/16892198889674895.png' alt=""  className='latest_image_tips'/>

                            <div className='latestdevelopments_main_div'>
                                <p>自2015年正式运营以来，优麦医生APP始终专注于皮肤科医生在线教育，目前平台已经覆盖全中国95%以上的皮肤科医生。</p> 
                                <p>Since its launch in 2015, the Umer Doctor App has focused on online education for dermatologists, and currently covers over 95% of dermatologists in China.</p>
                            </div>

                            <div className='latestdevelopments_main_div'>
                                <p>此番，“优麦医生APP”海外应用市场上架，面向全球皮肤科及相关学科医生，全方位满足用户的专业学习需求。目前“优麦医生APP”内的直播课程、系列专栏已汇聚了大量内容创作者， 源源不断的原创内容，为广大皮肤科医生的在线学习提供了丰富选择。国内外学术会议的专场直播、话题讨论、热点分享，以更为便捷的途径为用户提供学习“刚需”。今年年初上线的短视频模块，更是让碎片化时间“充电”的应用场景精准落地。</p> 
                                <p>The overseas launch of the Umer Doctor App aims to engage dermatologists and other related physicians around the world, offering a comprehensive solution to their professional learning needs. The app’s live courses and series columns have already gathered a large number of content creators that providing a continuous supply of original content for online learning. It also offers convenient access to specialized live broadcasts, topic discussions, and hot topics shared from domestic and international academic conferences, to provide users with “just what they need”. In addition, with the short video module launched at the beginning of this year, users can learn in their fragmented time.</p>
                            </div>

                            <div className='latestdevelopments_main_div'>
                                <p>优麦科技创始人、CEO常江表示，互联网医疗及医生在线学习模式的迅速发展，既是时代的指向也是实践的选择。“优麦医生APP”海外上线，在更大范围推动皮肤科学术发展与交流的同时，也标志着优麦科技在皮肤领域整体战略再次迈出关键一步。</p> 
                                <p>As Founder and CEO of Umer Technology, Chang Jiang says that the rapid developing online learning and internet medicine are both a sign of the times and a choice of practice for doctors. The launch of the Umer Doctor App overseas not only advances the field of dermatology and communication among peers on a larger scale, but also marks another key step in Umer Technology's overall strategy for the industry.</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;