import React, { Component } from "react";
import artificialintelligencebg11 from "../../images/artificialintelligencebg11.jpg";
import ReactHtmlParser from "html-react-parser";
import "./index.scss";

const html1 = `<h1 class="rich_media_title " id="activity-name" style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
“麦”进星时代！第五届皮肤科在线教育卓越影响力年度盛典举行&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
</h1>
<div class="rich_media_content js_underline_content
                   defaultNoSetting
        " id="js_content" style="color: rgb(0, 0, 0); font-family: sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; visibility: visible;">
<section style="box-sizing: border-box; font-style: normal; font-weight: 400; text-align: justify; font-size: 16px; visibility: visible;">
    <section powered-by="xiumi.us" style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box; visibility: visible;">
        
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
        <br style="box-sizing: border-box; visibility: visible;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box; visibility: visible;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
            <img class="rich_pages wxw-img" data-ratio="0.6712962962962963" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="2" src="https://cdn.umer.com.cn/umer-doctor/20240126/6ad56cc8-b50c-4583-a5df-3c13383ab2e41706235483093.jpeg" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box; visibility: visible;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box; visibility: visible;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box; visibility: visible;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                        <span style="color: #FFFFFF; box-sizing: border-box; visibility: visible;">14项总共65个大奖闪亮揭晓，150余位皮肤科专家齐聚上海，5,000余位皮肤科医生在线互动。<br style="box-sizing: border-box; visibility: visible;"/></span>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                        <br style="box-sizing: border-box; visibility: visible;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                        <span style="color: #FFFFFF; box-sizing: border-box; visibility: visible;">1月20日，由优麦医生发起并主办的“金麦豆之夜——第五届皮肤科在线教育卓越影响力年度盛典暨2023皮科热点高峰论坛” 顺利举行。本次活动共分为“皮科热点高峰论坛”及“颁奖典礼”两大环节，线上线下同步开启。</span>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                        <br style="box-sizing: border-box; visibility: visible;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box; visibility: visible;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; overflow: hidden; align-self: flex-start; box-sizing: border-box; visibility: visible;">
            <svg style="display: block; box-sizing: border-box; visibility: visible;" viewbox="0 0 1080 720" xml:space="default" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                <g style="box-sizing: border-box; visibility: visible;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box; visibility: visible;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062359750844560.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                </g>
                <g style="box-sizing: border-box; visibility: visible;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box; visibility: visible;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062360617712743.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animatetransform type="translate" fill="freeze" dur="6.4s" values="1080 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0" restart="never" keysplines="0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1" keytimes="0;0.13;0.25;0.38;0.50;0.63;0.75;0.87;1.00" calcmode="spline" attributename="transform" repeatcount="indefinite" style="box-sizing: border-box;"></animatetransform>
                </g>
                <g style="box-sizing: border-box; visibility: visible;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box; visibility: visible;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062360824154610.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animatetransform type="translate" fill="freeze" dur="6.4s" values="1080 0;1080 0;1080 0;0 0;0 0;0 0;0 0;0 0;0 0" restart="never" keysplines="0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1" keytimes="0;0.13;0.25;0.38;0.50;0.63;0.75;0.87;1.00" calcmode="spline" attributename="transform" repeatcount="indefinite" style="box-sizing: border-box;"></animatetransform>
                </g>
                <g style="box-sizing: border-box; visibility: visible;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box; visibility: visible;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062361025778444.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animatetransform type="translate" fill="freeze" dur="6.4s" values="1080 0;1080 0;1080 0;1080 0;1080 0;1080 0;1080 0;0 0;0 0" restart="never" keysplines="0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1" keytimes="0;0.13;0.25;0.38;0.50;0.63;0.75;0.87;1.00" calcmode="spline" attributename="transform" repeatcount="indefinite" style="box-sizing: border-box;"></animatetransform>
                </g>
                <g style="box-sizing: border-box; visibility: visible;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box; visibility: visible;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062359750844560.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animatetransform type="translate" fill="freeze" dur="6.4s" values="1080 0;1080 0;1080 0;1080 0;1080 0;1080 0;1080 0;0 0;0 0" restart="never" keysplines="0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1" keytimes="0;0.13;0.25;0.38;0.50;0.63;0.75;0.87;1.00" calcmode="spline" attributename="transform" repeatcount="indefinite" style="box-sizing: border-box;"></animatetransform>
                </g>
            </svg>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <span style="color: #FFFFFF; box-sizing: border-box;">今年“金麦豆之夜”的主题是“麦进星时代”。<br style="box-sizing: border-box;"/></span>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <span style="color: #FFFFFF; box-sizing: border-box;">如果说进步是一种提高，那么进化就是一种演变。回首刚刚过去的一年，包括优麦医生在内的整个中国皮肤科领域，一直在变，却初心不变。我们携手进步，努力进化，心怀热爱，进无止境。</span>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.25833333333333336" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="3" src="https://cdn.umer.com.cn/umer-doctor/20240126/7a74e88d-be39-4860-a861-4079bb9cdf8f1706235483091.png" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <span style="text-align: justify; box-sizing: border-box;">【携手共进，继续协同创新】</span>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        在线教育，为皮肤科医生的学习提供了丰富的内容。颁奖典礼现场，多位皮肤科专家发表致辞，在肯定优麦医生对我国皮肤科事业发展助力的同时，也期待优麦医生能与学会组织、皮肤科专家、行业人员深化合作，带来更多创新与拓展。
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="4" src="https://cdn.umer.com.cn/umer-doctor/20240126/ca47c761-2723-4293-b2e6-b4074a93215e1706235483086.jpeg" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        中国非公立医疗机构协会皮肤专业委员会终身名誉主任委员、复旦大学附属华山医院郑志忠教授在开场致辞中说道，“优麦医生成立至今超过8年的时间，这8年多来，我见证了这个平台一路成长。更难得的是，在这个过程中，还不忘初心，一直在为中国皮肤科医生的价值实现而努力。最近，大家都在看电视剧《繁花》，中国皮肤科的发展一路繁花，优麦医生在皮肤科医生在线学习模式上的不断创新也呈现出一路繁花。祝愿中国皮肤科，越来越好。”<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="5" src="https://cdn.umer.com.cn/umer-doctor/20240126/65f6e72e-a5d2-40bd-bef4-c90ae1e7b1f21706235483104.jpeg" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        中国医师协会皮肤科医师分会名誉会长、北京大学第一医院朱学骏教授表示，优麦医生为皮肤科医生提供了网上学习的平台，尤其是在疫情期间，使得皮肤科医生始终能够关注国内外皮肤科前沿资讯与突出成果。优麦医生成立8年多，已经吸引全中国95%的皮肤科医生注册使用了优麦医生APP。2023年，优麦医生用户总数超过21万人，用户学习时长合计超过7000万分钟。希望在新的一年，我们的皮肤科专家跟优麦医生有更多深入的合作，呈现出更多、更好的、受皮肤科医生欢迎的学习内容。<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="6" src="https://cdn.umer.com.cn/umer-doctor/20240126/4e095b50-a96e-4b4a-a937-b0833eae38611706235483090.jpeg" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        中国中西医结合学会皮肤性病专业委员会主任委员、同济大学附属第十人民医院顾军教授表示，皮肤科建设既要传承也要创新。没有创新，就没有学科的发展。优麦医生也一直在创新，在2023年，优麦医生推出了全新的“年会U直播”栏目，这个栏目参与了国内的各大年会，也参与了在新加坡举办的2023世界皮肤科大会，这个创新栏目第一次登场，是在去年的全国中西医结合皮肤性病学术年会上。希望在2024年，优麦医生能够继续开拓创新，把明星栏目越做越好，同时也开创出更多的新栏目。<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.6666666666666666" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="7" src="https://cdn.umer.com.cn/umer-doctor/20240126/27793687-5c42-4a89-9a06-7b250ca5ba641706235483148.jpeg" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        上海市皮肤病医院/同济大学附属皮肤病医院院长李斌教授表示，很高兴来到金麦豆之夜年度盛典。上海市皮肤病医院与优麦医生是战略合作伙伴，在产学研全方位开展深度合作。2024年，上海市皮肤病医院也将与优麦医生继续携手，策划推出化妆品主题栏目，并积极探索化妆品尤其是功效性护肤品的医研共创之路。<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;"></section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <span style="color: #FFFFFF; box-sizing: border-box;">优麦科技创始人兼CEO常江表示，自2015年创立至今，优麦医生始终不忘初心，充分发挥在线教育的“桥梁”作用，有效衔接皮肤科医生、行业人员、企业品牌创新发展的各个环节，实现创新生态的构建与高效运转。“回望刚刚过去的2023年，优麦医生聚焦在了‘进化’二字，我们推出了‘年会U直播’这样的创新栏目，落地全国各大年会场景；我们成为了第25届世界皮肤科大会（WCD）的中国独家战略媒体合作伙伴，也成为了第32届欧洲皮肤病与性病学会（EADV）支持媒体。优麦的每一步，都要感谢各位专家、老师的支持，也要感谢企业合作伙伴的‘共同进化’。”</span><br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.25833333333333336" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="9" src="https://cdn.umer.com.cn/umer-doctor/20240126/57b9e2d9-3a1f-4d6c-bc85-0451a6d4d5ae1706235483088.png" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        【硕果累累，皮肤科在线教育星河滚烫】
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        麦田守望者<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        专业认可与荣誉
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; overflow: hidden; align-self: flex-start; box-sizing: border-box;">
            <svg style="display: block;box-sizing: border-box;" viewbox="0 0 1080 720" xml:space="default" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062378557422517.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062379022149599.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;1;1;1;1;1;1;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062379431834517.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;1;1;1;1;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062379609029339.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;1;1;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062379817533228.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062380048196693.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062380252914496.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;0;0;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062380410923382.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;0;0;0;0;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062378557422517.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
            </svg>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        携手共进<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        “麦”向未来
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; overflow: hidden; align-self: flex-start; box-sizing: border-box;">
            <svg style="display: block;box-sizing: border-box;" viewbox="0 0 1080 720" xml:space="default" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062380776825329.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062381059214198.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;1;1;1;1;1;1;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706238128284816.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;1;1;1;1;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062381494526312.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;1;1;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062381687041405.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;1;1;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706238189470720.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;1;1;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062382196129437.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;1;1;0;0;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062382419648074.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;1;1;0;0;0;0;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
                <g style="box-sizing: border-box;">
                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062380776825329.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                    </foreignobject>
                    <animate begin="0s" fill="freeze" dur="9.6s" values="1;1;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0" restart="never" keytimes="0;0.06;0.13;0.19;0.25;0.31;0.38;0.44;0.50;0.56;0.62;0.69;0.75;0.81;0.87;0.94;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                </g>
            </svg>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 15px 26px; box-sizing: border-box;">
            <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;"></section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                        【年度榜单，引领创作风向】
                    </p>
                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                        携手进步，努力进化<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                        心怀热爱，进无止境
                    </p>
                </section>
            </section>
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="7.631481481481481" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="11" src="https://cdn.umer.com.cn/umer-doctor/20240126/29a7a600-3b67-4afd-ac61-e8ded91dde7d1706235483071.png" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="8.55" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="12" src="https://cdn.umer.com.cn/umer-doctor/20240126/cf05c34f-0e59-45ff-9355-7a9c7cc87ab01706235483166.png" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: -1px 0px 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.25833333333333336" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="13" src="https://cdn.umer.com.cn/umer-doctor/20240126/1d06bf95-ceda-4f04-a3a5-49e1d988e3b21706235483082.png" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                        【高峰论坛，直击皮科热点】
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        皮科热点高峰论坛是“金麦豆”年度盛典的保留节目。本次论坛，众多皮肤科专家合力呈现四大主题：“皮肤科热点特邀演讲”、“痤疮/玫瑰痤疮的研究新进展与诊疗思考”、“医美抗衰新思路与功效护肤的产业发展演变”及“炎症及免疫性皮肤病全球视角与治疗对策”。
                    </p>
                </section>
            </section>
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
            <section powered-by="xiumi.us" style="margin: 10px 0px; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; vertical-align: middle; width: 103.188px; line-height: 0; align-self: center; flex: 0 0 auto; box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="margin: 0px; line-height: 0; box-sizing: border-box;">
                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 41.2656px; height: auto; box-sizing: border-box;">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewbox="0 0 81.5 81.5" style="max-width: 100%;width: 100%;box-sizing: border-box;" width="100%">
                                <path d="M40.7,0C18.2,0,0,18.2,0,40.7s18.2,40.7,40.7,40.7c22.5,0,40.7-18.2,40.7-40.7S63.2,0,40.7,0z   M54.3,45.6h-17l5.6,5.6c2,2,2,5.4,0,7.4v0c-2,2-5.4,2-7.4,0L21.2,44.4c-2-2-2-5.4,0-7.4l14.1-14.1c2-2,5.4-2,7.4,0c2,2,2,5.4,0,7.4  l-5.9,5.9h17.4c2.6,0,4.7,2.1,4.7,4.7C59,43.4,56.9,45.6,54.3,45.6z" fill="#ffca00" style="box-sizing: border-box;"></path>
                            </svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block; vertical-align: middle; width: auto; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; align-self: center; box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="color: rgb(255, 202, 0); line-height: 1; font-size: 13px; box-sizing: border-box;">
                        <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                            左右滑动查看更多
                        </p>
                    </section>
                </section>
                <section style="display: inline-block; vertical-align: middle; width: 103.188px; line-height: 0; align-self: center; flex: 0 0 auto; box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="margin: 0px; transform: rotateY(180deg); line-height: 0; box-sizing: border-box;">
                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 41.2656px; height: auto; box-sizing: border-box;">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewbox="0 0 81.5 81.5" style="max-width: 100%;width: 100%;box-sizing: border-box;" width="100%">
                                <path d="M40.7,0C18.2,0,0,18.2,0,40.7s18.2,40.7,40.7,40.7c22.5,0,40.7-18.2,40.7-40.7S63.2,0,40.7,0z   M54.3,45.6h-17l5.6,5.6c2,2,2,5.4,0,7.4v0c-2,2-5.4,2-7.4,0L21.2,44.4c-2-2-2-5.4,0-7.4l14.1-14.1c2-2,5.4-2,7.4,0c2,2,2,5.4,0,7.4  l-5.9,5.9h17.4c2.6,0,4.7,2.1,4.7,4.7C59,43.4,56.9,45.6,54.3,45.6z" fill="#ffca00" style="box-sizing: border-box;"></path>
                            </svg>
                        </section>
                    </section>
                </section>
            </section>
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
            <section powered-by="xiumi.us" style="display: inline-block; width: 1032px; vertical-align: top; overflow-x: auto; scroll-snap-type: x mandatory; white-space: nowrap; box-sizing: border-box;">
                <section powered-by="xiumi.us" style="display: inline-block; vertical-align: top; width: 1032px; scroll-snap-align: center; white-space: normal; overflow: hidden; transform: rotate(0deg); box-sizing: border-box; max-width: 100%;">
                    <section style="text-align: justify; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; width: 1032px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 30px 0px; text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgb(255, 255, 255) -12px -12px 24px; flex: 0 0 auto; height: auto; border-width: 0px; border-radius: 20px 20px 0px; border-style: none; border-color: rgb(62, 62, 62); align-self: flex-start; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgba(163, 177, 198, 0.5) 12px 12px 24px; border-width: 0px; border-radius: 20px; border-style: none; border-color: rgb(62, 62, 62); overflow: hidden; background-color: rgb(1, 0, 0); align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                                            <section powered-by="xiumi.us" style="margin: 20px 0px; box-sizing: border-box;">
                                                <section style="color: rgb(255, 255, 255); text-align: justify; padding: 0px 22px; font-size: 14px; box-sizing: border-box;">
                                                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <span style="box-sizing: border-box;">特邀演讲专场</span>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <span style="box-sizing: border-box;"><br style="box-sizing: border-box;"/></span>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <span style="box-sizing: border-box;">中国医师协会皮肤科医师分会名誉会长、北京大学第一医院终身教授朱学骏教授分享了《加强自身免疫疱病的慢病管理意识》；北京大学第一医院的涂平教授的演讲主题是《皮肤科年轻医生的治疗决策优选》；山东第一医科大学附属皮肤病医院刘红教授讲解《药物不良反应的精准诊疗》；四川大学华西医院蒋献教授带来《玫瑰痤疮诊疗难点与策略进展》；上海交通大学医学院附属瑞金医院曹华教授解读《皮肌炎诊断标准的演进》；上海市皮肤病医院/同济大学附属皮肤病医院于宁副教授分享《银屑病生物治疗的热点与挑战》。</span>
                                                    </p>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                            <section powered-by="xiumi.us" style="display: flex; flex-flow: row; margin: 0px 0px 10px; justify-content: flex-start; box-sizing: border-box;">
                                <section style="display: inline-block; width: 206.391px; vertical-align: top; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; align-self: flex-start; height: auto; line-height: 0; padding: 0px; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="text-align: right; isolation: isolate; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 206.391px; height: 4px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 10px; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(255, 202, 0); box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px inset; box-sizing: border-box;">
                                            <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                <section powered-by="xiumi.us" style="display: inline-block; vertical-align: top; width: 1032px; scroll-snap-align: center; white-space: normal; overflow: hidden; transform: rotate(0deg); box-sizing: border-box; max-width: 100%;">
                    <section style="text-align: justify; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; width: 1032px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 30px 0px; text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgb(255, 255, 255) -12px -12px 24px; flex: 0 0 auto; height: auto; border-width: 0px; border-radius: 20px 20px 0px; border-style: none; border-color: rgb(62, 62, 62); align-self: flex-start; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgba(163, 177, 198, 0.5) 12px 12px 24px; border-width: 0px; border-radius: 20px; border-style: none; border-color: rgb(62, 62, 62); overflow: hidden; background-color: rgb(1, 0, 0); align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                                            <section powered-by="xiumi.us" style="margin: 20px 0px; box-sizing: border-box;">
                                                <section style="color: rgb(255, 255, 255); text-align: justify; padding: 0px 22px; font-size: 14px; box-sizing: border-box;">
                                                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        痤痤/玫瑰痤疮专场<br style="box-sizing: border-box;"/>
                                                    </p>
                                                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <br style="box-sizing: border-box;"/>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        上海交通大学医学院附属仁济医院鞠强教授分享了《痤疮机制再认识与诊疗进展》；中南大学湘雅医院李吉教授讲解了《如何调控玫瑰痤疮的神经血管功能紊乱》。圆桌论坛我们邀请到了四川大学华西医院蒋献主任医师；上海交通大学医学院附属第九人民医院徐慧主任医师；重庆医科大学附属第一医院陈瑾主任医师；南京医科大学第一附属医院许阳主任医师；就《痤疮/玫瑰痤疮的研究新进展与诊疗思考》进行了讨论。圆桌论坛由四川大学华西医院舒程惠迪主治医师担任主持。
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <br style="box-sizing: border-box;"/>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <br style="box-sizing: border-box;"/>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <br style="box-sizing: border-box;"/>
                                                    </p>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                            <section powered-by="xiumi.us" style="display: flex; flex-flow: row; margin: 0px 0px 10px; justify-content: flex-start; box-sizing: border-box;">
                                <section style="display: inline-block; width: 412.797px; vertical-align: top; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; align-self: flex-start; height: auto; line-height: 0; padding: 0px; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="text-align: right; isolation: isolate; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 412.797px; height: 4px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 10px; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(255, 202, 0); box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px inset; box-sizing: border-box;">
                                            <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                <section powered-by="xiumi.us" style="display: inline-block; vertical-align: top; width: 1032px; scroll-snap-align: center; white-space: normal; overflow: hidden; transform: rotate(0deg); box-sizing: border-box; max-width: 100%;">
                    <section style="text-align: justify; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; width: 1032px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 30px 0px; text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgb(255, 255, 255) -12px -12px 24px; flex: 0 0 auto; height: auto; border-width: 0px; border-radius: 20px 20px 0px; border-style: none; border-color: rgb(62, 62, 62); align-self: flex-start; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgba(163, 177, 198, 0.5) 12px 12px 24px; border-width: 0px; border-radius: 20px; border-style: none; border-color: rgb(62, 62, 62); overflow: hidden; background-color: rgb(1, 0, 0); align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                                            <section powered-by="xiumi.us" style="margin: 20px 0px; box-sizing: border-box;">
                                                <section style="color: rgb(81, 91, 105); text-align: justify; padding: 0px 22px; box-sizing: border-box;">
                                                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <span style="font-size: 14px; color: #FFFFFF; box-sizing: border-box;">医美抗衰/功效护肤专场</span>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <br style="box-sizing: border-box;"/>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <span style="font-size: 14px; color: #FFFFFF; box-sizing: border-box;">中国人民解放军空军特色医学中心刘玮教授为大家解读《2023中国皮肤科医生与功效性护肤品产业发展洞察报告》。随后的圆桌论坛，复旦大学附属华山医院郑志忠教授；中国人民解放军空军特色医学中心刘玮教授；中山大学附属第三医院赖维教授；重庆医科大学附属第一医院陈瑾教授；杭州颜术时尚医疗美容诊所宋为民教授；上海嘉会国际医院嘉会医疗丁蕙琳博士；复旦大学人类表型组研究院马彦云博士就医美抗衰新思路与功效护肤的产业发展演变这一话题展开热烈的讨论。</span>
                                                    </p>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                            <section powered-by="xiumi.us" style="display: flex; flex-flow: row; margin: 0px 0px 10px; justify-content: flex-start; box-sizing: border-box;">
                                <section style="display: inline-block; width: 619.188px; vertical-align: top; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; align-self: flex-start; height: auto; line-height: 0; padding: 0px; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="text-align: right; isolation: isolate; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 619.188px; height: 4px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 10px; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(255, 202, 0); box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px inset; box-sizing: border-box;">
                                            <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                <section powered-by="xiumi.us" style="display: inline-block; vertical-align: middle; width: 1032px; scroll-snap-align: center; white-space: normal; overflow: hidden; transform: rotate(0deg); box-sizing: border-box; max-width: 100%;">
                    <section style="text-align: justify; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; width: 1032px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 30px 0px; text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgb(255, 255, 255) -12px -12px 24px; flex: 0 0 auto; height: auto; border-width: 0px; border-radius: 20px 20px 0px; border-style: none; border-color: rgb(62, 62, 62); align-self: flex-start; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 980.391px; vertical-align: top; box-shadow: rgba(163, 177, 198, 0.5) 12px 12px 24px; border-width: 0px; border-radius: 20px; border-style: none; border-color: rgb(62, 62, 62); overflow: hidden; background-color: rgb(1, 0, 0); align-self: flex-start; flex: 0 0 auto; box-sizing: border-box;">
                                            <section powered-by="xiumi.us" style="margin: 20px 0px; box-sizing: border-box;">
                                                <section style="color: rgb(255, 255, 255); text-align: justify; padding: 0px 22px; font-size: 14px; box-sizing: border-box;">
                                                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        炎症与免疫专场
                                                    </p>
                                                    <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        <br style="box-sizing: border-box;"/>
                                                    </p>
                                                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                                        同济大学附属第十人民医院顾军教授与中国医学科学院皮肤病医院顾恒教授主持会议。顾恒教授分享了《中重度AD治疗：传统药物和新型药物的选择对策》，重庆医科大学附属第二医院赵恒光副教授分享了《斑秃与免疫研究新进展》，上海交通大学医学院附属瑞金医院李霞教授带来了《银屑病心血管共病的治疗进展》。会议还设置了两场精彩的圆桌讨论：《探本溯源-银屑病诊疗面面观》与《拨云见日-AD机制、诊疗与管理的新视角》，上海交通大学医学院附属瑞金医院李霞教授，东港市中心医院姜启君主任医师，上海中医药大学附属岳阳中西医结合医院李欣副教授、上海市皮肤病医院/同济大学附属皮肤病医院丁杨峰教授、复旦大学附属儿科医院李明教授分别参与讨论。会议最后，中国医师协会皮肤科医师分会名誉会长、北京大学第一医院朱学骏教授为大会做总结致辞。
                                                    </p>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                            <section powered-by="xiumi.us" style="display: flex; flex-flow: row; margin: 0px 0px 10px; justify-content: flex-start; box-sizing: border-box;">
                                <section style="display: inline-block; width: 825.594px; vertical-align: top; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; align-self: flex-start; height: auto; line-height: 0; padding: 0px; box-sizing: border-box;">
                                    <section powered-by="xiumi.us" style="text-align: right; isolation: isolate; box-sizing: border-box;">
                                        <section style="display: inline-block; width: 825.594px; height: 4px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 10px; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(255, 202, 0); box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px inset; box-sizing: border-box;">
                                            <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="text-align: justify; color: rgb(255, 255, 255); font-size: 14px; padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                        <strong style="box-sizing: border-box;">更多学术专场干货信息，请锁定“优麦医生微信服务号”，持续放送中。</strong>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
            <img data-ratio="0.25833333333333336" data-s="300,640" data-w="1080" width="100%" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="14" src="https://cdn.umer.com.cn/umer-doctor/20240126/e1207666-3920-4a67-82bc-97ad934c52a61706235483110.png" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box;">
        <section style="display: inline-block; width: 1084px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(0, 0, 0); margin: 0px; padding: 26px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="color: rgb(255, 255, 255); padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        群星闪耀<br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        温情相约
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        下一届金麦豆之夜
                    </p>
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        我们，不见不散！
                    </p>
                </section>
            </section>
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="color: rgb(255, 255, 255); padding: 0px 6px; line-height: 2; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box;">
        <br/>
    </section>
    <section powered-by="xiumi.us" style="margin-bottom: 0px; padding-right: 15px; padding-left: 15px; letter-spacing: 0.578px; text-wrap: wrap; font-size: 12px; color: rgb(160, 160, 160);">
        <p style="margin: 5px 0px;">
            版权声明：本文为原创文，版权属于优麦医生和作者所有，非经授权，任何媒体、网站或个人不得转载。
        </p>
    </section>
    <p powered-by="xiumi.us" style="margin: 5px 0px 0px; font-size: 16px; letter-spacing: 0.578px; text-wrap: wrap;">
        <br/>
    </p>
    <section powered-by="xiumi.us" style="margin-top: 10px; margin-bottom: 10px; font-size: 16px; letter-spacing: 0.578px; text-wrap: wrap; text-align: center; line-height: 0;">
        
    </section>
</section>
</div>`

class Latestdevelopments4 extends Component {
    timer = null
  constructor(props) {
    super(props);
    this.state = {
        tab2active: 0,
    }
  }

    
    
    handleLoaded() {
    }
  maxShow = 222
  render() {
    return (
      <div className="aboutussection">
        <img
          className="latestdeveimg2"
          onLoad={this.handleLoaded.bind(this)}
          src={artificialintelligencebg11}
          alt=""
        ></img>
        <div className="latestdevelopments_main latestdevelopments15_main">
            {
                ReactHtmlParser(html1, { ignoreCase: false })
            }
        </div>
      </div>
    );
  }
}

export default Latestdevelopments4;
