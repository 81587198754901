import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import './index.scss';

class Latestdevelopments4 extends Component {
    timer = null

    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

        
        clearInterval(this.timer)

        this.timer = setInterval(() => {
            let newCur = this.state.cur + 1

            if (newCur > this.state.imageGroup.length - 1) {
                newCur = 0
            }

            this.setState({
                cur: newCur
            })
        }, 2000);
    }

    maxShow = 3

    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >优麦科技常江受聘银屑病防治研究教育专项基金委员会副主任委员</div>
                        </div>

                        <div className='latestdevelopments_main_content'>
                          7月29-30日，由北京长江药学发展基金会银屑病防治研究教育专项基金管理委员会主办的银屑病防治研究教育专题研讨会在上海举行。其间，第七届委员会换届会（全体委员工作会）顺利召开。优麦科技创始人、CEO常江受聘北京长江药学发展基金会银屑病防治研究教育专项基金委员会副主任委员。
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230811/16917217774706484.jpg" alt=""  className='latest_image'/>

                        <div className='latestdevelopments_tips center'>
                          委员会合影
                        </div>

                        <div className='latestdevelopments_main_content'>
                          本次大会主席、银屑病防治研究教育专项基金委员会主任委员、
                          上海长征医院<b>温海教授</b>发表开幕致辞，
                          会议邀请上海市第十人民医院<b>顾军教授</b>、
                          空军军医大学西京医院<b>王刚教授</b>、
                          山东第一医科大学附属皮肤病医院<b>张福仁教授</b>、
                          上海市皮肤病医院<b>史玉玲教授</b>、
                          南方医科大学皮肤病医院<b>杨斌教授</b>、
                          浙江大学医学院附属第二医院<b>满孝勇教授</b>、
                          上海市皮肤病医院<b>丁杨峰教授</b>、
                          上海中医药大学附属岳阳中西医结合医院<b>李欣教授</b>、
                          空军特色医学中心<b>庞晓文教授</b>等众多国内深耕银屑病研究的知名专家进行特别演讲。
                          大会闭幕式由史玉玲教授主持，
                          顾军教授发表闭幕致辞。
                        </div>

                        <div className='latestdevelopments_main_content'>
                          北京长江药学发展基金会银屑病防治研究教育专项基金委员会换届会暨委员工作会由基金会<b>郁静秘书长</b>主持。
                          北京长江药学发展基金会终身荣誉理事长<b>马剑文教授</b>发表致辞。
                          北京长江药学发展基金会银屑病防治研究教育专项基金委员会顾问<b>俞梦孙院士</b>围绕“人民健康理念与银屑病的防治”展开分享，
                          基金会第六届主任委员<b>温海教授</b>进行工作报告，
                          并对新一届新任委员寄予了殷切期望。新一届委员会包含<b>顾问18人，名誉主委3人；全体委员242人，其中常委91人</b>，人员队伍较上一届明显扩大。
                          基金会后续将加大银屑病社区建设，深入开展医患研讨会及义诊，持续推进我国银屑病领域高质量发展。
                        </div>

                        <div className="user-img-text">
                            <div className="img-wrapper">
                                <img style={ { transform: "translateY(-10%)" } } src="https://cdn.umer.com.cn/umer-doctor/20230523/16848272478338608.jpg" alt="" />
                            </div>

                            <div className="content"><span className="stone"></span>
                            常江表示：近年来银屑病治疗飞速发展，创新药物与用药指南不断更新。优麦医生作为皮肤科医生专属的学习协作平台，将持续在医教研等多方面助力广大皮肤科医生，积极推动银屑病基础研究与临床诊疗的发展，从而让银屑病患者能够获得规范化、个体化的治疗方案。
                            </div>
                        </div>
                        
                        <div className='latestdevelopments_main_content'>
                        银屑病防治研究教育专项基金委员会成立于 1997 年，自成立以来，为树立全社会对银屑病的正确认知，规范疾病诊疗，提高患者生活质量开展了大量的工作，如召开医患研讨会、编写科普书籍、建立在线社区等。
                        </div>
                        
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;