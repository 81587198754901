import React, { Component } from 'react';
import doctorsectionbg from '../../images/doctorsectionbg.jpg'
import section3dht from '../../images/section3dht.png'
import dkle1 from '../../images/dkle1.png'
import dkle2 from '../../images/dkle2.png'
import latestdevelopments3 from '../../images/latestdevelopments3.png'
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import lastdevOne from '../../images/lastdev-one.png'
import code from '../../images/code.png'
import './index.scss';
class latestdevelopments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11}></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >排名29！优麦科技所属公司荣登“2022上海软件和信息技术服务业高成长百家”</div>
                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                时值岁末，上海市经济和信息化委员会公示“2022上海软件和信息技术服务业百强”、 “2022上海软件和信息技术服务业高成长百家” 企业名单。优麦科技所属公司上海麦色信息科技有限公司荣登“2022上海软件和信息技术服务业高成长百家”，排名29位。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                上海市经济和信息化委员会官网显示，“推动本市软件和信息服务业发展，进一步促进企业做大做强，发挥骨干企业的引领作用，根据企业填报的2019-2021年经营收入情况，按照年度经营收入规模和增长速度，在市软件行业协会和各区软件和信息服务业主管部门推荐的基础上，征得企业同意确认后对上海软件和信息技术服务业企业按经营收入规模和成长速度进行排名。”
                            </div>

                            <img src={latestdevelopments3} alt=""  className='latest_image'/>
                            <div className='latestdevelopments_main_content center'>
                            （“上海市经济和信息化委员会”官网截图 ）
                            </div>

                            
                        </div>
                </div>
                } 
            </div>
        );
    }
}

export default latestdevelopments;