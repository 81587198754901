import React, { Component } from 'react';
import './index.scss'
import indexbg from '../../images/indexbg.png'
import bodykjcx from '../../images/bodykjcx.png'
import sectronpjqr from '../../images/sectronpjqr.png'
import secpronpimg from '../../images/secpronp-img.png'
import section2bg from '../../images/section2bg.png'
import sectrondivbtn from '../../images/umerApp.png'
import sectronbannerbtn from '../../images/sectrondivbtn.png'
import Reactcarouse from '../../components/Carousel/ReactCarousel'
import section3dht from '../../images/section3dht.png'
import section3pfk from '../../images/section3pfk.png'
import section3bg from '../../images/section3bg.png'
import section3div3dz from '../../images/section3div3dz.png'
import section3div3App from '../../images/section3div3App.png'
import section3div3rzys from '../../images/section3div3rzys.png'
import section3div3fgl from '../../images/section3div3fgl.png'
import carouselone from '../../images/carouselone.png'
import section4rgzn from '../../images/section4rgzn.png'
import sectron2divbtn from '../../images/sectron2divbtn.png'
import section5bottombgtb from '../../images/section5bottombgtb.png'
import section3div3Main2img from '../../images/section3div3Main2img.gif'
import section3div3Main2ymhx from '../../images/section3div3Main2ymhx.gif'
import section4div3Mainimg from '../../images/section4div3Mainimg.png'
import footer_last2 from '../../images/index_footer_2.png'
import Bgbutton from '../../components/Bgbutton/Bgbutton'
import Footer from '../../components/Footer/Footer';
import Loading from '../../loading'
import umerdocotrappselect from '../../images/umerdocotrappselect.png'
import umerdocotrappunselect from '../../images/umerdocotrappunselect.png'
import umerConferenceselect from '../../images/umerConferenceselect.png'
import umerConferenceunselect from '../../images/umerConferenceunselect.png'
import ulolselect from '../../images/ulolselect.png'
import ulolunselect from '../../images/ulolunselect.png'
import enterpriseserviceselect from '../../images/enterpriseserviceselect.png'
import enterpriseserviceunselect from '../../images/enterpriseserviceunselect.png'
import strategicpartnersselect from '../../images/strategicpartnersselect.png'
import strategicpartnersunselect from '../../images/strategicpartnersunselect.png'
import Strategicpartners from '../../images/Strategicpartners.gif'
import UWrittenwords from '../../images/UWrittenwords.png'
import Enterprise from '../../images/Enterprise.png'
import Ulol from '../../images/Ulol.png'
import app from '../../images/app.png'
import youmaihuixun from '../../images/youmaihuixun.png'
import Ulm from '../../images/Ulm.png'
import enterpriseservicesNr from '../../images/enterpriseservicesNr.png'
import Strategicpartnesnr from '../../images/Strategicpartnesnr.png'

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }

    }
    handleMouseDown(e) {
        console.log(e)
        this.setState({
            tab2active: e
        })
    }
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

    }
    handleClick = (e) => {
        console.log(e);
        sessionStorage.setItem('tabactive', e)
    }
    handleimglick = (e) =>{
        console.log(e);
        sessionStorage.setItem('tag', e)
    }
    render() {
        return (
            <div className='containers'>
                {/* {!this.state.loaded && <Loading></Loading>} */}
                <div className='sectronpdiv'>
                    <div className='bodydiv'>
                        <p className='sectronp'>Umer Technology</p>
                        <img className='sectronpdivimg' onLoad={this.handleLoaded.bind(this)} src={bodykjcx}></img>
                        <div className='sectronpdivbtn' >
                            <Bgbutton txt='关于我们' tabactive='2' href='/aboutus' ></Bgbutton>
                        </div>
                    </div>
                    <img className='imgbody' src={indexbg}></img>
                    <img className='secrtonpdimgjqr' src={sectronpjqr}></img>
                    <img src={secpronpimg} className='sectronpimgTwo'></img>
                </div>
                {
                    this.state.loaded &&
                    <div>
                        <div className='section2'>
                            <img className='section2bg' src={section2bg}></img>
                            <div className='section2-main'>
                                <Reactcarouse></Reactcarouse>
                            </div>

                        </div>
                        <div className='section3'>
                            <img src={section3bg} className='section3img'></img>
                            <div className='section3_main'>
                                <div className='section3div1'>
                                    <img className='section3_title' src={section3pfk}></img>
                                </div>
                                <img className='section3_line' src={section3dht}></img>
                                {
                                    this.state.tab2active == 0 &&
                                    <div className='section3div3'>
                                        <img className='section3div1_img' src={app}></img>
                                        {/* <div className='section3div3Main'>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3App}></img>
                                                <div className='section3div3divdiv'>App用户数</div>
                                                <div className='section3div3divdiv2'>46000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3rzys}></img>
                                                <div className='section3div3divdiv'>认证医生用户数</div>
                                                <div className='section3div3divdiv2'>26000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3fgl}></img>
                                                <div className='section3div3divdiv'>覆盖中国皮肤科</div>
                                                <div className='section3div3divdiv2'>95%</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3dz}></img>
                                                <div className='section3div3divdiv'>医院用户分布于全国</div>
                                                <div className='section3div3divdiv2'>34</div>
                                                <div className='section3div3divdiv3'>省份、</div>
                                                <div className='section3div3divdiv2'>8000+</div>
                                                <div className='section3div3divdiv3'>医院、</div>
                                                <div className='section3div3divdiv2'>345</div>
                                                <div className='section3div3divdiv3'>个城市</div>
                                            </div>
                                        </div>
                                        <div className='section3div3Main2'>
                                            <img className='section_gif' src={section3div3Main2img}></img>
                                        </div> */}
                                    </div>
                                }
                                {
                                    this.state.tab2active == 1 &&
                                    <div className='section3div3'>
                                        <img src={youmaihuixun} className='section3div3_img'></img>
                                        {/* <div className='section3div3Main'>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3App}></img>
                                                <div className='section3div3divdiv'>App用户数</div>
                                                <div className='section3div3divdiv2'>46000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3rzys}></img>
                                                <div className='section3div3divdiv'>认证医生用户数</div>
                                                <div className='section3div3divdiv2'>26000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3fgl}></img>
                                                <div className='section3div3divdiv'>覆盖中国皮肤科</div>
                                                <div className='section3div3divdiv2'>95%</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3dz}></img>
                                                <div className='section3div3divdiv'>医院用户分布于全国</div>
                                                <div className='section3div3divdiv2'>34</div>
                                                <div className='section3div3divdiv3'>省份、</div>
                                                <div className='section3div3divdiv2'>8000+</div>
                                                <div className='section3div3divdiv3'>医院、</div>
                                                <div className='section3div3divdiv2'>345</div>
                                                <div className='section3div3divdiv3'>个城市</div>
                                            </div>
                                        </div>
                                        <div className='section3div3Main2'>
                                            <img className='section_gif' src={section3div3Main2ymhx}></img>
                                        </div> */}
                                    </div>
                                }
                                {
                                    this.state.tab2active == 2 &&
                                    <div className='section3div3'>
                                        <img src={Ulm} className='section3div3_img'></img>
                                        {/* <div className='section3div3Main'>
                                            <img className='section3div3Main_img' src={UWrittenwords}></img>
                                        </div>
                                        <div className='section3div3Main2'>
                                            <img className='section_gif' src={Ulol}></img>
                                        </div> */}
                                    </div>
                                }
                                {
                                    this.state.tab2active == 3 &&
                                    <div className='section3div3'>
                                        <img className='section3div3_img' src={enterpriseservicesNr}></img>
                                        {/* <div className='section3div3Main'>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3App}></img>
                                                <div className='section3div3divdiv'>App用户数</div>
                                                <div className='section3div3divdiv2'>46000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3rzys}></img>
                                                <div className='section3div3divdiv'>认证医生用户数</div>
                                                <div className='section3div3divdiv2'>26000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3fgl}></img>
                                                <div className='section3div3divdiv'>覆盖中国皮肤科</div>
                                                <div className='section3div3divdiv2'>95%</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3dz}></img>
                                                <div className='section3div3divdiv'>医院用户分布于全国</div>
                                                <div className='section3div3divdiv2'>34</div>
                                                <div className='section3div3divdiv3'>省份、</div>
                                                <div className='section3div3divdiv2'>8000+</div>
                                                <div className='section3div3divdiv3'>医院、</div>
                                                <div className='section3div3divdiv2'>345</div>
                                                <div className='section3div3divdiv3'>个城市</div>
                                            </div>
                                        </div>
                                        <div className='section3div3Main2'>
                                            <img className='section_qyfw' src={Enterprise}></img>
                                        </div> */}
                                    </div>
                                }
                                {
                                    this.state.tab2active == 4 &&
                                    <div className='section3div3'>
                                        <img className='section3div3_img' src={Strategicpartnesnr}></img>
                                        {/* <div className='section3div3Main'>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3App}></img>
                                                <div className='section3div3divdiv'>App用户数</div>
                                                <div className='section3div3divdiv2'>46000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3rzys}></img>
                                                <div className='section3div3divdiv'>认证医生用户数</div>
                                                <div className='section3div3divdiv2'>26000+</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3fgl}></img>
                                                <div className='section3div3divdiv'>覆盖中国皮肤科</div>
                                                <div className='section3div3divdiv2'>95%</div>
                                            </div>
                                            <div className='section3div3divFris'>
                                                <img src={section3div3dz}></img>
                                                <div className='section3div3divdiv'>医院用户分布于全国</div>
                                                <div className='section3div3divdiv2'>34</div>
                                                <div className='section3div3divdiv3'>省份、</div>
                                                <div className='section3div3divdiv2'>8000+</div>
                                                <div className='section3div3divdiv3'>医院、</div>
                                                <div className='section3div3divdiv2'>345</div>
                                                <div className='section3div3divdiv3'>个城市</div>
                                            </div>
                                        </div>
                                        <div className='section3div3Main2'>
                                            <img className='section_gif' src={Strategicpartners}></img>
                                        </div> */}
                                    </div>
                                }
                                <div className='section3div4'>
                                    <a href={"/umerdoctor/"} onClick={() => this.handleClick("4","")}>
                                        <img onMouseEnter={this.handleMouseDown.bind(this, 0)} onClick={this.handleimglick.bind(this,0)} className='section3div4img' src={this.state.tab2active == 0 ? umerdocotrappselect : umerdocotrappunselect}></img>
                                        <img onMouseDown={this.handleMouseDown.bind(this, 1)} onClick={this.handleimglick.bind(this,1)} onMouseEnter={this.handleMouseDown.bind(this, 1)} className='section3div4img' src={this.state.tab2active == 1 ? umerConferenceselect : umerConferenceunselect}></img>
                                        <img onMouseDown={this.handleMouseDown.bind(this, 2)} onClick={this.handleimglick.bind(this,2)} onMouseEnter={this.handleMouseDown.bind(this, 2)} className='section3div4img' src={this.state.tab2active == 2 ? ulolselect : ulolunselect}></img>
                                        <img onMouseDown={this.handleMouseDown.bind(this, 4)} onClick={this.handleimglick.bind(this,4)} onMouseEnter={this.handleMouseDown.bind(this, 4)} className='section3div4img' src={this.state.tab2active == 4 ? strategicpartnersselect : strategicpartnersunselect}></img>
                                        <img onMouseDown={this.handleMouseDown.bind(this, 3)} onClick={this.handleimglick.bind(this,3)} onMouseEnter={this.handleMouseDown.bind(this, 3)} className='section3div4img' src={this.state.tab2active == 3 ? enterpriseserviceselect : enterpriseserviceunselect}></img>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className='section3 section4_'>
                            <img src={footer_last2} className='section3img'></img>
                            <div className='section3_main'>
                                <div className='section3div1'>
                                    <img className='section3_title' src={section4rgzn}></img>
                                </div>
                                <img className='section3_line' src={section3dht}></img>
                                <div className='section3div3 section4div4'>
                                    <div className='section4div3Main'>
                                        <img className='section4div3img' src={section4div3Mainimg}></img>
                                    </div>
                                    <div className='section4div3Main2'>
                                        <ul className='section4ul'>
                                            <li>多模态皮肤影像管理平台</li>
                                            <li>优智云MIIS系统</li>
                                            <li>USkin智能测肤</li>
                                            <li>银屑病PASI自动评分</li>
                                            <li>AISIA皮肤检测仪</li>
                                            <li>皮肤治疗管理平台</li>
                                        </ul>
                                        <div className='section4div3Main2imge'>
                                            <Bgbutton txt='查看详情' tabactive='5' href='/artificialintelligence'></Bgbutton>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }



            </div>

        );
    }
}

export default index;