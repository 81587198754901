import React, { Component } from 'react';
import './joinus.scss';
import doctorsectionbg from '../../images/doctorsectionbg.jpg'
import section3dht from '../../images/section3dht.png'
import Swiper from 'swiper/js/swiper.min.js';
import footer_last2 from '../../images/index_footer_2.png'
// import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

class joinus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wrap: null,
            loaded: false
        }
        this.wrap = null;
        this.swiperIdRef = el => {
            console.log(el)
            this.swiperId = el;
        }
        this.swiperPreRef = el => {
            console.log('swiperPreRef-el:', el)
            this.swiperPre = el
        }
        this.swiperNextRef = el => {
            this.swiperNext = el
        }

    }
    componentDidMount() {
        console.log('componentDidMount',this.swiperId)
    }

    prevSwiper() {
        if(this.wrap){
            this.wrap.slidePrev()
        }
        
    }
    nextSwiper() {
        console.log('1234',this.wrap)
        if(this.wrap){
            this.wrap.slideNext()
        }
        
    }
    handleLoaded() {
        this.setState({
            loaded: true
        })
        let that = this;
        setTimeout(()=>{
            console.log(this.swiperId)
            that.wrap = new Swiper(that.swiperId, {
                loop: true,  //循环
                // autoplay: {   //滑动后继续播放（不写官方默认暂停）
                //     disableOnInteraction: false,
                // },
                // effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
    
            })
        },100)
        
    }
    render() {
        return (
            <div className='joinussection'>
                <img className='aboutussectionimg' onLoad={this.handleLoaded.bind(this)} src={footer_last2}></img>
                {
                   this.state.loaded && 
                    <div className='swiper-container joinussection_div' ref={this.swiperIdRef}>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className='joinussection_div_div' >策划</div>
                                <img style={{ width: '80%' }} src={section3dht}></img>
                                <div className='swiper-slide_div'>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>岗位职责</div>
                                        <ul className='joinus_main_div_ul2'>
                                            <li>为公司E-marketing销售工作的开展提供良好支持，具备较强的方案撰写能力和提案能力</li>
                                            <li>负责药企、药妆类客户的市场研究、市场分析、市场策划及方案的落地执行</li>
                                            <li>负责策划和组织学术年会活动，从展台的搭建到活动的落地执行</li>
                                        </ul>
                                    </div>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>任职要求</div>
                                        <ul className='joinus_main_div_ul2'>
                                            <li>本科以上学历，有互联网/广告/医学相关行业经验优先</li>
                                            <li>愿意接受挑战，学习能力强，抗压能力强</li>
                                            <li>良好的沟通协调能力、客户服务意识和优秀的团队合作精神</li>
                                            <li>有敏锐的市场洞察能力，随时关注竞争对手或同行动态，提出合理化建议</li>
                                        </ul>
                                    </div>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>投递邮箱</div>
                                        <ul className='swiper-slide_ul'>
                                            <li>hr.super@umer.com.cn</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className='joinussection_div_div' >医学编辑</div>
                                <img style={{ width: '80%' }} src={section3dht}></img>
                                <div className='swiper-slide_div'>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>岗位职责</div>
                                        <ul className='joinus_main_div_ul2'>
                                            <li>对医学会议稿件进行编辑，内容校对</li>
                                            <li>支持APP 产品医学功能编辑开发</li>
                                            <li>完成医学社区用户内容的管理、引导及互动</li>
                                            <li>定期负责医学专业文章/新闻的撰写、编辑、发布及相关专题、专访策划</li>
                                        </ul>
                                    </div>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>任职要求</div>
                                        <ul className='joinus_main_div_ul2'>
                                            <li>本科以上学历，医学院校临床、医药相关专业毕业，皮肤科专业优先</li>
                                            <li>愿对临床科研有所了解，熟悉学术科研的一般思路及方法，对office等相关软件操作熟练</li>
                                            <li>有医院临床经验、医学杂志社工作经验优先</li>
                                        </ul>
                                    </div>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>投递邮箱</div>
                                        <ul className='swiper-slide_ul'>
                                            <li>hr.super@umer.com.cn</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className='joinussection_div_div' >内容产品经理</div>
                                <img style={{ width: '80%' }} src={section3dht}></img>
                                <div className='swiper-slide_div'>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>岗位职责</div>
                                        <ul className='joinus_main_div_ul2'>
                                            <li>负责APP内容产品的主题调研、分析和规划设计</li>
                                            <li>负责内容引入、内容管理及整体规划，同上下游业务各方沟通需求，对APP内容丰富度和质量负责</li>
                                            <li>负责策划、撰写APP输出的文案，增加APP内容的传播力和影响力</li>
                                            <li>密切关注行业和竞品</li>
                                        </ul>
                                    </div>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>任职要求</div>
                                        <ul className='joinus_main_div_ul2'>
                                            <li>5年以上新媒体从业经验（有互联网医疗产品运营经验者优先）</li>
                                            <li>具备优秀的产品思维和解决方案能力</li>
                                            <li>对数据比较敏感，具备较强的数据分析能力，擅长新媒体写作</li>
                                            <li>中文、传播、新闻等学科背景，对知识传播类产品有独到的见解</li>
                                        </ul>
                                    </div>
                                    <div className='swiper-slide_div_div'>
                                        <div className='swiper-slide_div_div_div'>投递邮箱</div>
                                        <ul className='swiper-slide_ul'>
                                            <li>hr.super@umer.com.cn</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                        
                        <div className='swiper-button-prev' onClick={this.prevSwiper.bind(this)}></div>
                        <div className='swiper-button-next' onClick={this.nextSwiper.bind(this)}></div>
                
                    </div>
                }
            </div>
        );
    }
}

export default joinus;