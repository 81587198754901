import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import last61 from '../../images/last-6-1.png'
import last62 from '../../images/last-6-2.png'
import './index.scss';
class Latestdevelopments4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >优麦科技所属公司荣获上海市“专精特新”企业称号</div>
                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                根据《上海市优质中小企业梯度培育管理实施细则》（沪经信规范〔2022〕8号），经专家评审和综合评估，3月2日，上海市经济和信息化委员会将2022年上海市专精特新中小企业（第二批）公示名单和复核通过公示名单予以公示。优麦科技所属公司上海麦色信息科技有限公司荣获2022年上海市“专精特新”企业称号。
                            </div>
                            <img src={last61} alt=""  className='latest_image_tips'/>

                            <img src={last62} alt=""  className='latest_image_tips'/>

                            <div className="latestdevelopments_tips center">2022年上海市专精特新中小企业（第二批）公示名单</div>

                            <div className="latestdevelopments_main_content center">信息来自“上海市经济和信息化委员会”官网</div>

                            <div className='latestdevelopments_main_content'>
                                “专精特新”是指企业具有专业化、精细化、特色化、新颖化的发展特征。在“科技创新改善皮肤健康”的使命驱动下，优麦科技一路深耕皮肤数字医疗健康领域。去年岁末，上海麦色信息科技有限公司荣登“2022上海软件和信息技术服务业高成长百家”。
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;