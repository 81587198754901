import React, { Component } from 'react';
import './index.css'
class loading extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                    <div className='loading'>这个是首屏幕</div>
            
            </div>
        );
    }
}

export default loading;