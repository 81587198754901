import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import ReactHtmlParser from 'react-html-parser';

import medicalServiceCompaniesMain from '../../images/medical-service-companies-main.jpg'
import changjiang from '../../images/changjiang.png'
import hezuolanmu from '../../images/hezuolanmu.png'
import libin from '../../images/libin.png'
import zhuxuejun from '../../images/zhuxuejun.png'
import './index.scss';

const html = `
<section class="htmlcontent" style="box-sizing: border-box; font-style: normal; font-weight: 400; text-align: justify; font-size: 16px; visibility: visible;">
    <section style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
        <section style="width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892361344206635.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
        <br style="box-sizing: border-box; visibility: visible;"/>
    </p>
    <section style="text-align: center; margin: 10px 0px -1px; line-height: 0; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
        <section style="width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/1689236203084172.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
        <section style="width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892362287147870.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="text-align: center; margin: 0px 0px 10px; line-height: 0; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
        <section style="width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230714/16893202820447159.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            7月的新加坡热情如火，第25届世界皮肤科大会热力绽放。
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
        <p style="white-space: normal;margin: 0px;padding-left:0px;box-sizing: border-box;">
            <strong style="box-sizing: border-box;"><span style="color: #fff;box-sizing: border-box;">优麦医生作为本届世皮会中国独家媒体战略合作伙伴</span></strong>，火力全开。
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            直播、短视频、图文报道，丰富的内容输出形式，匹配优麦医生媒体矩阵，全景式呈现“皮科奥运”的专业魅力。
        </p>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="margin: 10px 0%;text-align: center;justify-content: center;transform: translate3d(1px, 0px, 0px);-webkit-transform: translate3d(1px, 0px, 0px);-moz-transform: translate3d(1px, 0px, 0px);-o-transform: translate3d(1px, 0px, 0px);display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;width: auto;vertical-align: top;min-width: 10%;max-width: 100%;flex: 0 0 auto;height: auto;background-color: rgb(0, 57, 122);align-self: flex-start;box-sizing: border-box;">
            <section style="display: flex;flex-flow: row nowrap;margin: -2px 0%;text-align: left;justify-content: flex-start;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: middle;width: auto;flex: 0 0 0%;height: auto;background-color: rgb(1, 57, 122);line-height: 0;align-self: center;border-width: 0px;box-sizing: border-box;">
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: middle;width: auto;align-self: center;background-color: rgba(255, 255, 255, 0);min-width: 10%;width: 100%;flex: 0 0 auto;height: auto;box-sizing: border-box;">
                    <section style="letter-spacing: 0px;line-height: 1;font-size: 18px;color: rgb(255, 255, 255);padding: 0px 16px;text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            世皮U直播
                        </p>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <section style="text-align: center;color: #fff;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
            <strong style="box-sizing: border-box;">大会热点深一度</strong>
        </p>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            国内外专家轮番走进现场直播间，围绕皮肤科主题，展开深度的学术对话，促进中国学术与世界学术的高端交流深度。
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
    </section>
    <section style="text-align: center;margin: 10px 0px 0px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/1689236445356947.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892364711591277.gif" style="vertical-align: middle; width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;" data-ratio="0.55625" data-w="640" data-index="6" src="https://mmbiz.qpic.cn/sz_mmbiz_gif/loiajvDFPRSQ7MF25jQMT2tghZhIic2xQYa9Ihh5PEky6GMD9rNBKAaxicbNxDzJVfAA6gH0Xk5TjYibxyjRuJGsUg/640?wx_fmt=gif&wxfrom=5&wx_lazy=1&wx_co=1" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="margin: 10px 0%;text-align: center;justify-content: center;transform: translate3d(1px, 0px, 0px);-webkit-transform: translate3d(1px, 0px, 0px);-moz-transform: translate3d(1px, 0px, 0px);-o-transform: translate3d(1px, 0px, 0px);display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;width: auto;vertical-align: top;min-width: 10%;max-width: 100%;flex: 0 0 auto;height: auto;background-color: rgb(0, 57, 122);align-self: flex-start;box-sizing: border-box;">
            <section style="display: flex;flex-flow: row nowrap;margin: -2px 0%;text-align: left;justify-content: flex-start;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: middle;width: auto;flex: 0 0 0%;height: auto;background-color: rgb(1, 57, 122);line-height: 0;align-self: center;border-width: 0px;box-sizing: border-box;">
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: middle;width: auto;align-self: center;background-color: rgba(255, 255, 255, 0);min-width: 10%;width: 100%;flex: 0 0 auto;height: auto;box-sizing: border-box;">
                    <section style="letter-spacing: 0px;line-height: 1;font-size: 18px;color: rgb(255, 255, 255);padding: 0px 16px;text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            皮小歪逛世皮
                        </p>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <section style="text-align: center;color: #fff;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
            <strong style="box-sizing: border-box;">展台亮点大搜罗</strong>
        </p>
    </section>
    <section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            全球皮科产业秀，展台亮点零距离。皮小歪深度逛展，走进20余家品牌展位，发现前沿进展、再访明星产品、特邀专家采访、聆听品牌讲解，带来医药、医美、化妆品等领域新视野。
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
    </section>
    <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892367173495877.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892368321169960.gif" style="vertical-align: middle; width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;" data-ratio="0.55" data-w="640" data-index="8" src="https://mmbiz.qpic.cn/sz_mmbiz_gif/loiajvDFPRSSNX3feZjTCHHH28Jh3sobSg0GR7BqsRj5dT1Fy9LqIKRMqzIJl40ibkOCSSZdkw79dfBIFYUhyDGg/640?wx_fmt=gif&wxfrom=5&wx_lazy=1&wx_co=1" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892368668997593.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
    </section>
    <section style="margin: 10px 0%;text-align: center;justify-content: center;transform: translate3d(1px, 0px, 0px);-webkit-transform: translate3d(1px, 0px, 0px);-moz-transform: translate3d(1px, 0px, 0px);-o-transform: translate3d(1px, 0px, 0px);display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;width: auto;vertical-align: top;min-width: 10%;max-width: 100%;flex: 0 0 auto;height: auto;background-color: rgb(0, 57, 122);align-self: flex-start;box-sizing: border-box;">
            <section style="display: flex;flex-flow: row nowrap;margin: -2px 0%;text-align: left;justify-content: flex-start;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: middle;width: auto;flex: 0 0 0%;height: auto;background-color: rgb(1, 57, 122);line-height: 0;align-self: center;border-width: 0px;box-sizing: border-box;">
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 5px;height: 2px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 0px 0px 3px 7px;border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgb(255, 255, 255);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                    <section style="text-align: center;margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 3px 7px 0px 0px;border-color: rgba(255, 255, 255, 0) rgb(255, 255, 255) rgba(255, 255, 255, 0) rgb(177, 211, 248);box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: middle;width: auto;align-self: center;background-color: rgba(255, 255, 255, 0);min-width: 10%;width: 100%;flex: 0 0 auto;height: auto;box-sizing: border-box;">
                    <section style="letter-spacing: 0px;line-height: 1;font-size: 18px;color: rgb(255, 255, 255);padding: 0px 16px;text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            直通世皮会
                        </p>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <section style="text-align: center;color: #fff;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
            <strong style="box-sizing: border-box;">第一时间传递大会资讯</strong><br style="box-sizing: border-box;"/>
        </p>
    </section>
    <section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            <br style="box-sizing: border-box;"/>
        </p>
        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
            大会新闻、产业热点、企业资讯……图文速递，第一时间带来WCD上的新动态、新数据。
        </p>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892370991835862.gif" style="vertical-align: middle; width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;" data-ratio="0.5609375" data-w="640" data-index="10" src="https://mmbiz.qpic.cn/sz_mmbiz_gif/loiajvDFPRSSNX3feZjTCHHH28Jh3sobSiaCibEF8jThDCm0M71TvL5ukxjoWHPLxA8rQaErpa8TLJJ3QEEeOyShw/640?wx_fmt=gif&wxfrom=5&wx_lazy=1&wx_co=1" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="display: flex;width: 100%;flex-flow: column nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="z-index: 2;box-sizing: border-box;" powered-by="xiumi.us">
            <section style="margin: 0px 0px -1000px;box-sizing: border-box;">
                <section style="text-align: center;color: rgb(255, 255, 255);box-sizing: border-box;">
                    <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                        <span style="font-size: 18px;box-sizing: border-box;"><strong style="box-sizing: border-box;">特邀观察员集结</strong></span>
                    </p>
                    <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                        <strong style="box-sizing: border-box;">皮肤科医生视角带你看世皮</strong>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section style="display: flex;width: 100%;flex-flow: column nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="z-index: 1;box-sizing: border-box;" powered-by="xiumi.us">
            <section style="text-align: center;margin: -10px 0px -1px;line-height: 0;box-sizing: border-box;">
                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/168923723269628.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                </section>
            </section>
        </section>
    </section>
    <section style="text-align: center;margin: -5px 0px 0px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892371499292580.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="display: flex;width: 100%;flex-flow: column nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="z-index: 2;box-sizing: border-box;" powered-by="xiumi.us">
            <section style="margin: 0px 0px -800px;box-sizing: border-box;">
                <section style="text-align: center;color: rgb(255, 255, 255);box-sizing: border-box;">
                    <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                        <br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                        <strong style="font-size: 18px;box-sizing: border-box;">携手企业合作伙伴</strong><br style="box-sizing: border-box;"/>
                    </p>
                    <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                        <strong style="box-sizing: border-box;">共话全球皮肤领域发展</strong>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section style="text-align: center;margin: 0px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892372428823877.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
    <section style="display: flex;width: 100%;flex-flow: column nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="z-index: 1;box-sizing: border-box;" powered-by="xiumi.us">
            <section style="text-align: center;margin: -10px 0px 0px;line-height: 0;box-sizing: border-box;">
                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892372958175388.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                </section>
            </section>
        </section>
    </section>
    <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;width: 100%;vertical-align: top;background-color: rgb(0, 57, 122);padding: 0px 15px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
            <section style="margin-top: 10px;margin-bottom: 10px;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;border-width: 2px;border-style: dotted;border-color: transparent;padding: 10px;background-color: rgb(255, 255, 255);border-radius: 15px;box-sizing: border-box;">
                    <section style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;vertical-align: top;width: 50%;letter-spacing: 0px;padding: 0px 3px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892373276259670.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/1689237367540699.jpg" style="vertical-align: middle; width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;" data-ratio="0.6666666666666666" data-w="1080" data-index="16" src="https://mmbiz.qpic.cn/sz_mmbiz_jpg/loiajvDFPRSQ7MF25jQMT2tghZhIic2xQYkKoKz3rDbVPNylSadVlEHtezpPQXET5nibic0SIsnibP0tSDMjIX978fA/640?wx_fmt=jpeg&wxfrom=5&wx_lazy=1&wx_co=1" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892373849118550.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block;vertical-align: top;width: 50%;padding: 0px 3px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892374033652444.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892374349332894.png" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892374557619519.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section style="text-align: justify;justify-content: flex-start;display: flex;flex-flow: row nowrap;margin: -5px 0px 0px;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;vertical-align: top;width: 50%;letter-spacing: 0px;padding: 0px 3px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892375755651541.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892377758924254.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892378340035547.jpg" style="vertical-align: middle; width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;" data-ratio="0.562962962962963" data-w="1080" data-index="23" src="https://mmbiz.qpic.cn/sz_mmbiz_jpg/loiajvDFPRSRJcQzG25icy34x8THAgRnX7qV0DycbGQbAIg6HnuQNTdj2ibRqKIP58b9P3ib0tXzaWsrhQcWIRWQWQ/640?wx_fmt=jpeg&wxfrom=5&wx_lazy=1&wx_co=1" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block;vertical-align: top;width: 50%;padding: 0px 3px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/1689237657761957.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892377991923590.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                            <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                                <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
                                    <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892378522711352.jpg" class="" _width="100%"  alt="图片" data-fail="0"/>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;margin: 10px 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;height: auto;border-style: solid;border-width: 20px;border-color: rgb(163, 212, 70);background-color: rgb(255, 255, 255);padding: 20px;box-sizing: border-box;">
            <section style="justify-content: center;display: flex;flex-flow: row nowrap;margin: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 0 0 0%;height: auto;line-height: 0;box-sizing: border-box;">
                    <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: top;width: auto;padding: 0px 10px;min-width: 5%;max-width: 100%;flex: 0 0 auto;height: auto;box-sizing: border-box;">
                    <section style="font-size: 20px;color: rgb(163, 212, 70);box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                            <strong style="box-sizing: border-box;">期&nbsp; 待</strong>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 5%;max-width: 100%;flex: 0 0 auto;height: auto;align-self: flex-end;box-sizing: border-box;">
                    <section style="margin: 0px 0px 5px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;width: 20px;height: auto;box-sizing: border-box;">
                            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892379617239606.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;padding: 10px;box-sizing: border-box;">
                    <section style="text-align: justify;color: rgb(160, 160, 160);box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            会前，WCD2023大会秘书长、新加坡全国皮肤中心的林燕茹教授和本届大会两位中国形象大使——北京大学人民医院张建中教授、四川大学华西医院冉玉平教授，共同祝贺优麦医生成为2023世界皮肤科大会中国独家媒体战略合作伙伴。林燕茹教授表示，“对这次的合作充满信心和期待”。
                        </p>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                    <section style="box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                    <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-o-transform: rotateY(180deg);box-sizing: border-box;">
                            <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;padding: 5px 20px;box-sizing: border-box;">
                    <section style="text-align: center;margin: 0px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;width: 100%;height: auto;box-sizing: border-box;">
                            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892378751868788.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
                        </section>
                    </section>
                </section>
            </section>
            <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;transform: rotateX(180deg);-webkit-transform: rotateX(180deg);-moz-transform: rotateX(180deg);-o-transform: rotateX(180deg);box-sizing: border-box;">
                    <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                        <section style="box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                        </section>
                    </section>
                    <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                        <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-o-transform: rotateY(180deg);box-sizing: border-box;">
                                <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                    <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
            <section style="justify-content: center;display: flex;flex-flow: row nowrap;margin: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 0 0 0%;height: auto;line-height: 0;box-sizing: border-box;">
                    <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: top;width: auto;padding: 0px 10px;min-width: 5%;max-width: 100%;flex: 0 0 auto;height: auto;box-sizing: border-box;">
                    <section style="font-size: 20px;color: rgb(163, 212, 70);box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                            <strong style="box-sizing: border-box;">认&nbsp; 可</strong>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 5%;max-width: 100%;flex: 0 0 auto;height: auto;align-self: flex-end;box-sizing: border-box;">
                    <section style="margin: 0px 0px 5px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;width: 20px;height: auto;box-sizing: border-box;">
                            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892379617239606.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;padding: 10px;box-sizing: border-box;">
                    <section style="text-align: justify;color: rgb(160, 160, 160);box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            <span style="box-sizing: border-box;">WCD2023新闻发布会上，大会发言人答优麦医生提问。国际皮肤病学联合会(ILDS)主席Prof. Lars French表示，中国皮肤科走在世界前沿，出席这次会议的中国皮肤科医生人数名列前茅。</span><span style="box-sizing: border-box;">WCD2023大会主席Prof. Roy Chan补充道，这在很大程度上归因于优麦医生的支持。</span>
                        </p>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                    <section style="box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                    <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-o-transform: rotateY(180deg);box-sizing: border-box;">
                            <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;padding: 5px 20px;box-sizing: border-box;">
                    <section style="text-align: center;margin: 0px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;width: 100%;height: auto;box-sizing: border-box;">
                            <img class="rich_pages wxw-img" data-ratio="0.54375" data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892380070952717.gif" _width="100%"  alt="图片" data-fail="0"/>
                        </section>
                    </section>
                </section>
            </section>
            <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;transform: rotateX(180deg);-webkit-transform: rotateX(180deg);-moz-transform: rotateX(180deg);-o-transform: rotateX(180deg);box-sizing: border-box;">
                    <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                        <section style="box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                        </section>
                    </section>
                    <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                        <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-o-transform: rotateY(180deg);box-sizing: border-box;">
                                <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                    <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
            <section style="justify-content: center;display: flex;flex-flow: row nowrap;margin: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 0 0 0%;height: auto;line-height: 0;box-sizing: border-box;">
                    <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: top;width: auto;padding: 0px 10px;min-width: 5%;max-width: 100%;flex: 0 0 auto;height: auto;box-sizing: border-box;">
                    <section style="font-size: 20px;color: rgb(163, 212, 70);box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="margin: 0px;padding: 0px;box-sizing: border-box;">
                            <strong style="box-sizing: border-box;">延&nbsp; 续</strong>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 5%;max-width: 100%;flex: 0 0 auto;height: auto;align-self: flex-end;box-sizing: border-box;">
                    <section style="margin: 0px 0px 5px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;width: 20px;height: auto;box-sizing: border-box;">
                            <img data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892379617239606.gif" class="" _width="100%"  alt="图片" data-fail="0"/>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;padding: 10px;box-sizing: border-box;">
                    <section style="text-align: justify;color: rgb(160, 160, 160);box-sizing: border-box;" powered-by="xiumi.us">
                        <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                            <span style="box-sizing: border-box;">下一届世界皮肤科大会将于四年后在墨西哥瓜达拉哈拉(Guadalajara)举办，</span><span style="box-sizing: border-box;">WCD2027大会主席Prof. Jorge Ocampo Candiani接受优麦医生独家专访时表示，希望能够与更多中国皮肤科医生交流分享学术知识。</span>
                        </p>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                    <section style="box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                        </section>
                    </section>
                </section>
                <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                    <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-o-transform: rotateY(180deg);box-sizing: border-box;">
                            <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;padding: 5px 20px;box-sizing: border-box;">
                    <section style="text-align: center;margin: 0px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;width: 100%;height: auto;box-sizing: border-box;">
                            <img class="rich_pages wxw-img" data-ratio="0.5580182529335072" data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892382303713941.png" _width="100%"  alt="图片" data-fail="0"/>
                        </section>
                    </section>
                </section>
            </section>
            <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;transform: rotateX(180deg);-webkit-transform: rotateX(180deg);-moz-transform: rotateX(180deg);-o-transform: rotateX(180deg);box-sizing: border-box;">
                    <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                        <section style="box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                            </section>
                        </section>
                    </section>
                    <section style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;line-height: 0;box-sizing: border-box;">
                        <section style="transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;" powered-by="xiumi.us">
                            <section style="transform: rotateY(180deg);-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-o-transform: rotateY(180deg);box-sizing: border-box;">
                                <section style="display: inline-block;width: 15px;height: 15px;vertical-align: top;overflow: hidden;border-left: 1px solid rgb(163, 212, 70);border-top: 1px solid rgb(163, 212, 70);padding: 2px;line-height: 0;box-sizing: border-box;">
                                    <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;margin: 0px 0px -10px;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;vertical-align: middle;width: auto;align-self: center;flex: 100 100 0%;height: auto;box-sizing: border-box;">
            <section style="justify-content: flex-start;display: flex;flex-flow: row nowrap;margin: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="display: inline-block;width: 382.575px;vertical-align: middle;align-self: center;flex: 0 0 auto;background-color: rgba(1, 176, 205, 0.85);padding: 6px 21px 6px 20px;height: auto;box-sizing: border-box;">
                    <section style="margin: 0px;box-sizing: border-box;" powered-by="xiumi.us">
                        <section style="text-align: justify;color: rgb(255, 255, 255);font-size: 18px;box-sizing: border-box;">
                            <p style="text-align: center;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                                <strong style="box-sizing: border-box;">【 划 重 点 】</strong>
                            </p>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block;vertical-align: middle;width: auto;align-self: center;flex: 0 0 auto;min-width: 5%;width: 100%;height: auto;margin: 0px 0px 0px -46px;box-sizing: border-box;">
            <svg ="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
        </section>
    </section>
    <section style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;margin: -61px 0px 10px;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: rgba(1, 176, 205, 0.6);padding: 26px;height: auto;box-sizing: border-box;">
            <section style="text-align: justify;color: rgb(255, 255, 255);box-sizing: border-box;" powered-by="xiumi.us">
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <strong style="box-sizing: border-box;">&gt;&gt;&gt; “WCD2023专区”登陆优麦医生APP，精彩内容等你探索。<br style="box-sizing: border-box;"/></strong>
                </p>
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <strong style="box-sizing: border-box;">&gt;&gt;&gt; 《皮小歪逛世皮》等精彩视频尽在优麦Umer视频号。</strong>
                </p>
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
                <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
                    <strong style="box-sizing: border-box;">&gt;&gt;&gt; 优麦医生APP在大会前夕完成海外应用市场上架，满足海外华人皮肤科医生及产业人士的学习、协作等应用所需。</strong>
                </p>
            </section>
        </section>
    </section>
    <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us">
        <br style="box-sizing: border-box;"/>
    </p>
    <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;" powered-by="xiumi.us">
        <section style="width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;">
            <img class="rich_pages wxw-img" data-ratio="0.25" data-s="300,640" src="https://cdn.umer.com.cn/umer-doctor/20230713/16892383751369776.jpg" _width="100%"  alt="图片" data-fail="0"/>
        </section>
    </section>
</section>
`
class Latestdevelopments4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                <div className='latestdevelopments_main'>
                    {ReactHtmlParser(html)}
                </div>
            </div>
        );
    }
}

export default Latestdevelopments4;