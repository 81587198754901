import React, { Component } from 'react';
import doctorsectionbg from '../../images/doctorsectionbg.jpg'
import section3dht from '../../images/section3dht.png'
import dkle1 from '../../images/dkle1.png'
import dkle2 from '../../images/dkle2.png'
import technology from '../../images/technology.png'
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import code from '../../images/code.png'
import './latestdevelopments.scss';
class latestdevelopments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11}></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >喜讯 | 数字医疗产品获批，优麦科技完成由千骥资本独家投资的A++轮融资，浩悦资本担任独家财务顾问</div>
                            <img style={{ width: '100%' }} src={section3dht}></img>

                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                2021年11月，皮肤互联网医疗平台优麦科技宣布获得千骥资本近千万美元的A++轮融资，这是继不久前获得由经纬创投独家投资的A+轮融资后，优麦科技在三个月内完成的第二轮融资，浩悦资本再次担任本轮融资的独家财务顾问。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                同时值得关注的是，近日，优麦科技与CSID专家团队携手研发的皮肤图像管理软件(USKIN-001)，获得了中华人民共和国医疗器械注册证（Ⅱ类），该项目由中国皮肤影像资源库(Chinese Skin Image Database, CSID)开放研究课题(CSID-ORF)基金资助。二类证的获批是优麦科技继4月获得互联网医院牌照后，在皮肤数字医疗应用上拿下的又一个科技创新里程碑。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                优麦科技始终坚持“科技创新改善皮肤健康”的使命，借助其核心产品优麦医生APP已覆盖95%以上的皮肤医生的优势，优麦科技正与数千名皮肤科医生在其构建的优麦云医院里共同推动皮肤医学的专业科普走向广泛受众。此次，USKIN-001的落地应用，将进一步拓展皮肤科在影像处理、信息获取、临床诊断等方面的能力，有助于打破不同地域和机构之间“信息差”。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                优麦科技深耕皮肤医疗赛道，早已建立了“D+B+C”的价值实现模型，正逐步打通临床数据收集-医患教育-市场推广-诊疗服务-专病病程管理的全链路径，此次获批二类医疗器械证的皮肤图像管理软件正是基于中国人群皮肤影像资源库，利用互联网技术让影像数据“流动”起来，链接“信息孤岛”，使医疗资源的使用效率最大化，为“分级诊疗”、“医疗资源下沉”等政策实行助力。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                今年是中国数字疗法的报证和资本市场大年，但中国大量数字疗法企业还在临床甚至临床前的验证阶段。数字疗法的本质是通过行为管理改变用户的生活习惯和健康习惯，不仅仅只是一个传统的药械产品，还有艰巨的临床推广和市场教育工作。相信由优麦科技这样围绕医患一体化战略打造的皮肤数字医疗生态闭环，不仅可以进一步深化为数百家皮肤科药企和皮肤消费品公司提供专业的一站式数字营销解决方案，也将在此次融资的助力下，加快其皮肤医疗基础设施建设的进程，未来为数字疗法创新企业、在商业化应用和疗效验证的困境提供进一步的解决方案。
                            </div>
                            <img src={technology} alt=""  className='latest_image'/>
                            <div className='latestdevelopments_main_div'>
                                更重要的是，优麦科技在专科互联网医院建设、专病病程管理上持续发力。其一站式肌肤智慧管理平台，为C端消费者进行科普教育、咨询问诊以及皮肤档案管理，提供精准用药与专业医学级护肤相结合的智慧肌肤解决方案。期待优麦指数成为国人的护肤指数。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                千骥资本董事总经理张敏表示，皮肤科横跨医疗+消费赛道，伴随着单抗创新药陆续上市及C端对功效型护肤品需求的增加，国内市场迎来了爆发增长。基于广泛覆盖全国皮肤科医生的优麦医生App，优麦已经成为极具影响力的皮肤科数字平台，提供一站式医学级皮肤管理解决方案。我们看好优麦在皮肤科“D+B+C”的模式，相信其将为中国皮肤医学领域带来更多创新的服务和产品，很高兴能与其携手，支持其为中国皮肤医学生态创造长期价值，与千骥资本在医疗产品、服务和支付方布局的国内领军企业协同共赢。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                浩悦资本董事总经理郭一信先生表示，一直以来数字医疗都与传统医疗服务在可及性、可靠性及便捷性上磨合切磋，皮肤科因为最广泛、频繁和无特因发病的轻慢病特点，注定需要更科技创新的平台和手段，来深度链接医生、药企、患者和护肤品牌。我们很高兴看到优麦科技在助力皮肤科医生的学习和自我提升的同时，不断地用工具和服务赋能医生触达更广阔的患者群体和实现个人价值，打破医疗服务的固有屏障，为医生和患者带来更可及、可靠和便利的一站式肌肤管理平台。
                            </div>
                            <div className='aboutussection_div_div' >关于优麦科技</div>
                            <img style={{ width: '100%' }} src={section3dht}></img>
                            <div className='latestdevelopments_main_content'>
                                优麦科技成立于2015年，是中国最具影响力的皮肤医生价值平台。核心产品优麦医生APP目前累积超12万用户，其中覆盖国内95%以上的皮肤医生，是受中国医师协会皮肤科医师分会、中华医学会皮肤性病学分会、中国中西医结合学会皮肤性病专业委员会等专业机构官方认可的中国最专业的皮肤领域医生学习平台。同时，公司横跨医疗+消费黄金赛道，面向数百家皮肤科药企和皮肤消费品公司提供专业的一站式数字营销解决方案，并在专科互联网医院建设、专病病程管理上持续发力，围绕医患一体化战略构建皮肤数字医疗的生态闭环。
                            </div>
                            <div className='aboutussection_div_div' >关于千骥资本</div>
                            <img style={{ width: '100%' }} src={section3dht}></img>
                            <div className='latestdevelopments_main_content'>
                                千骥资本成立于2010年，是国内最早从事医疗健康及生命科学领域投资的专业基金之一，致力于促进中国医疗产业的转化升级和革新。千骥资本目前管理六支健康医疗产业基金，基金出资人为国际及国内知名的机构投资者，包括亚洲主权财富基金、政府机构、国内外保险和金融机构、跨国企业。
                            </div>
                            <div className='aboutussection_div_div' >关于浩悦资本</div>
                            <img style={{ width: '100%' }} src={section3dht}></img>
                            <div className='latestdevelopments_main_content latestdevelopments_main_bottom'>
                                浩悦资本创立于2014年1月，致力于成为嫁接中国医疗事业与资本的桥梁。浩悦资本的研究领域和交易范围覆盖生物医药、创新医疗器械、IVD与精准医疗、医疗服务和智慧医疗等多个细分领域。浩悦资本的团队由深耕中国医疗健康领域多年的资深投资银行家组成，长期密切追踪医疗健康投资市场动态和投资趋势，每周发布医疗健康投资周报和专题研究报告，以浩悦观点解读新政策和新前沿
                            </div>
                        </div>
                </div>
                } 
            </div>
        );
    }
}

export default latestdevelopments;