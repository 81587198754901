import React, { Component } from "react";
import artificialintelligencebg11 from "../../images/artificialintelligencebg11.jpg";
import ReactHtmlParser from "react-html-parser";
import "./index.scss";

const html1 = `
<section
style="color: #fff;box-sizing: border-box; font-style: normal; font-weight: 400; text-align: justify; font-size: 16px; visibility: visible;">
<section
  style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box; visibility: visible;"
  powered-by="xiumi.us">
  <section
    style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
    <img data-s="300,640"
      style="vertical-align: middle; max-width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;"
      data-ratio="0.13333333333333333" data-w="1080" data-index="1"
      src="https://cdn.umer.com.cn/umer-doctor/20230728/16905246791904737.jpg" class="" _width="100%"
      crossorigin="anonymous" alt="图片" data-fail="0"></section>
</section>
<p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"
  powered-by="xiumi.us"><br style="box-sizing: border-box; visibility: visible;"></p>
<section
  style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box; visibility: visible;"
  powered-by="xiumi.us">
  <section
    style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box; visibility: visible;">
    <img data-s="300,640" data-src="https://cdn.umer.com.cn/umer-doctor/20230728/16905247598782430.png"
      style="vertical-align: middle; max-width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;"
      data-ratio="0.562037037037037" data-w="1080" data-index="2"
      src="https://cdn.umer.com.cn/umer-doctor/20230728/16905247598782430.png" class="" _width="100%"
      crossorigin="anonymous" alt="图片" data-fail="0"></section>
</section>
<section style="padding: 0px 15px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><span
      style="color: #fff; box-sizing: border-box; visibility: visible;"><br
        style="box-sizing: border-box; visibility: visible;"></span></p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><span
      style="color: #fff; box-sizing: border-box; visibility: visible;">守成创新，千帆竞发。7月13日-7月16日，中华医学会第二十八次全国皮肤性病学术年会（CSD）在沈阳举行。因新冠疫情停摆一年的CSD大会，重新迎来线下相聚，现场氛围格外热烈，年会注册代表超过6300人。<br
        style="box-sizing: border-box; visibility: visible;"></span></p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><br
      style="box-sizing: border-box; visibility: visible;"></p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><span
      style="color: #fff; box-sizing: border-box; visibility: visible;"><strong
        style="box-sizing: border-box; visibility: visible;">中华医学会皮肤性病学分会携手优麦医生共建大会宣传中心，优麦医生品牌栏目“年会U直播”首度登陆CSD大会。</strong></span>
  </p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><br
      style="box-sizing: border-box; visibility: visible;"></p>
</section>
<section
  style="margin: 10px 0%; text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;"
  powered-by="xiumi.us">
  <section
    style="display: inline-block; vertical-align: middle; width: auto; background-color: rgba(255, 255, 255, 0); line-height: 0; align-self: center; flex: 0 0 auto; box-sizing: border-box; visibility: visible;">
    <section style="opacity: 0.85; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
      <section
        style="display: inline-block; width: 17px; height: 17px; vertical-align: top; overflow: hidden; background-color: #fff; box-sizing: border-box; visibility: visible;">
        <section style="font-size: 0px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section style="text-align: justify; box-sizing: border-box; visibility: visible;">
            <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
              <br style="box-sizing: border-box; visibility: visible;"></p>
          </section>
        </section>
      </section>
    </section>
    <section
      style="opacity: 0.6; margin: -8px 0% 0px; transform: translate3d(6px, 0px, 0px); box-sizing: border-box; visibility: visible;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block; width: 12px; height: 12px; vertical-align: top; overflow: hidden; background-color: #fff; box-sizing: border-box; visibility: visible;">
        <section style="font-size: 0px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section style="text-align: justify; box-sizing: border-box; visibility: visible;">
            <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
              <br style="box-sizing: border-box; visibility: visible;"></p>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section
    style="display: inline-block; vertical-align: middle; width: auto; background-color: rgba(255, 255, 255, 0); line-height: 0; letter-spacing: 0px; padding: 0px 10px; align-self: center; flex: 0 0 auto; box-sizing: border-box; visibility: visible;">
    <section
      style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;"
      powered-by="xiumi.us">
      <section
        style="height: 1.5em;display: inline-block; width: 100%; vertical-align: top; border-width: 0px; border-style: none; border-color: #fff; line-height: 1; letter-spacing: 0px; padding: 0px; align-self: flex-start; flex: 0 0 auto; box-sizing: border-box; visibility: visible;">
        <section style="margin: 0px 0% 4px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section
            style="font-size: 18px; color: #fff; padding: 0px; text-align: justify; box-sizing: border-box; visibility: visible;">
            <p
              style="text-align: center; white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
              <strong style="box-sizing: border-box; visibility: visible;">对&nbsp; 话</strong></p>
          </section>
        </section>
        <section style="margin: 0px 0%; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section style="background-color: #fff; height: 1px; box-sizing: border-box; visibility: visible;"><svg
              viewBox="0 0 1 1"
              style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg>
          </section>
        </section>
        <section style="opacity: 0.28; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section
            style="color: #fff; font-size: 18px; line-height: 1.3; box-sizing: border-box; visibility: visible;">
            <p style="margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">凝聚皮科中坚力量</p>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section
    style="display: inline-block; vertical-align: middle; width: auto; background-color: rgba(255, 255, 255, 0); line-height: 0; align-self: center; flex: 0 0 auto; box-sizing: border-box; visibility: visible;">
    <section style="opacity: 0.85; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
      <section
        style="display: inline-block; width: 17px; height: 17px; vertical-align: top; overflow: hidden; background-color: #fff; box-sizing: border-box; visibility: visible;">
        <section style="font-size: 0px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section style="text-align: justify; box-sizing: border-box; visibility: visible;">
            <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
              <br style="box-sizing: border-box; visibility: visible;"></p>
          </section>
        </section>
      </section>
    </section>
    <section
      style="opacity: 0.6; margin: -8px 0% 0px; transform: translate3d(-6px, 0px, 0px); box-sizing: border-box; visibility: visible;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block; width: 12px; height: 12px; vertical-align: top; overflow: hidden; background-color: #fff; box-sizing: border-box; visibility: visible;">
        <section style="font-size: 0px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section style="text-align: justify; box-sizing: border-box; visibility: visible;">
            <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
              <br style="box-sizing: border-box; visibility: visible;"></p>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<section style="padding: 0px 15px; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><br
      style="box-sizing: border-box; visibility: visible;"></p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
    中青年医生作为中坚力量，在皮肤科发展中承上启下，他们在传承、在创新、在引领。本次“年会U直播”联合CSD青年委员会、CSD2023学术壁报大赛、中华医学会全国青年皮肤科医生授课大赛、第十二届皮肤医学知识挑战赛，集结皮科中坚力量，搭建学术交流平台。
  </p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><br
      style="box-sizing: border-box; visibility: visible;"></p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
    3天时间，45场直播访谈。青委会成员、各项大赛获奖者陆续走进“年会U直播”，分享专业热点与创新思路。更有业内大咖倾力助阵，为直播栏目增光添彩。</p>
  <p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;"><br
      style="box-sizing: border-box; visibility: visible;"></p>
</section>
<section
  style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box; visibility: visible;"
  powered-by="xiumi.us">
  <section
  style="width: 100%;">
    <img
      style="width: 100%;"
      src="https://cdn.umer.com.cn/umer-doctor/20230728/16905248871657278.gif"
      crossorigin="anonymous"/>
    </section>
</section>
<p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us"><br
    style="box-sizing: border-box;"></p>
<section
  style="margin: 10px 0%;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;box-sizing: border-box;"
  powered-by="xiumi.us">
  <section
    style="display: inline-block;vertical-align: middle;width: auto;background-color: rgba(255, 255, 255, 0);line-height: 0;align-self: center;flex: 0 0 auto;box-sizing: border-box;">
    <section style="opacity: 0.85;box-sizing: border-box;" powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 17px;height: 17px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
    <section
      style="opacity: 0.6;margin: -8px 0% 0px;transform: translate3d(6px, 0px, 0px);-webkit-transform: translate3d(6px, 0px, 0px);-moz-transform: translate3d(6px, 0px, 0px);-o-transform: translate3d(6px, 0px, 0px);box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 12px;height: 12px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section
    style="display: inline-block;vertical-align: middle;width: auto;background-color: rgba(255, 255, 255, 0);line-height: 0;letter-spacing: 0px;padding: 0px 10px;align-self: center;flex: 0 0 auto;box-sizing: border-box;">
    <section style="justify-content: center;display: flex;flex-flow: row nowrap;box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="height: 1.5em;display: inline-block;width: 100%;vertical-align: top;border-width: 0px;border-style: none;border-color: #fff;line-height: 1;letter-spacing: 0px;padding: 0px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
        <section style="margin: 0px 0% 4px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="font-size: 18px;color: #fff;padding: 0px;text-align: justify;box-sizing: border-box;">
            <p style="text-align: center;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                style="box-sizing: border-box;">荐&nbsp; 书</strong></p>
          </section>
        </section>
        <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="background-color: #fff;height: 1px;box-sizing: border-box;"><svg viewBox="0 0 1 1"
              style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
        </section>
        <section style="opacity: 0.28;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="color: #fff;font-size: 18px;line-height: 1.3;box-sizing: border-box;">
            <p style="margin: 0px;padding: 0px;box-sizing: border-box;">皮科好书闪亮登场</p>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section
    style="display: inline-block;vertical-align: middle;width: auto;background-color: rgba(255, 255, 255, 0);line-height: 0;align-self: center;flex: 0 0 auto;box-sizing: border-box;">
    <section style="opacity: 0.85;box-sizing: border-box;" powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 17px;height: 17px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
    <section
      style="opacity: 0.6;margin: -8px 0% 0px;transform: translate3d(-6px, 0px, 0px);-webkit-transform: translate3d(-6px, 0px, 0px);-moz-transform: translate3d(-6px, 0px, 0px);-o-transform: translate3d(-6px, 0px, 0px);box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 12px;height: 12px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us"><br
    style="box-sizing: border-box;"></p>
<section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
    读一本好书，如同饮一泓清泉。优麦医生APP“荐书”栏目，持续为皮肤科医生推荐各种新书、好书。在本次CSD大会现场，“荐书”特别为大家搜罗2本好书。<br style="box-sizing: border-box;"></p>
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br style="box-sizing: border-box;">
  </p>
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
    由南部战区总医院杨慧兰教授、中国医科大学附属第一医院高兴华教授主编的《现代病毒性皮肤病学》迎来新书发布会。由四川大学华西医院冉玉平教授主编的《挑战疑难病-SCI论文背后的故事》特别进行现场签售。</p>
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br style="box-sizing: border-box;">
  </p>
</section>
<section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
  powered-by="xiumi.us">
  <section
    style="width: 100%"><img
      style=" width: 100%;"
      data-ratio="0.5616438356164384" data-w="730" data-index="4"
      src="https://cdn.umer.com.cn/umer-doctor/20230728/16905249251724841.gif" class="" _width="100%"
      crossorigin="anonymous" alt="图片" data-fail="0"/></section>
</section>
<section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br style="box-sizing: border-box;">
  </p>
</section>
<section
  style="margin: 10px 0%;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;box-sizing: border-box;"
  powered-by="xiumi.us">
  <section
    style="display: inline-block;vertical-align: middle;width: auto;background-color: rgba(255, 255, 255, 0);line-height: 0;align-self: center;flex: 0 0 auto;box-sizing: border-box;">
    <section style="opacity: 0.85;box-sizing: border-box;" powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 17px;height: 17px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
    <section
      style="opacity: 0.6;margin: -8px 0% 0px;transform: translate3d(6px, 0px, 0px);-webkit-transform: translate3d(6px, 0px, 0px);-moz-transform: translate3d(6px, 0px, 0px);-o-transform: translate3d(6px, 0px, 0px);box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 12px;height: 12px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section
    style="display: inline-block;vertical-align: middle;width: auto;background-color: rgba(255, 255, 255, 0);line-height: 0;letter-spacing: 0px;padding: 0px 10px;align-self: center;flex: 0 0 auto;box-sizing: border-box;">
    <section style="justify-content: center;display: flex;flex-flow: row nowrap;box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="height: 1.5em;display: inline-block;width: 100%;vertical-align: top;border-width: 0px;border-style: none;border-color: #fff;line-height: 1;letter-spacing: 0px;padding: 0px;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
        <section style="margin: 0px 0% 4px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="font-size: 18px;color: #fff;padding: 0px;text-align: justify;box-sizing: border-box;">
            <p style="text-align: center;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                style="box-sizing: border-box;">传&nbsp; 递</strong></p>
          </section>
        </section>
        <section style="margin: 0px 0%;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="background-color: #fff;height: 1px;box-sizing: border-box;"><svg viewBox="0 0 1 1"
              style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
        </section>
        <section style="opacity: 0.28;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="color: #fff;font-size: 18px;line-height: 1.3;box-sizing: border-box;">
            <p style="margin: 0px;padding: 0px;box-sizing: border-box;"><span
                style="letter-spacing: 0px;box-sizing: border-box;">优秀壁报互动展示</span></p>
          </section>
        </section>
      </section>
    </section>
  </section>
  <section
    style="display: inline-block;vertical-align: middle;width: auto;background-color: rgba(255, 255, 255, 0);line-height: 0;align-self: center;flex: 0 0 auto;box-sizing: border-box;">
    <section style="opacity: 0.85;box-sizing: border-box;" powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 17px;height: 17px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
    <section
      style="opacity: 0.6;margin: -8px 0% 0px;transform: translate3d(-6px, 0px, 0px);-webkit-transform: translate3d(-6px, 0px, 0px);-moz-transform: translate3d(-6px, 0px, 0px);-o-transform: translate3d(-6px, 0px, 0px);box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 12px;height: 12px;vertical-align: top;overflow: hidden;background-color: #fff;box-sizing: border-box;">
        <section style="font-size: 0px;box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: justify;box-sizing: border-box;">
            <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                style="box-sizing: border-box;"></p>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<section style="padding: 0px 15px;box-sizing: border-box;" powered-by="xiumi.us">
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br style="box-sizing: border-box;">
  </p>
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
    为进一步激发皮肤科医生思维活跃、勇于创新的特点，营造浓厚的学术交流氛围，中华医学会第二十八次全国皮肤性病学术年会发起壁报征集活动。优麦医生配合学会完成两轮壁报评选和审核，最终评出20份最佳壁报、30份匠心壁报。“宣传中心”互动展示屏上，以多媒体形式展示优秀壁报作品，吸引了众多参会代表驻足观看。
  </p>
  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br style="box-sizing: border-box;">
  </p>
</section>`

const html2 =`
<section
  style="text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;margin: 10px 0px;box-sizing: border-box;"
  powered-by="xiumi.us">
  <section
    style="display: inline-block;width: auto;vertical-align: top;align-self: flex-start;flex: 0 0 auto;min-width: 5%;max-width: 100%;height: auto;box-sizing: border-box;">
    <section style="display: flex;width: 100%;flex-flow: column nowrap;box-sizing: border-box;" powered-by="xiumi.us">
      <section
        style="z-index: 1;transform: perspective(0px);-webkit-transform: perspective(0px);-moz-transform: perspective(0px);-o-transform: perspective(0px);transform-style: flat;box-sizing: border-box;"
        powered-by="xiumi.us">
        <section
          style="justify-content: center;display: flex;flex-flow: row nowrap;transform: translate3d(4px, 0px, 0px) rotateX(180deg);-webkit-transform: translate3d(4px, 0px, 0px) rotateX(180deg);-moz-transform: translate3d(4px, 0px, 0px) rotateX(180deg);-o-transform: translate3d(4px, 0px, 0px) rotateX(180deg);margin: 0px 0px -2px;box-sizing: border-box;">
          <section
            style="display: inline-block;vertical-align: top;width: 50%;align-self: flex-start;flex: 0 0 auto;box-sizing: border-box;">
            <section style="box-sizing: border-box;" powered-by="xiumi.us">
              <section
                style="display: inline-block;width: 10px;height: 14px;vertical-align: top;overflow: hidden;background-color: rgb(91, 91, 91);border-style: solid;border-width: 2px;border-color: rgb(255, 255, 255);box-sizing: border-box;">
                <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                      style="box-sizing: border-box;"></p>
                </section>
              </section>
            </section>
          </section>
          <section style="display: inline-block;vertical-align: top;width: 50%;box-sizing: border-box;">
            <section style="box-sizing: border-box;" powered-by="xiumi.us">
              <section
                style="display: inline-block;width: 10px;height: 14px;vertical-align: top;overflow: hidden;background-color: rgb(91, 91, 91);border-style: solid;border-width: 2px;border-color: rgb(255, 255, 255);box-sizing: border-box;">
                <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                      style="box-sizing: border-box;"></p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <section
      style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;"
      powered-by="xiumi.us">
      <section
        style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: #fff;box-sizing: border-box;">
        <section
          style="text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;margin: -4px 0px 5px;transform: translate3d(5px, 0px, 0px);-webkit-transform: translate3d(5px, 0px, 0px);-moz-transform: translate3d(5px, 0px, 0px);-o-transform: translate3d(5px, 0px, 0px);box-sizing: border-box;"
          powered-by="xiumi.us">
          <section
            style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;background-color: rgb(1, 57, 122);padding: 10px 20px;margin: 0px;box-shadow: rgb(255, 255, 255) -2px 2px 0px;box-sizing: border-box;">
            <section
              style="text-align: left;justify-content: flex-start;display: flex;flex-flow: row nowrap;box-sizing: border-box;"
              powered-by="xiumi.us">
              <section
                style="display: inline-block;vertical-align: middle;width: 1px;align-self: center;flex: 0 0 auto;height: auto;margin: 0px 6px 0px 0px;box-sizing: border-box;">
                <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
                  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><br
                      style="box-sizing: border-box;"></p>
                </section>
              </section>
              <section
                style="display: inline-block;vertical-align: middle;width: auto;align-self: center;flex: 0 0 auto;min-width: 5%;max-width: 100%;height: auto;box-sizing: border-box;">
                <section style="text-align: justify;color: rgb(255, 255, 255);box-sizing: border-box;"
                  powered-by="xiumi.us">
                  <p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"><strong
                      style="box-sizing: border-box;">重磅登陆</strong></p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<section
  style="text-align: right;justify-content: flex-end;display: flex;flex-flow: row nowrap;margin: -36px 0px 10px;box-sizing: border-box;"
  powered-by="xiumi.us">
  <section
    style="display: inline-block;width: 100%;vertical-align: top;align-self: flex-start;flex: 0 0 auto;border-style: solid;border-width: 3px;border-color: #fff;padding: 46px 21px 28px;height: auto;box-sizing: border-box;">
    <section style="text-align: justify;box-sizing: border-box;" powered-by="xiumi.us">
      <p style="color: #fff;white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;">
        本次年会期间，《中华皮肤科杂志》正式登陆优麦医生APP，为广大皮肤科医生的学术交流拓展新渠道。<br style="box-sizing: border-box;"></p>
    </section>
  </section>
</section>
<p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;" powered-by="xiumi.us"><br
    style="box-sizing: border-box;"></p>
<section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;box-sizing: border-box;"
  powered-by="xiumi.us">
  <section
    style="max-width: 100%;vertical-align: middle;display: inline-block;line-height: 0;box-sizing: border-box;"><img
      class="rich_pages wxw-img" data-ratio="0.25" data-s="300,640"
      data-src="https://cdn.umer.com.cn/umer-doctor/20230728/16905250013389494.jpg" data-w="1080"
      style="vertical-align: middle; max-width: 100%; box-sizing: border-box; width: 100% !important; height: auto !important; visibility: visible !important;"
      data-index="5" src="https://cdn.umer.com.cn/umer-doctor/20230728/16905250013389494.jpg" _width="100%"
      crossorigin="anonymous" alt="图片" data-fail="0"></section>
</section>
</section>`;

class Latestdevelopments4 extends Component {
    timer = null
  constructor(props) {
    super(props);
    this.state = {
        tab2active: 0,
        loaded: false,
        imageGroup: [
            'https://cdn.umer.com.cn/umer-doctor/20230728/16905256331076821.jpg',
            'https://cdn.umer.com.cn/umer-doctor/20230728/16905255591115782.png',
            'https://cdn.umer.com.cn/umer-doctor/20230728/16905256084144073.png',
        ],
        cur: 0,
        imageGroupHeight: 0
    }
  }

    
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

        clearInterval(this.timer)

        this.timer = setInterval(() => {
            let newCur = this.state.cur + 1

            if (newCur > this.state.imageGroup.length - 1) {
                newCur = 0
            }

            this.setState({
                cur: newCur
            })
        }, 2000);
    }
  maxShow = 222
  render() {
    const {
        imageGroup,
        cur
    } = this.state

    let next = cur + 1

    if (next > this.state.imageGroup.length - 1) {
        next = 0
    }

    return (
      <div className="aboutussection">
        <img
          className="latestdeveimg2"
          onLoad={this.handleLoaded.bind(this)}
          src={artificialintelligencebg11}
          alt=""
        ></img>
        <div className="latestdevelopments_main">
            {
                ReactHtmlParser(html1)
            }
            {
                <div className='image-group'>
                    {
                        imageGroup.map((item, index) => {
                            if (Math.abs(index - cur) <= Math.floor(this.maxShow / 2)) {
                                return <img className="image" key={item} src={item} alt="" style={{ opacity: cur === index ? 1 : 0 }} />
                            } else {
                                return ''
                            }
                        })
                    }

                    <img style={{ opacity: 0, display: 'block', width: '100%' }} src={imageGroup[0]} alt="" />
                </div>
            }
            {
                ReactHtmlParser(html2)
            }
        </div>
      </div>
    );
  }
}

export default Latestdevelopments4;
