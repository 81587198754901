import React, { Component } from 'react';
import doctorsectionbg from '../../images/doctorsectionbg.jpg'
import section3dht from '../../images/section3dht.png'
import dkle1 from '../../images/dkle1.png'
import dkle2 from '../../images/dkle2.png'
import latestdevelopments3 from '../../images/latestdevelopments3.png'
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import qianyue from '../../images/qianyue.png'
import changjiang from '../../images/changjiang.png'
import hezuolanmu from '../../images/hezuolanmu.png'
import libin from '../../images/libin.png'
import zhuxuejun from '../../images/zhuxuejun.png'
import lastdevOne from '../../images/lastdev-one.png'
import code from '../../images/code.png'
import './index.scss';
class Latestdevelopments4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11}></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >强强联手 再启新篇——优麦科技与上海市皮肤病医院/同济大学附属皮肤病医院签署战略合作协议</div>

                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                11月23日，致力于科技创新改善皮肤健康的优麦科技与上海市皮肤病医院/同济大学附属皮肤病医院共同签署战略合作协议。双方将在产学研全方位开展深度合作，共同推进皮肤科事业的创新与发展。上海市皮肤病医院李斌院长、优麦科技CEO常江先生代表双方签署战略合作协议。
                            </div>
                            <img src={qianyue} alt=""  className='latest_image_tips'/>

                            <div className='latestdevelopments_tips center'>
                                签约仪式现场
                            </div>
                            <div className='latestdevelopments_main_content center'>
                                （左：上海市皮肤病医院院长李斌、右：优麦科技CEO常江）
                            </div>
                            <div className='latestdevelopments_main_div latestdevelopments_title'>
                                李斌院长致辞
                            </div>

                            <img src={libin} alt=""  className='latest_image'/>
                            <div className='latestdevelopments_main_content'>
                                李斌院长表示，上海市皮肤病医院始终坚持医、教、研、防协同发展原则，践行“科教兴院”的方针。近年来，医院各方面建设持续发力，作为专科医院，上海市皮肤病医院连续两年荣登中国医院科技量值（STEM）皮肤病学科技产出全国第一，2021年度总科技量值排名全国第二。提升学科建设与人才培养是现阶段医院实现高质量发展的重中之重，《“十四五”国家信息化规划》也提出加快教育信息化，支撑终身数字教育。借助皮肤医学信息与产业互联网平台的力量，将有助于提升医院科教质量及核心竞争力。上海市皮肤病医院与优麦科技在医生教育等方面的合作由来已久，此次战略合作协议的签署标志着一次继往开来的全新起航，医院将以开放的态度与优麦科技开展更深层次的合作，希望双方充分发挥各自优势，资源共享，协同发展，在医生教育、科普传播、医研共创等多方面不断深耕、创新、开拓。同时也希望全国年轻皮肤科医生，借助优麦平台能够有更多的学习机会，继而使得中国皮肤科水平，达到更高的层次，未来已来。
                            </div>
                            <div className='latestdevelopments_main_div latestdevelopments_title'>
                                常江先生致辞
                            </div>
                            <img src={changjiang} alt=""  className='latest_image'/>

                            <div className='latestdevelopments_main_content'>
                                常江先生表示：“优麦科技从皮肤科医生在线学习平台优麦医生APP起步，如今已构建起完整的皮肤产业互联网闭环，其中包括服务于临床的优麦皮肤互联网医院以及聚焦于医研共创的科研服务平台优麦科研院等。在科技创新改善皮肤健康的使命驱动下，我们紧密围绕皮肤科医教研及科普等价值刚需，致力于打造不断完善的中国皮肤科医生价值平台和皮肤数字医疗健康管理平台。优麦科技的业务深度垂直于皮肤数字医疗健康领域，上海市皮肤病医院作为高水平的大学附属医院、高水准的皮肤专科医院，在临床、科研、教学等各个方面都有着累累硕果，此番双方达成战略合作，将构建起‘立足上海、辐射全国’的协同体系，携手推动中国皮肤健康行业、皮肤科学事业的可持续发展。”
                            </div>

                            <div className='latestdevelopments_main_div latestdevelopments_title'>
                                合作栏目首发
                            </div>
                            <img src={hezuolanmu} alt=""  className='latest_image'/>
                            <div className='latestdevelopments_main_content'>
                                作为双方战略合作首发项目，皮肤科在线教育品牌栏目——《保德有约-谁与朱学骏约会》在签约仪式现场荣耀揭面。该栏目特邀北京大学第一医院朱学骏教授领衔，上海市皮肤病医院院长李斌教授、副院长史玉玲教授共同领衔，与青年医生一起对疑难病例进行探讨。
                            </div>

                            <div className='latestdevelopments_main_div latestdevelopments_title'>
                                朱学骏教授致辞
                            </div>
                            <img src={zhuxuejun} alt=""  className='latest_image'/>
                            <div className='latestdevelopments_main_content'>
                                朱学骏教授表示：“上海市皮肤病医院正处在发展最快的时期，非常高兴一年前我以特约专家的身份成为上海市皮肤病医院的一分子，同时也实现了‘传承’的力量，既传承了父亲的衣钵，也向青年医生传递了皮肤病诊疗知识。希望通过打造更多的精品栏目，助力广大皮肤科医生尤其是青年医生的专业成长。青年强则皮科强，中国皮肤科，未来可期。”

                            </div>
                        </div>
                </div>
                } 
            </div>
        );
    }
}

export default Latestdevelopments4;