import React, { Component } from "react";
import artificialintelligencebg11 from "../../images/artificialintelligencebg11.jpg";
import "./index.scss";
class Latestdevelopments4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab2active: 0,
      loaded: false,
    };
  }

  handleLoaded() {
    console.log("aaa");
    this.setState({
      loaded: true,
    });
  }
  render() {
    return (
      <div className="aboutussection">
        <img
          className="latestdeveimg2"
          onLoad={this.handleLoaded.bind(this)}
          src={artificialintelligencebg11}
          alt=""
        ></img>
        {this.state.loaded && (
          <div className="latestdevelopments_main">
            <div className="aboutussection_div">
              <div className="aboutussection_div_div">
                上海医药行业协会化妆品专业委员会成立，优麦科技常江受聘为副主任委员
              </div>
            </div>
            <div className="aboutus_main_div">
              <div className="latestdevelopments_main_div">
                两年前，《化妆品生产经营监督管理办法》、《化妆品功效宣称评价规范》、《化妆品标签管理办法》等重量级行业监管法规陆续生效并实施执行，标志着我国化妆品行业正式迈入“功效”年代。历经两年迅猛发展，生物医药技术驱动的化妆品研发已成为当前功效性化妆品发展的主要模式，“产学研医”共融也成为了化妆品产业精进的法门。
              </div>
              <div className="latestdevelopments_main_div">
                为夯实化妆品皮肤科学的基础和应用研究、助力我国化妆品行业向高科技方向发展，2023年6月6日，正值二十四节气之“芒种”，在这个有着“收获希望”寓意的日子，由上海市皮肤病医院发起，经上海医药行业协会批准，上海医药行业协会化妆品专委会成立大会暨化妆品产业创新发展论坛在上海成功举办。值得一提的是，上海医药行业协会化妆品专业委员会（以下简称“化妆品专委会”）是一个立足上海，以“科学、规范、融合、创新”为理念，专注于化妆品产业高质量发展的行业专委会。
              </div>
              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867061732921638.jpg"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_main_div">
                上海市皮肤病医院朱全刚教授主持开幕式，上海市皮肤病医院院长李斌教授、空军特色医学中心刘玮教授、复旦大学生命科学学院王久存教授、复旦大学附属中山医院皮肤科终身教授秦万章、中国科学院林国强院士、上海市药品监督管理局化妆品监督管理处副处长金鑫、上海市经济和信息化委员会都市产业处处长陈文珊分别发表了致辞。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867065706247190.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                朱全刚教授主持开幕式
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867066444763464.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">李斌教授致辞</div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867066745986881.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">刘玮教授致辞</div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867067019095208.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                王久存教授致辞
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867067414126588.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                秦万章教授致辞
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867067844443093.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                林国强院士致辞
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867068149439974.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                金鑫副处长致辞
              </div>

              <div className="latestdevelopments_main_div">
                金鑫副处长在发言中介绍了近年来的工作部署和行动计划，并表示“作为监管部门必须恪守‘守底线强监管’的底线思维，同时为行业发展做好服务，建立完善行业的沟通交流的机制，把握行业的所需和所想，更贴近行业的需求，探索创新的监管理念和方式，进一步推动行业的高质量发展。”
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867068671056915.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                陈文珊处长致辞
              </div>
              <div className="latestdevelopments_main_div">
                陈文珊处长表示，化妆品专委会的成立将一定会有助于上海化妆品产业的“产学研医”，将价值链、产业链、创新链和服务链四链协同，更好地发挥临床医学和生物科技，“我们也将继续促进创新要素集聚和联合攻关，一起促进新技术新模式来赋能传统的日化行业。”
              </div>

              <div className="latestdevelopments_main_div">
                上海医药行业协会副秘书长肇晖、复旦大学人类表型组学研究院马彦云博士主持了成立仪式。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867069281488158.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                副秘书长肇晖主持成立仪式
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867070016594550.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                马彦云博士主持成立仪式
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867070294356281.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                执行副会长兼秘书长夷征宇宣布化妆品专委会正式成立
              </div>

              <div className="latestdevelopments_main_div">
                上海医药行业协会执行副会长兼秘书长夷征宇宣布上海医药行业协会化妆品专业委员正式成立，她表示，我国化妆品行业正步入变革与机遇并存的新时期，化妆品专业委员会将不断建立健全工作机制，把握行业发展趋势，发挥桥梁纽带作用，打通创新链条，联合多学科多方力量，围绕学术交流、科普宣教、制定标准规范、促进产学研医深度合作、加强行业自律等多个方面开展工作，助力上海化妆品产业的高质量、规范化、可持续发展。“我们也期待更多单位、企业加入，进一步充实专委会的力量，为上海打造化妆品产业高质量健康发展做出更大的贡献。”
              </div>

              <div className="latestdevelopments_main_div">
                随后，夷征宇副会长等领导为专委会主任委员、名誉主任委员等颁发聘书。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867071140254461.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                右起依次为上海医药行业协会执行副会长兼秘书长夷征宇、化妆品专业委员会名誉主任委员/上海市皮肤病医院院长李斌教授、化妆品专业委员会主任委员/上海市皮肤病医院皮肤与化妆品研究室常务副主任/谈益妹副研究员、化妆品专业委员会名誉主任委员/空军特色医学中心原皮肤病医院院长刘玮教授
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867071455819769.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                主任委员谈益妹（右一）为顾问团颁发聘书，左起依次为交通大学附属瑞金医院郑捷教授、中国医学科学院皮肤病医院张怀亮教授、复旦大学附属中山医院皮肤科终身教授秦万章、中国科学院林国强院士、四川大学附属华西医院李利教授、中山大学附属第三医院赖维教授
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867071939218254.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                主任委员谈益妹（右一）为顾问团颁发聘书，左起依次为复旦大学生命科学学院王久存教授、军医科大学附属长征医院温海教授、昆明医科大学第一附属医院云南省皮肤病医院执行院长何黎教授
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867072283608495.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                主任委员谈益妹（左一）、名誉主任委员刘玮教授（左二）以及名誉主任委员李斌教授（右一）为副主任委员颁发聘书。左起第三依次为上海市皮肤病医院朱全刚教授、上海化妆品审评中心主任医师周灯学、交通大学附属仁济医院鞠强教授、复旦大学附属华山医院吴文育教授、上海市食品药品检验研究院副院长王柯、优麦科技CEO常江、上海中医药大学附属岳阳医院李欣教授
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/1686707266025689.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                化妆品专委会主任委员谈益妹发言
              </div>

              <div className="latestdevelopments_main_div">
                上海市皮肤病医院皮肤与化妆品研究室常务副主任/谈益妹副研究员代表化妆品专委会第一届委员发言，她提及了化妆品专业委员会是为了吸纳各领域人才，共同探讨和发展中国化妆品行业而建立的平台，“当前的化妆品已经不是传统意义上简单的工业产品，是多学科交叉融合的高科技产品，因此，‘科学、规范、融合、创新’八个字是化妆品专委会的理念方针，我们将在上海市医药行业协会的领导下，致力于为化妆品产业的产学研医融创提供交流合作共创的平台，以‘服务企业、规范行业、发展产业’为宗旨，为皮肤大健康产业的“上海标准”的建设努力奋斗，推动行业健康发展。”她还着重介绍了专委会计划开展工作的四大方向：组织编写《化妆品皮肤科学》，系统介绍与化妆品相关的皮肤科学知识与进展；开展化妆品不良反应监测的研究和数据库建设；启动化妆品安全与功效评价高质量标准的起草工作；开办化妆品科普知识系列课程，积极普及科学护肤的专业知识。
              </div>

              <div className="latestdevelopments_main_div">
                政策引领，学术加持。
              </div>

              <div className="latestdevelopments_main_div">
                化妆品专委会成立仪式圆满结束后，与会的皮肤科专家在“化妆品产业创新发展论坛”上开展了首次学术分享，交通大学附属瑞金医院郑捷教授、海军医科大学附属长征医院温海教授、中国医学科学院皮肤病医院张怀亮教授共同主持，探讨化妆品研发新范式。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867073459723280.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                左起：温海教授、郑捷教授、张怀亮教授
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867073950985278.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                何黎教授分享课题
              </div>

              <div className="latestdevelopments_main_div">
                昆明医科大学第一附属医院云南省皮肤病医院执行院长何黎教授带来《中国人群敏感性皮肤的研究及应用进展》主题，分享了其团队的研究成果：研究发现了9个与敏感性皮肤屏障受损相关的基因，证实了其中CLDN-5介导敏感性皮肤表皮通透屏障受损，以及如何对敏感性皮肤进行精准干预。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867074766698629.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                李利教授分享课题
              </div>

              <div className="latestdevelopments_main_div">
                四川大学附属华西医院李利教授在《基于数据挖掘网售儿童化妆品的整体状况及安全性分析》中，谈及了如何更好地利用大数据在人体评价、功效检测结果进行管理和分析判断。李利教授还介绍了化妆品人体评价数字化平台应用结果，“我们力求将化妆品检验检测工作做得更科学、更严谨，把好检测质量关，为推动化妆品行业良性发展提供更好的服务，让‘国货之光’也迈向国际。”
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/16867075393929945.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                谈益妹副研究员分享课题
              </div>
              <div className="latestdevelopments_main_div">
                谈益妹副研究员带来了《化妆品人体安全性评测与监测前瞻》主题演讲，就《化妆品安全技术规范》以外的部分化妆品人体安全性试验方法作了介绍，并就人体安全性评测中可能的安全风险产品类别案例和化妆品不良反应因果关系评价地方标准修订情况进行了分享。她建议，化妆品企业可以建立更加完善的、严于国家标准和技术规范的化妆品人体安全性评价试验体系。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/168670758674517.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                刘玮教授分享课题
              </div>

              <div className="latestdevelopments_main_div">
                刘玮教授在《化妆品功效评价前瞻》中，就化妆品的基本属性、功效宣称评价规范化管理和未来研究评价方向作了分享，强调了化妆品功效评价试验原则，提出了目前市场上功效评价现状及面临的困扰，并就如何解决这些问题提出了建议。
              </div>

              <img
                src="https://cdn.umer.com.cn/umer-doctor/20230614/1686707634860728.JPG"
                alt=""
                className="latest_image_tips"
              />

              <div className="latestdevelopments_tips center">
                赖维教授分享课题
              </div>

              <div className="latestdevelopments_main_div">
                中山大学附属第三医院赖维教授分享了《定制化护肤策略与产品化的思考》，他肯定了定制化护肤及产品是未来化妆品发展的趋势，企业要尽早布局和谋划，“但目前该领域的产品实现仍有一定的技术瓶颈和难点，必须要有各方面的共同研究和积极配合，包括产学研、检验检测、政府部门等，将来以定制化护肤及产品作为突破口之一，实现中国‘智’造和大国品牌。”
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Latestdevelopments4;
