import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import medicalServiceCompaniesMain from '../../images/medical-service-companies-main.jpg'
import changjiang from '../../images/changjiang.png'
import hezuolanmu from '../../images/hezuolanmu.png'
import libin from '../../images/libin.png'
import zhuxuejun from '../../images/zhuxuejun.png'
import './index.scss';
class Latestdevelopments4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >第50！优麦科技再登 “未来医疗100强”</div>
                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                近日，由VB100、动脉网、蛋壳研究院主办，上海张江集团战略合作支持的第七届未来医疗100强大会在沪举办。会上，“2023未来医疗100强·中国创新医疗服务榜”评选结果发布，中国领先的皮肤健康产业互联网平台优麦科技位列第50！这也是优麦科技在“未来医疗100强”榜单上梅开二度。
                            </div>
                            <img src={medicalServiceCompaniesMain} alt=""  className='latest_image_tips'/>

                            <div className='latestdevelopments_main_content'>
                                未来医疗100强榜是国内第一个针对非上市企业的创新医疗领域榜单，旨在遴选真正代表未来医疗的创新医疗者，发现未来医疗产业的核心力量，推动健康医疗产业的创新变革进程。榜单创立至今，已成为医健领域的投资风向标，引领当前乃至今后一段时期中国医健创新领域的发展与投资风向，为创新企业后续融资提供借鉴与参考。
                            </div>

                            <div className='latestdevelopments_main_content'>
                                优麦科技创始人、CEO常江表示：“非常高兴优麦科技再度登上‘未来医疗100强’榜单，而且排名较去年有大幅提升，这是对我们过往历程的肯定，更激励我们坚定地朝着充满希望的未来迈进。优麦科技秉承‘科技创新改善皮肤健康’的使命，致力于打造不断完善的中国皮肤科医生价值平台和皮肤数字医疗健康管理平台。我们有信心在皮肤数字医疗的赛道上勇攀高峰。”
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;