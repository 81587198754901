import React, { Component } from 'react';
import doctorsectionbg from '../../images/doctorsectionbg.jpg'
import section3dht from '../../images/section3dht.png'
import dkle1 from '../../images/dkle1.png'
import dkle2 from '../../images/dkle2.png'
import latestdevelopments2 from '../../images/latestdevelopments2.png'
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import code from '../../images/code.png'
import './index.scss';
class latestdevelopments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11}></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >深耕皮肤数字医疗，优麦科技荣登“2022未来医疗100强”</div>
                            <img style={{ width: '100%' }} src={section3dht}></img>

                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                2022年6月，第六届未来医疗100强大会云峰会如期而至，“2022未来医疗100强·中国创新医疗服务榜”火热出炉，优麦科技首度登榜，排名88位。
                            </div>
                            <img src={latestdevelopments2} alt=""  className='latest_image'/>
                            <div className='latestdevelopments_main_div'>
                                “未来医疗100强”是由VB100、动脉网、蛋壳研究院推出的国内第一个针对非上市企业的创新医疗领域榜单，旨在遴选真正代表未来医疗的中国创新医疗者，发现我国未来医疗产业的核心力量，推动健康医疗产业的创新变革进程。
                            </div>
                            <div className='latestdevelopments_main_content'>
                                优麦科技成立于2015年，旗下公司业务深度垂直于皮肤数字医疗健康领域，致力于打造不断完善的中国皮肤科医生价值平台和皮肤数字医疗健康管理平台。由优麦医生APP、优麦皮肤互联网医院、优麦科研院等业务模块构建的产品矩阵，紧密围绕皮肤科医生的医教研及科普等价值刚需，以闭环式服务引领皮肤产业互联网的新趋势。
                            </div>
                            <div className='latestdevelopments_main_content latestdevelopments_main_bottom'>
                                本届未来医疗100强大会以“中国故事”为基础视角，以发生在中国的本土创新、中国本土创新给世界难题带来的全新解决方案为叙事脉络，通过中国故事主人公们的讲述，传递属于中国生命科学产业自己的故事。在“科技创新改善皮肤健康”这一公司使命的驱动下，优麦科技立志谱写皮肤数字医疗之传奇，为“健康中国”添上浓墨重彩的一笔。
                            </div>
                            
                        </div>
                </div>
                } 
            </div>
        );
    }
}

export default latestdevelopments;