import React, { Component, Fragment } from 'react';
import './Bgbutton.scss'
import bg from '../../images/Bgbtn.png'
class Bgbutton extends Component {
    constructor(props) {
        super(props);
    }
    handleClick=()=>{
        sessionStorage.setItem('tabactive',this.props.tabactive)
    }
    render() {
        return (
            <Fragment>
                <div className='bgbtn_main'>
                    <a href={this.props.href} onClick={()=>this.handleClick()}>{this.props.txt}</a>
                    <img src={bg}/>
                </div>
                
            </Fragment>
        );
    }
}

export default Bgbutton;