import React, { Component } from 'react';
import './umerdoctor.scss';
import Swiper from 'swiper/js/swiper.min.js';
// import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import section3dht from '../../images/umerdoctorline.png'
import doctorsectionds from '../../images/doctorsectionds.png'
import CDAlogo from '../../images/CDAlogo.png'
import docotorapp from '../../images/docotorapp.png'
import docotorwx from '../../images/docotorwx.png'
import doctoru from '../../images/doctoru.png'
import docortyy from '../../images/docortyy.png'
import docotorzlhz from '../../images/docotorzlhz.png'
import qrcord from '../../images/qrcode.png'
import umerdoctor1 from '../../images/umerdoctor1-2.png'
import phone2 from '../../images/Reflection.png'
import umerdoctor3 from '../../images/umerdoctor3.png'
import enterpriseservices from '../../images/enterpriseservices.png'
import enterpriseservicestwo from '../../images/enterpriseservicestwo.png'
import footer_last2 from '../../images/index_footer_2.png'
import healthcommission from '../../images/healthcommission.png'
import CSIDlogo from '../../images/CSIDlogo.png'
import CSID from '../../images/CSID.png'
import nonpubliclogo from '../../images/nonpubliclogo.png'
import nonpublic from '../../images/nonpublic.png'
import zxyjh_2 from '../../images/zxyjh_2.png'
import zxyjh from '../../images/zxyjh.png'
import app_2 from '../../images/app_2.png'
import Youmaihuixun_2 from '../../images/Youmaihuixun_2.png'
import Ulol2 from '../../images/Ulol2.png'
import enterpriseservices2 from '../../images/enterpriseservices2.png'
import strategicpartner2 from '../../images/strategicpartner2.png'
class umerdoctor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: 0,
            wrap: null,
            loaded: false
        }
        this.swiperIdRef = el => {
            this.swiperId = el;
        };
        this.swiperPreRef = el => {
            console.log('swiperPreRef-el:', el)
            this.swiperPre = el
        }
        this.swiperNextRef = el => {
            this.swiperNext = el
        }
        this.wrap = null;
    }
    componentDidMount() {
        console.log(sessionStorage.getItem('tag'));
        if(sessionStorage.getItem('tag')){
            this.setState({
                tag:sessionStorage.getItem('tag')
            })
        }


    }
    prevSwiper() {
        this.wrap.slidePrev()
    }
    nextSwiper() {
        this.wrap.slideNext()
    }
    handleMouseDown(e) {
        this.setState({
            tag: e
        }, () => {
            if (this.wrap && e === 3) {
                console.log(this.wrap)
                // this.wrap.destroy()
                this.wrap = null;
            }
            if (!this.wrap) {
                console.log(this.wrap)
                let that = this;
                this.wrap = new Swiper(this.swiperId, {
                    loop: true,  //循环
                    // autoplay: {   //滑动后继续播放（不写官方默认暂停）
                    //     disableOnInteraction: false,
                    // },
                    // effect: 'fade',
                    fadeEffect: {
                        crossFade: true,
                    },
                    navigation: {
                        nextEl: that.swiperPre,
                        prevEl: that.swiperNext,
                        // nextEl: '.swiper-button-prev',
                        // prevEl: '.swiper-button-next',
                    },
                })
            }

        })
    }
    getRef() {
        console.log('ref:', this.swiperId)
    }
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })
    }
    render() {
        return (
            <div className='doctorsection'>
                <img className='doctorsectionimg' src={footer_last2} onLoad={this.handleLoaded.bind(this)} onClick={this.getRef.bind(this)}></img>
                {
                    this.state.loaded &&
                    <div className='doctorsectiondiv'>
                        <div className='doctorsectiondiv_main1'>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 0)}>
                                <img src={docotorapp} className={this.state.tag == 0 ? 'main_div_icon main_div_icon_active' : 'main_div_icon'}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 0 ? '#87DCF1' : '#FEFFFF' }}>优麦医生APP</div>
                            </div>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 1)}>
                                <img src={docotorwx} className={this.state.tag == 1 ? 'main_div_icon main_div_icon_active' : 'main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 1 ? '#87DCF1' : '#FEFFFF' }}>优麦科研院</div>
                            </div>
                            {/* <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 2)}>
                                <img src={doctoru} className={this.state.tag == 2 ? 'main_div_icon main_div_icon_active' : 'main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 2 ? '#87DCF1' : '#FEFFFF' }}>优麦皮肤互联网医院</div>
                            </div> */}

                        </div>
                        {
                            this.state.tag == 0 &&
                            <div className='main2_div'>
                                <img src="https://cdn.umer.com.cn/umer-doctor/20240826/11002119_626_1724659099433_微信图片_20240826155812.png" className='main2_div_img'></img>
                                {/* <div>
                                    <div className='main2_div_div'>致力于构建中国皮肤科专属生态圈</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                    <div className='main2_div_div2'>
                                        <ul className='main2_div_ul'>
                                            <li>专家学术内容系统传播，结合互动，使医生轻松get医学专业知识  </li>
                                            <li>皮肤科线下年会直播、卫星会直播</li>
                                            <li>企业定制课程，结合专家学术内容传</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='main2_contentimg main2_content_frist'>

                                    <img src={qrcord}></img>
                                    <img src={umerdoctor1}></img>
                                </div> */}

                            </div>
                        }
                        {
                            this.state.tag == 1 &&
                            <div className='main2_div'>
                                <img src={Youmaihuixun_2} className='main2_div_img'></img>
                                {/* <div>
                                    <div className='main2_div_div'>中国医师协会皮肤科医师分会</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                </div>
                                <div className='main2_contentimg main2_content_second'>
                                    <img src={qrcord}></img>
                                    <img src={phone2} />
                                    <ul className='main2_div_ul main2_div_ul_second'>
                                        <li>关注人数 2500+</li>
                                        <li>用户分布于34省份</li>
                                        <li>会议查询</li>
                                        <li>会议报道</li>
                                        <li>咨询快递</li>
                                        <li>专题策划</li>
                                        <li>直播信息</li>
                                    </ul>
                                </div> */}

                            </div>
                        }
                        {
                            // this.state.tag == 2 &&
                            // <div className='main2_div'>
                            //     <img src={Ulol2} className='main2_div_img'></img>
                            //     {/* <div>
                            //         <div className='main2_div_div'>U+联盟是优麦医生发起的皮肤相关专业联盟</div>
                            //         <img className='main2_div_line' src={section3dht}></img>
                            //         <div className='main2_div_div2p'>
                            //             <p>U+联盟将链接国内皮肤科权威协会和大咖，<br />通过图文、短视频、直播等方式为市场和消费者们产出专业的皮肤知识和内容。</p>
                            //             <p></p>
                            //             <p className='main2_bcpt' style={{ color: '#6ab8d2' }}>露出平台：</p>
                            //         </div>
                            //     </div>
                            //     <div style={{ textAlign: 'center' }}>
                            //         <img className='main2_contentimg_p' src={umerdoctor3}></img>
                            //     </div> */}
                            // </div>
                        }
                    </div>
                }

            </div>
        );
    }
}

export default umerdoctor;