import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import './index.scss';

class Latestdevelopments4 extends Component {
    timer = null

    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

        
        clearInterval(this.timer)

        this.timer = setInterval(() => {
            let newCur = this.state.cur + 1

            if (newCur > this.state.imageGroup && this.state.imageGroup.length - 1) {
                newCur = 0
            }

            this.setState({
                cur: newCur
            })
        }, 2000);
    }

    maxShow = 3

    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >中国非公医协皮肤专委会二届换届选举会议暨三届一次全委会议在南京成功召开</div>
                        </div>


                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932054566363226.jpg" alt=""  className='main_image'/>

                        <div className='latestdevelopments_tips center'>
                            专委会第三届委员会全体成员合影
                        </div>

                        <div className='latestdevelopments_main_content main-content'>
                            2023年8月17日晚，中国非公立医疗机构协会（以下简称“中国非公医协”）皮肤专业委员会（以下简称“专委会”）在南京成功召开专委会第二届委员会换届选举会议暨第三届委员会第一次全体委员会议。中国非公医协常务副会长兼秘书长郝德明、副秘书长兼会员组织部主任王珊、会员组织部副主任杨子旭，专委会第二届委员会主任委员、换届领导小组组长郑志忠教授、副主任委员、换届领导小组副组长项蕾红教授，以及第三届委员会全体委员候选人等260余人参加会议。会议由王珊副秘书长主持。
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932068318559530.jpg" alt=""  className='main_image'/>

                        <div className='latestdevelopments_tips center'>
                            王珊副秘书长主持
                        </div>

                        <div className='latestdevelopments_tips main-tip center'>
                            郑志忠教授致辞并作专委会第二届委员会工作报告
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932097019932466.jpg" alt=""  className='latest_image'/>

                        <div className='latestdevelopments_main_content'>
                            首先由郑志忠主任委员致辞，并作专委会第二届委员会工作报告。他从专委会的组织建设、学术会议举办、评价标准制定、基地建设以及企业合作等方面汇报了专委会第二届委员会取得的工作成效。他提到，截至目前专委会已通过视频联动、线上直播、专科学术专题、系列公益课程、全国巡讲等形式开展了多期会议活动，并在行业自律、标准制定、医药及相关设备产学研结合和投融资等方面开展了大量工作，切实服务于广大会员单位。他表示，皮肤专委会在总会的领导下，在各级委员的支持配合下，经过八年的发展，取得了累累硕果，得到了总会的高度认可，获得了较好的社会评价和广泛赞誉，这与各位同仁的支持和帮助是密不可分的，借此机会，他向给予专委会支持的各界人士表示了衷心的感谢。
                        </div>

                        <div className='latestdevelopments_tips center'>
                            吉喆秘书长作报告
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932070922909091.jpg" alt=""  className='latest_image'/>

                        <div className='latestdevelopments_main_content'>
                            专委会秘书长吉喆作第二届委员会换届筹备工作的报告。她就换届领导小组产生、委员候选人招募、遴选，以及换届选举会议的组织筹备工作进行了汇报。
                        </div>

                        <div className='latestdevelopments_tips center'>
                            投票选举环节
                        </div>
                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/1693207179240415.jpg" alt=""  className='latest_image'/>
                       
                        <div className='latestdevelopments_main_content'>
                            经民主表决，会议选举产生了专委会第三届委员会领导班子及各级委员共270人，其中常务委员72名，领导班子17名。由项蕾红教授担任专委会第三届委员会主任委员，由常江、陈卫东、顾军、郝飞、何威、李雪莉、林雄心、林宗华、马岚、王敏莉、王振刚、吴文育、杨斌、周梅、赵小忠担任专委会第三届委员会副主任委员（按姓氏拼音排序），由吉喆担任专委会第三届委员会秘书长。会议还审议通过了专委会第三届委员会《管理办法》。
                        </div>

                        <div className='latestdevelopments_main_content'>
                            经项蕾红主任委员提名并经委员会一致审议通过，由专委会创始人、第一、二届主任委员、郑志忠教授担任专委会终身名誉主任委员，由何黎教授担任专委会党建工作高质量发展专家总顾问，由陈向东、郭振宇、孟中平、宋为民、吴美先担任第三届委员会顾问。
                        </div>
                        <div className='latestdevelopments_tips center'>
                            项蕾红主任委员发表就职讲话
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932078571832159.jpg" alt=""  className='latest_image'/>

                        <div className='latestdevelopments_main_content'>
                            专委会第三届委员会主任委员项蕾红发表就职讲话并展望专委会下一步工作。她表示，相信在总会的指导与大力支持下，在各位领导班子成员、常务委员、委员以及各专业组配合下，皮肤专委会第三届委员会定能继续发扬第二届委员会拼搏实干的奋斗精神，秉承专委会成立的初心，肩扛使命，搭建更多交流平台，继续做好会员发展、组织建设、学术培训、国家行业评价等工作，在规范、帮扶、创新、提高、发展的路上不忘初心、继续前行，推动专委会各项工作实现新的跨越式发展，努力为我国皮肤医学事业的发展做出新的更大的贡献。 
                        </div>

                        <div className='latestdevelopments_tips center'>
                            委员代表发言
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932079964872839.jpg" alt=""  className='latest_image'/>

                        <div className='latestdevelopments_main_content'>
                            各委员代表就第三届委员会发展进行发言，大家一致对继续做大做强专委会表明了信心和决心，同时也对第三届委员会的发展做出了展望和希冀。大家表示，将与专委会携手共进，发挥自身优势，为皮肤医学事业发展贡献自己的力量。 
                        </div>

                        <div className='latestdevelopments_tips center'>
                            郝德明会长作重要讲话
                        </div>

                        <img src="https://cdn.umer.com.cn/umer-doctor/20230828/16932080247077688.jpg" alt=""  className='latest_image'/>

                        <div className='latestdevelopments_main_content'>
                            会议最后，中国非公医协常务副会长兼秘书长郝德明发表重要讲话。他对以项蕾红教授为主任委员的皮肤专委会第三届委员会全体成员表示热烈的祝贺，并就专委会下一步工作提出了建议，一是要坚持党的领导，进一步提高政治站位，切实增强做好专委会工作的责任感、荣誉感和使命感；二是要进一步夯实组织基础，切实加强专委会自身建设；三是要进一步突出工作重点，推动各项工作任务落实落地。他希望，皮肤专委会各位同仁继续支持总会和专委会的工作，积极出谋划策，为促进我国皮肤医疗事业持续健康发展继续努力奋斗，为最终实现多元化的办医格局作出应有的贡献！
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;