import React, { Component } from 'react';
import footer_last2 from '../../images/index_footer_2.png'
import './drugs.scss';

class drugs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }
    
    itemList = [
        {
            imgUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_384_1728375253922_图片1.png',
            detailUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_830_1728375264208_图片2.png',
            title: '良济 玫芦消痤膏 15g'
        },
        {
            imgUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_480_1728378331612_图片11.png',
            detailUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_953_1728378339542_图片12.png',
            title: '维福佳 复合维生素B片20片/板*3板/盒'
        },
        {
            imgUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_1037_1728378381416_图片13.png',
            detailUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_558_1728378392587_图片14.png',
            title: 'ccpc 盐酸西替利嗪片 10mg*12片'
        },
        {
            imgUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_1071_1728378451632_图片15.png',
            detailUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_403_1728378458060_图片16.png',
            title: '康角丫 水杨酸复合洗剂 15g*2包 6g*2包/盒'
        },
        {
            imgUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_1096_1728375473034_图片9.png',
            detailUrl: 'https://cdn.umer.com.cn/umer-doctor/20241008/11014970_703_1728375480334_图片10.png',
            title: '升和 肤痒颗粒 9g*10袋（每袋装9克）'
        }
    ]

    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

    }

    imageClick(url){
        window.location.href = url
    }

    render() {
        return (
            <div className='drugssection' >
                <img className='drugssectionimg' src={footer_last2} onLoad={this.handleLoaded.bind(this)} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='drugs_main'>
                        {
                            this.itemList.map(item => <>
                                <div className='drug-item'>
                                    <img className='drug-item-img' src={item.imgUrl} onLoad={this.handleLoaded.bind(this)}  alt=""></img>

                                    <div className='title'>{item.title}</div>

                                    <div className='btn' >
                                    <a href={item.detailUrl} target='blank'>查看详情 ></a></div>
                                </div>
                            </>)
                        }
                    </div>
                }
            </div>
        );
    }
}

export default drugs;