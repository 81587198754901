import React, { Component } from "react";
import artificialintelligencebg11 from "../../images/artificialintelligencebg11.jpg";
import ReactHtmlParser from "html-react-parser";
import "./index.scss";

const html1 = `<div>
    <div id="activity-name">重磅 | 优麦医生成为美国皮肤科学会(AAD)官方授权合作伙伴</div>

<img src="https://cdn.umer.com.cn/umer-doctor/20240305/17096042106316794.jpg" />
</div>`

class Latestdevelopments4 extends Component {
    timer = null
  constructor(props) {
    super(props);
    this.state = {
        tab2active: 0,
    }
  }

    
    
    handleLoaded() {
    }
  maxShow = 222
  render() {
    return (
      <div className="aboutussection">
        <img
          className="latestdeveimg2"
          onLoad={this.handleLoaded.bind(this)}
          src={artificialintelligencebg11}
          alt=""
        ></img>
        <div className="latestdevelopments_main latestdevelopments17_main">
            {
                ReactHtmlParser(html1, { ignoreCase: false })
            }
        </div>
      </div>
    );
  }
}

export default Latestdevelopments4;
