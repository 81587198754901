import React, { Component } from 'react';
import '../index.css'
import errorimg from '../images/404.png'
class error404 extends Component {
    render() {
        return (
            <div className='error_404'>
                <img src={errorimg} alt=""/>
                <p>友情提醒：找不到页面</p> 
            </div>
        );
    }
}

export default error404;