import React, { Component } from 'react';
import docotorapp from '../../images/docotorapp.png'
import docotorwx from '../../images/docotorwx.png'
import doctoru from '../../images/doctoru.png'
import docortyy from '../../images/docortyy.png'
import docotorzlhz from '../../images/docotorzlhz.png'
import section3dht from '../../images/umerdoctorline.png'
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import Skintreatmtmanagemetplatform from '../../images/Skintreatmtmanagemetplatform.png'
import footer_last2 from '../../images/index_footer_2.png'
import solutionimg from '../../images/solutionimg.png'
import multimodalimage from '../../images/multimodalimage.png'
import smallicons from '../../images/smallicons.png'
import MIISimg from '../../images/MIISimg.png'
import VS from '../../images/VS.png'
import aibtn from '../../images/aibtn.png'
import rgzn from '../../images/rgzn.png'
import zncf from '../../images/zncf.png'
import aisia from '../../images/aisia.png'
import './artificialintelligence.scss'

class artificialintelligence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: 0,
            loaded: false
        }
    }
    handleMouseDown(e) {
        this.setState({
            tag: e
        })
    }
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

    }
    render() {

        return (
            <div className='doctorsection'>
                <img className='artificialintelligenceBgimg' onLoad={this.handleLoaded.bind(this)} src={artificialintelligencebg11} ></img>
                {
                    this.state.loaded &&
                    <div>
                    <div className='artificialintelligencesectiondiv'>
                        <div className='doctorsectiondiv_main1'>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 0)}>
                                <img src={aibtn} className={this.state.tag == 0 ? 'arti_main_div_icon arti_main_div_icon_active' : 'arti_main_div_icon'}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 0 ? '#87DCF1' : '#FEFFFF' }}>多模态皮肤影像管理平台</div>
                            </div>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 1)}>
                                <img src={aibtn} className={this.state.tag == 1 ? 'arti_main_div_icon arti_main_div_icon_active' : 'arti_main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 1 ? '#87DCF1' : '#FEFFFF' }}>优智云MIIS系统</div>
                            </div>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 2)}>
                                <img src={aibtn} className={this.state.tag == 2 ? 'arti_main_div_icon arti_main_div_icon_active' : 'arti_main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 2 ? '#87DCF1' : '#FEFFFF' }}>USkin智能测肤</div>
                            </div>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 3)}>
                                <img src={aibtn} className={this.state.tag == 3 ? 'arti_main_div_icon arti_main_div_icon_active' : 'arti_main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 3 ? '#87DCF1' : '#FEFFFF' }}>银屑病PASI自动评分</div>
                            </div>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 4)}>
                                <img src={aibtn} className={this.state.tag == 4 ? 'arti_main_div_icon arti_main_div_icon_active' : 'arti_main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 4 ? '#87DCF1' : '#FEFFFF' }}>AISIA皮肤检测仪</div>
                            </div>
                            <div className='main1_div' onMouseDown={this.handleMouseDown.bind(this, 5)}>
                                <img src={aibtn} className={this.state.tag == 5 ? 'arti_main_div_icon arti_main_div_icon_active' : 'arti_main_div_icon '}></img>
                                <div className='main_div_app' style={{ color: this.state.tag == 5 ? '#87DCF1' : '#FEFFFF' }}>皮肤治疗管理平台</div>
                            </div>
                        </div>
                        {
                            this.state.tag == 3 &&
                            <div className='main2_div'>
                                <div>
                                    <div className='main2_div_div'>行业现状</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                    <div className='man2_hyxz'>
                                        是临床上通用的银屑病严重程度评分方法，受累面积、皮损的红斑、鳞屑、厚度，在不同部位等综合评估。
                                        由于评分依赖主管因素较多，在评分的结果可信度、可衡量等方面、不同医生差异较大、在制定合理的治疗方案、评估病情等方面，
                                        均存在问题。PASI自动评分，是利用自然语言图形图像的算法、人工智能等技术，实现对皮损面积、红斑、鳞屑、厚度的智能激素按，
                                        为评估疾病病情及疗效，提供客观的、便捷的、可衡量的数据。
                            </div>
                                </div>

                            </div>
                        }
                        {
                            this.state.tag == 4 &&
                            <div className='main2_div'>
                                <div>
                                    <div className='man2_hyxz'>
                                        一款通过关键点定位技术获取高质量、可重复性皮肤图像，结合多光谱照射皮肤，通过AI算法深层量化剖析皮肤问题的检测仪器。<br></br> <br></br>

                                        <div className='main2_div_title'>8光谱：</div>
                                        RGB白光、平行偏振光、交叉偏振光、UV光、伍氏光、蓝光、复合光红素图、复合光热力图。<br></br><br></br>

                                        <div className='main2_div_title'>量化分析：</div>
                                        皮脂、毛孔、痤疮、皱纹、纹理、色斑、肤色、色素、水分、粉刺、敏感度等。<br></br><br></br>

                                        <div className='main2_div_title'>算法：</div>
                                        AISIA搭建具有自主知识产权的完全自主研发的颜面部软组织底层算法框架结构，基于百万级黄种人面部多光谱的特征数据、痤疮等数据，进行人工智能模型的训练，并融合机器学习、深度学习、对抗学习等先进成熟可靠的模型，从面部问题出发，融合面部疾病、面部特征，创建了融合2D、3D、4D、多光谱算法仓库，形成了独具黄种人面部特色的算法系统。

                                    </div>
                                    <img className='aisia' src={aisia}></img>
                                </div>

                            </div>
                        }
                        {
                            this.state.tag == 0 &&
                            <div className='main2_div'>
                                <div>
                                    <div className='main2_div_div'>多模态皮肤影像中心工作流程示意图</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                    <div className='man2_dmt'>
                                        * 支持对接各种影像设备
                                </div>
                                    <div className='intelligence_tag1_div'>
                                        <div>
                                            <div className='intelligence_tag1_div'>
                                                <img src={smallicons}></img>
                                                <div className='intelligence_tag1_div_div'>应用</div>
                                            </div>
                                            <ul className='joinus_main_div_ul2 intelligence_tag1_div_ul'>
                                                <li>多模态影像数据集中统一管理</li>
                                                <li>高级检索搜图</li>
                                                <li>多终端数据同步</li>
                                                <li>多终端调阅</li>
                                                <li>各种影像硬件数据共享</li>
                                                <li>多模态诊断报告</li>
                                                <li>支持远程会诊</li>
                                                <li>医联体数据共享</li>
                                                <li>工作量统计</li>
                                                <li>权限管理</li>
                                            </ul>
                                            <div className='intelligence_tag1_div'>
                                                <img src={smallicons}></img>
                                                <div className='intelligence_tag1_div_div'>科研</div>
                                            </div>
                                            <ul className='joinus_main_div_ul2 intelligence_tag1_div_ul'>
                                                <li>AI科技</li>
                                                <li>多模态影像数据导出</li>
                                                <li>高级搜索影像数据</li>

                                            </ul>
                                            <div className='intelligence_tag1_div'>
                                                <img src={smallicons}></img>
                                                <div className='intelligence_tag1_div_div'>定制</div>
                                            </div>
                                            <ul className='joinus_main_div_ul2 intelligence_tag1_div_ul'>
                                                <li>专业报告模板</li>
                                                <li>统计分析</li>
                                                <li>院际间数据共享</li>
                                                <li>数据加密</li>
                                                <li>云数据库构建</li>
                                                <li>对接互联网医院</li>

                                            </ul>
                                        </div>
                                        <div>
                                            <img className='multimodalimage' src={multimodalimage}></img>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                        {
                            this.state.tag == 5 &&
                            <div className='main2_div'>
                                <div>
                                    <div className='main2_div_div'>行业现状</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                    <div className='man2_hyxz'>
                                        皮肤激光美容科是多环节、多人协同的工作模式，为患者建档、拍照、明确治疗方案后都需要登记，
                                        在数据的获取、留存、归档、查询等环节，后期的科研、教学所需的数据搜索、整理，都耗费人力和时间，极为不便。
                                </div>
                                    <img className='main2_img' src={Skintreatmtmanagemetplatform}></img>
                                    <div className='main2_div_div'>优麦科技解决方案</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                    <div className='man2_hyxz'>
                                        皮肤治疗管理平台在设计建设过程中，充分遵循皮肤激光美容科的专业和规范，
                                        从医技术人员操作的实用性触发、以简单便捷为导向。突出规范、便捷、实用、扩展等原则，确保系统功能的
                                   <span className='man2_hyxz_span'>便捷性</span>、操作的<span className='man2_hyxz_span'>友好性</span>、数据的<span className='man2_hyxz_span'>共享性</span>。
                                </div>
                                    <img className='main2_img' src={solutionimg}></img>
                                </div>

                            </div>
                        }
                        {
                            this.state.tag == 1 &&
                            <div className='main2_div'>
                                <img className='main2_div_img' src={rgzn}></img>
                                {/* <div>
                                    <div className='main2_div_div'>提升基层医生诊断能力</div>
                                    <img className='main2_div_line' src={section3dht}></img>
                                    <div>
                                        <div className='intelligence_tag1_div'>
                                            <div>
                                                <div className='intelligence_tag1_div_bt'>云MIIS系统，丰富和提升基层医院皮肤病诊疗能力。</div>
                                                <ul className='joinus_main_div_ul2 intelligence_tag1_div_ul_miis'>
                                                    <li>突出云服务构建融合临床数据</li>
                                                    <li>独有的PC+APP共享协作模式</li>
                                                    <li>集成【人工智能】辅助诊断功能</li>
                                                    <li>满足基层医院开展皮肤镜业务</li>
                                                    <li>具有专家知识库/诊断模板</li>
                                                    <li>支持远程协作</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <img src={MIISimg}></img>
                                            </div>
                                        </div>
                                        <div className='intelligence_tag1_div_yzpfzl'>优智皮肤肿瘤AI 2.0</div>
                                        <div className='intelligence_tag1_div intelligence_tag1_div_two'>
                                            <div>
                                                <div className='div_border_radius_left'>CSID皮肤肿瘤AI</div>
                                                <div className='intelligence_tag1_div_yzpfzl_left'>良恶性识别<span className='man2_hyxz_span'>85.2%</span></div>
                                                <div className='intelligence_tag1_div_yzpfzl_left'>疾病类型识别<span className='man2_hyxz_span'>66.7%</span></div>
                                            </div>
                                            <div className='div_vs'>
                                                <img className='vs' src={VS}></img>
                                            </div>
                                            <div>
                                                <div className='div_border_radius_right'>斯坦福皮肤肿瘤AI</div>
                                                <div className='intelligence_tag1_div_yzpfzl_right'><span className='man2_hyxz_span_right'>72.1%</span>良恶性识别</div>
                                                <div className='intelligence_tag1_div_yzpfzl_right'> <span className='man2_hyxz_span_right'>55.4%</span>疾病类型识别</div>
                                            </div>
                                        </div>
                                        <div className='intelligence_tag1_div_yzpfzl'>优智皮肤黑甲AI 1.0</div>
                                        <div className='intelligence_foot_font'>平均识别率达到<span style={{ color: '#7dddff' }}>87%</span>，超过80%皮肤科专业医生的诊断能力，尤其适用于基层医生的诊断与鉴别诊断。</div>
                                        <div className='div_Table'>
                                            <div className='Table'>
                                                <div className='Table_Row_one_left'>疾病分类</div>
                                                <div className='Table_Row_one_center'>疾病名</div>
                                                <div className='Table_Row_one_right'>疾病识别率</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_even'>甲母质痣</div>
                                                <div className='Table_Row_even_center'></div>
                                                <div className='Table_Row_even_rigth'>91%</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_odd'>甲恶黑</div>
                                                <div className='Table_Row_odd_center'></div>
                                                <div className='Table_Row_odd_right'>93%</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_even'>甲下出血</div>
                                                <div className='Table_Row_even_center'></div>
                                                <div className='Table_Row_even_rigth'>88%</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_odd'></div>
                                                <div className='Table_Row_odd_center'>甲影响不良</div>
                                                <div className='Table_Row_odd_right'>85%</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_even'></div>
                                                <div className='Table_Row_even_center'>甲真菌病</div>
                                                <div className='Table_Row_even_rigth'>83%</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_odd'>其他甲病</div>
                                                <div className='Table_Row_odd_center'>甲活化</div>
                                                <div className='Table_Row_odd_right'>89%</div>
                                            </div>
                                            <div className='Table'>
                                                <div className='Table_Row_even'></div>
                                                <div className='Table_Row_even_center'>甲色素条带</div>
                                                <div className='Table_Row_even_rigth'>79%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        }
                        {
                            this.state.tag == 2 &&
                            <div className='main2_div'>
                                <img className='main2_div_img' src={zncf}></img>
                            </div>
                        }
                    </div>
                    <div>

                    </div>
                </div>
                }
              
            </div>
        );
    }
}

export default artificialintelligence;