import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import './index.scss';

class Latestdevelopments4 extends Component {
    timer = null

    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false,
            imageGroup: [
                'https://cdn.umer.com.cn/umer-doctor/images/524/01%E6%B8%A9%E6%B5%B7.jpg',
                'https://cdn.umer.com.cn/umer-doctor/images/524/02%E9%A1%BE%E5%86%9B.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/03%E9%83%91%E5%BF%97%E5%BF%A0.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/04%E4%BD%95%E9%BB%8E.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/05%E8%AE%B8%E7%88%B1%E5%A8%A5.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/06%E9%A1%B9%E8%95%BE%E7%BA%A2.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/07%E5%88%98%E7%8E%AE.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/08%E5%8F%B2%E7%8E%89%E7%8E%B2.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/09%E5%AD%99%E5%BB%BA%E6%96%B9.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/10%E5%88%98%E7%BB%B4%E8%BE%BE.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/11%E5%91%A8%E5%86%AC%E6%A2%85.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/12%E9%83%9D%E9%A3%9E.jpg',
                'https://cdn.umer.com.cn/umer-doctor/images/524/13%E9%9E%A0%E5%BC%BA.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/14%E9%99%88%E6%9F%B3%E9%9D%92.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/15%E5%90%B4%E6%96%87%E8%82%B2.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/16%E9%82%93%E4%B8%B9%E7%90%AA.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/17%E6%BB%A1%E5%AD%9D%E5%8B%87.jpg',
                'https://cdn.umer.com.cn/umer-doctor/images/524/18%E5%86%89%E7%8E%89%E5%B9%B3.jpg',
                'https://cdn.umer.com.cn/umer-doctor/images/524/19%E9%A9%AC%E8%8B%B1.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/20%E8%B5%B5%E6%81%92%E5%85%89.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/21%E5%A7%9C%E6%96%87%E6%88%90.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/22%E6%9D%8E%E6%AC%A3.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/23%E9%99%88%E4%BD%B3.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/24%E5%B8%83%E6%96%87%E5%8D%9A.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/25%E6%9E%97%E5%B0%BD%E6%9F%93.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/26%E6%85%95%E5%BD%B0%E7%A3%8A.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/27%E8%8B%8F%E6%83%A0%E6%98%A5.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/28%E9%A9%AC%E5%BD%A6%E4%BA%91.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/29%E5%90%95%E8%89%B3%E6%80%9D.JPG',
                'https://cdn.umer.com.cn/umer-doctor/images/524/30%E6%9E%97%E7%87%95%E8%8C%B9.JPG'
            ],
            cur: 0,
            imageGroupHeight: 0
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

        
        clearInterval(this.timer)

        this.timer = setInterval(() => {
            let newCur = this.state.cur + 1

            if (newCur > this.state.imageGroup.length - 1) {
                newCur = 0
            }

            this.setState({
                cur: newCur
            })
        }, 2000);
    }

    maxShow = 3

    render() {
        const {
            imageGroup,
            cur
        } = this.state

        let next = cur + 1

        if (next > this.state.imageGroup.length - 1) {
            next = 0
        }

        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >年会客厅+年会U直播，点亮2023全国中西医结合皮肤性病学术年会</div>
                        </div>
                        <div className='aboutus_main_div'>
                            <div className='latestdevelopments_main_div'>
                                齐“新”合力，聚创未来。4月27-30日，2023全国中西医结合皮肤性病学术年会在云南昆明举行。中国中西医结合学会皮肤性病专业委员会与优麦医生共同打造的“年会客厅”闪亮登场，同时，展台内的现场直播间携“年会U直播”栏目首度亮相，线上线下相结合，助推年会学术亮点。
                            </div>
                            <img className="flow-image" style={ {marginTop: 0} } src="https://cdn.umer.com.cn/umer-doctor/20230526/16850841193076352.JPG" alt=""/>

                            <img className="flow-image" src="https://cdn.umer.com.cn/umer-doctor/20230526/16850841525164192.jpg" alt=""/>

                            <img className="flow-image latest_image_tips" src="https://cdn.umer.com.cn/umer-doctor/20230526/16850841628959794.jpg" alt=""/>

                            <div className="latestdevelopments_tips center">年会客厅现场</div>

                            <div className='latestdevelopments_main_content'>
                                受新冠疫情影响，全国中西医结合皮肤性病学术年会过往三年采取线上形式召开。时隔三年，线下相聚，参会代表热情满满。本届年会注册代表人数约2200人。
                            </div>

                            <div className='latestdevelopments_main_content'>
                            年会期间，中国中西医结合学会皮肤性病专业委员会与优麦医生共同打造的“年会客厅”，吸引了大量参会代表驻足、围观，访客络绎不绝。“客厅”内还展示了专委会品牌项目“919中国痤疮周”的历程回顾以及过往三年的在线年会数据等内容，让参会代表更多地了解专委会近年来的工作成果。
                            </div>

                            <div className='latestdevelopments_main_content'>
                            作为本次年会的亮点之一，中国中西医结合学会皮肤性病专业委员会与优麦医生联合呈现的“年会U直播”栏目，首次登陆“年会客厅”内特设的现场直播间。两天时间，业内大咖、青年学者轮番亮相“年会U直播”，共呈现30场直播访谈，内容涉及痤疮、白癜风、银屑病、中西医结合优势病种、皮肤病理及疑难病例、化妆品、医学美容等众多热门专场主题。
                            </div>

                            <div className='image-group'>
                                {
                                    imageGroup.map((item, index) => {
                                        if (Math.abs(index - cur) <= Math.floor(this.maxShow / 2)) {
                                            return <img className="image" key={item} src={item} alt="" style={{ opacity: cur === index ? 1 : 0 }} />
                                        } else {
                                            return ''
                                        }
                                    })
                                }

                                <img style={{ opacity: 0, display: 'block', width: '100%' }} src={imageGroup[0]} alt="" />
                            </div>
                            
                            <div className="latestdevelopments_tips center">“年会U直播”做客嘉宾集锦</div>

                            <div className="user-img-text">
                                <div className="img-wrapper">
                                    <img style={ { transform: "translateY(-3%)" } } src="https://cdn.umer.com.cn/umer-doctor/20230523/16848272572341401.jpg" alt="" />
                                </div>

                                <div className="content"><span className="stone"></span>
                                中国中西医结合学会皮肤性病专业委员会主任委员、同济大学附属第十人民医院皮肤科主任顾军教授表示，“过去三年的线上年会，为皮肤科医生提供了丰富的学习内容。本届年会是时隔三年之后，全国的参会代表首次在线下汇聚一堂。创新的“年会客厅”形式给代表们提供了更轻松的参会氛围，同时现场直播间内开展直播访谈，为年会学术亮点的传播提供了十分有力的助推。”
                                </div>
                            </div>

                            <div className='latestdevelopments_main_content'>
                            “年会客厅”内还为参会代表准备了学术好礼。优麦医生特别印制的《皮肤科知识思维导图口袋书》以及《痤疮治疗思维导图》在现场免费派发。《口袋书》收录了皮肤科常见疾病诊疗路径，对部分理论知识进行了简明扼要的诠释，强化对疾病学习内容的记忆与理解，可谓皮肤科医生的“移动充电宝”。
                            </div>

                            <img className="latest_image_tips" src="https://cdn.umer.com.cn/umer-doctor/20230526/16850842194889243.JPG" alt=""/>
                            
                            <div className="latestdevelopments_tips center">《皮肤科知识思维导图口袋书》</div>

                            <div className='latestdevelopments_main_content'>
                            此外，现场展示屏上播放的优麦医生APP精选短视频，也吸引了众多参会代表驻足观看。据介绍，为满足医生利用碎片化时间进行学习的需求，优麦医生APP全新上线短视频功能，首批内容集结治疗实操演示、实战诊治技术分享、诊断与鉴别分析、治疗用药经验、疑难案例解析等。
                            </div>

                            <div className="user-img-text">
                                <div className="img-wrapper">
                                    <img style={ { transform: "translateY(-10%)" } } src="https://cdn.umer.com.cn/umer-doctor/20230523/16848272478338608.jpg" alt="" />
                                </div>

                                <div className="content"><span className="stone"></span>
                                优麦科技创始人、CEO常江表示，“优麦科技始终围绕皮肤科医教研及科普等价值刚需，打造不断完善的中国皮肤科医生价值平台和皮肤数字医疗健康管理平台，旗下的优麦医生是皮肤科医生在线学习平台，致力于为广大的皮肤科医生提供权威、丰富、实用的学习内容。我们也将继续携手中国中西医结合学会皮肤性病专业委员会，共创未来。”
                                </div>
                            </div>

                            <div className='latestdevelopments_main_content'>
                            2017年9月，优麦医生与中国中西医结合学会皮肤性病专业委员会达成战略合作。5年多来，双方携手开拓、创新，在数字化时代共同推进学科发展。
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;