import React, { Component } from 'react';
import doctorsectionbg from '../../images/doctorsectionbg.jpg'
import section3dht from '../../images/section3dht.png'
import footer_last2 from '../../images/index_footer_2.png'
import './aboutus.scss';

class aboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

    }
    render() {
        return (
            <div className='aboutussection'>
                <img className='aboutussectionimg' src={footer_last2} onLoad={this.handleLoaded.bind(this)}></img>
                {
                    this.state.loaded &&
                    <div className='aboutus_main'>
                    <div className='aboutussection_div'>
                        <div  className='aboutussection_div_div' >优麦科技，一个聚焦于智慧医疗的科技创新品牌</div>
                        <img style={{width:'100%'}} src={section3dht}></img>

                    </div>
                    <div className='aboutus_main_div'>
                        <div className='aboutus_main_div_ul'>
                            <div className='about_main_top'>上海麦色信息科技有限公司旗下皮肤医学信息与健康管理品牌</div>
                            <div className='about_main_moudle'>
                                <div className='about_main_moudle_title'>品牌介绍</div>
                                <div className='about_main_moudle_content'>优麦科技上海麦色信息科技有限公司旗下品牌，专注于皮肤数字医疗健康领域，致力于打造不断完善的中国皮肤科医生价值平台和皮肤数字医疗健康管理平台。</div>
                                <div className='about_main_moudle_content'>基于“打造皮肤科数字医疗生态圈”的目标，优麦科技构筑了覆盖“D+B+C”端的专业平台型企业，服务内容涵盖医生在线教育、医药及化妆品数字营销、疾病互联网医院在线诊疗及用药建议等。</div>
                                <div className='about_main_moudle_content'>优麦科技旗下产品包括：优麦医生APP、优麦皮肤互联网医院、优麦科研院、优麦大药房等。</div>
                            </div>
                            <div className='about_main_moudle'>
                                <div className='about_main_moudle_title'>品牌使命及愿景</div>
                                <div className='about_main_moudle_li'> <span className='about_main_moudle_li_title'>使命:</span>科技创新改善皮肤健康</div>
                                <div className='about_main_moudle_li'> <span className='about_main_moudle_li_title'>愿景:</span>中国最具价值的皮肤医疗健康平台</div>
                            </div>
                            <div className='about_main_moudle'>
                                <div className='about_main_moudle_title'>品牌产品和服务</div>
                                <div className='about_main_moudle_content'>品牌已构建医生端（D端）、商业服务端（B端）、大众/患者端（C端）三个板块生态链，合作伙伴包括皮肤科行业协会、医药企业和化妆品企业客户等，在皮肤科垂直领域具备深度影响力。</div>
                                <div className='about_main_moudle_content'>在D端，品牌致力于打造不断完善的中国皮肤科医生价值平台，旗下优麦医生APP是皮肤科医生专属的在线学习平台。</div>
                                <div className='about_main_moudle_content'>在B端，优麦科技为企业提供整合数字营销服务。</div>
                                <div className='about_main_moudle_content'>在C端，优麦皮肤互联网医院是皮肤病专科互联网医院，提供在线诊疗、用药建议、病程管理、精准护肤等一站式服务。优麦皮肤互联网医院+优麦大药房，彻底打通从就诊到配药的业务链路。</div>

                            </div>
                            <div className='about_main_moudle'>
                                <div className='about_main_moudle_title'>发展历程</div>
                                <div className='about_main_moudle_li'>2015年9月，优麦科技正式成立</div>
                                <div className='about_main_moudle_li'>2015年11月，优麦医生APP正式上线，皮肤科医生价值平台启动</div>
                                <div className='about_main_moudle_li'>2016年11月，优麦科技与中国医师协会皮肤科医师分会达成战略合作</div>
                                <div className='about_main_moudle_li'>2016年12月，优麦科技与中国非公医疗协会皮肤专委会达成战略合作，成为常委级单位</div>
                                <div className='about_main_moudle_li'>2017年6月，优麦科技与中国中西医结合学会皮肤性病专委会达成战略合作</div>
                                <div className='about_main_moudle_li'>2018年3月，优麦科技联合中国人群皮肤影像资源库，发布了黄色人种皮肤肿瘤人工智能辅助诊断工具——优智AI</div>
                                <div className='about_main_moudle_li'>2021年4月，优麦皮肤互联网医院牌照获批</div>
                                <div className='about_main_moudle_li'>2021年4月，优麦科技联手国家皮肤与免疫疾病临床医学中心，成立“优麦健康教育学院”</div>
                                <div className='about_main_moudle_li'>2021年6月，优麦科技联手国家皮肤与免疫疾病临床医学中心，成立“化妆品科学评价支持委员会”</div>
                                <div className='about_main_moudle_li'>2021年9月，优麦科技旗下数字医疗创新产品皮肤图像管理软件（USKIN-001）获国家医疗器械II类证</div>
                                <div className='about_main_moudle_li'>2022年8月，优麦科技打通B2C业务链路，优麦大药房正式上线运营</div>
                                <div className='about_main_moudle_li'>2022年11月，优麦科技与上海市皮肤病医院/同济大学附属皮肤病医院共同签署战略合作协议</div>
                                <div className='about_main_moudle_li'>2023年6月，优麦医生成为2023世界皮肤科大会中国独家媒体战略合作伙伴</div>
                            </div>

                            <div className='about_main_moudle'>
                                <div className='about_main_moudle_title'>融资进程</div>
                                <div className='about_main_moudle_content'>2019年1月，优麦科技获数千万元A轮融资，本轮融资由弘盛资本领投，圆心科技（妙手医生）跟投</div>
                                <div className='about_main_moudle_content'>2021年7月，优麦科技获千万美元A+轮融资，由经纬中国独家投资</div>
                                <div className='about_main_moudle_content'>2021年11月，优麦科技获近千万美元A++轮融资，由千骥资本独家投资</div>
                            </div>

                            <div className='about_main_moudle'>
                                <div className='about_main_moudle_title'>所获荣誉</div>
                                <div className='about_main_moudle_content'>2017年5月，优麦科技参与发起中国人群皮肤影像资源库项目</div>
                                <div className='about_main_moudle_content'>2019年1月，优麦科技入选《健康报》中国十大医学科技新闻</div>
                                <div className='about_main_moudle_content'>2019年6月，优智AI荣获国家卫健委信息中心颁发的“医疗AI落地最佳应用TOP30奖项”</div>
                                <div className='about_main_moudle_content'>2022年6月，优麦科技荣获“2022未来医疗100强”</div>
                                <div className='about_main_moudle_content'>2022年12月，优麦科技所属公司上海麦色信息科技有限公司荣登“2022上海软件和信息技术服务业高成长百家”</div>
                                <div className='about_main_moudle_content'>2023年3月，优麦科技所属公司上海麦色信息科技有限公司荣获2022年上海市“专精特新”企业称号</div>
                                <div className='about_main_moudle_content'>2023年5月，优麦科技荣获 “2023未来医疗100强”</div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default aboutus;