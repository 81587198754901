import React, { Component } from "react";
import artificialintelligencebg11 from "../../images/artificialintelligencebg11.jpg";
import ReactHtmlParser from "html-react-parser";
import "./index.scss";

const html1 = `<h1 class="rich_media_title " id="activity-name">
回眸2023，致“进”时代
</h1>
<div class="rich_media_content js_underline_content defaultNoSetting" id="js_content" style="visibility: visible; >
<section class="rich_media_content_content" style="line-height: 1.8; letter-spacing: 1px; background-position: 0% 0%; background-repeat: repeat-y; background-size: 100%; background-attachment: scroll; color: rgb(107, 82, 83); background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062502944995031.jpg); box-sizing: border-box; font-style: normal; font-weight: 400; text-align: justify; font-size: 16px; visibility: visible;">
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
        <br style="box-sizing: border-box; visibility: visible;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; margin: 0px; line-height: 0; box-sizing: border-box; visibility: visible;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 650.391px; height: auto; box-sizing: border-box; visibility: visible;">
            <img class="rich_pages wxw-img" data-ratio="0.334" data-s="300,640" data-w="500" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="1" src="https://cdn.umer.com.cn/umer-doctor/20240126/2f453b70-adbc-4cb9-a1b6-97081778ba2a1706249829004.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 406.188px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px 0px -50px; box-sizing: border-box; visibility: visible;">
        <section style="font-size: 72px; color: rgba(198, 185, 166, 0.25); line-height: 1; box-sizing: border-box; visibility: visible;">
            <p style="margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
                <strong style="box-sizing: border-box; visibility: visible;">优麦科技</strong>
            </p>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px -30px 0px 0px; box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="text-align: left; margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box; visibility: visible;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box; visibility: visible;">
                    <img data-s="300,640" data-ratio="1.8" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="2" src="https://cdn.umer.com.cn/umer-doctor/20240126/ae80d850-96f5-4511-adbd-8af79a95c1931706249829007.gif" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; z-index: 1; box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                <section style="display: inline-block; width: auto; vertical-align: top; align-self: flex-start; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; box-sizing: border-box; visibility: visible;">
                    <section powered-by="xiumi.us" style="text-align: justify; font-size: 80px; line-height: 1.15; box-sizing: border-box; visibility: visible;">
                        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <strong style="box-sizing: border-box; visibility: visible;">2023</strong>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; margin: 0px; box-sizing: border-box; visibility: visible;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="margin: 0px; text-align: center; box-sizing: border-box; visibility: visible;">
                                <section style="background-color: rgb(107, 82, 83); height: 1px; box-sizing: border-box; visibility: visible;">
                                    <svg viewbox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; padding: 0px 10px; line-height: 0; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="text-align: center; box-sizing: border-box; visibility: visible;">
                                <section style="display: inline-block; width: 10px; height: 10px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 100%; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(216, 203, 177); box-sizing: border-box; visibility: visible;">
                                    <svg viewbox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box; visibility: visible;">
                                <section style="background-color: rgb(107, 82, 83); height: 1px; box-sizing: border-box; visibility: visible;">
                                    <svg viewbox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 0px 0px -30px; box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="text-align: right; margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box; visibility: visible;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box; visibility: visible;">
                    <img class="rich_pages wxw-img" data-ratio="1.8" data-s="300,640" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="3" src="https://cdn.umer.com.cn/umer-doctor/20240126/ef45db30-4f2a-49ff-a98e-8b5941c4a8a21706249828961.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
        <br style="box-sizing: border-box; visibility: visible;"/>
    </p>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
        <br style="box-sizing: border-box; visibility: visible;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px 10px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062507384979791.png); box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box; visibility: visible;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                    <br style="box-sizing: border-box; visibility: visible;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                <section style="display: inline-block; width: 1024px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; padding: 40px 20px; background-color: rgb(255, 255, 255); box-sizing: border-box; visibility: visible;">
                    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                        <section style="display: inline-block; vertical-align: top; width: auto; align-self: flex-start; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 0px 0px -10px; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="box-sizing: border-box; visibility: visible;">
                                <section style="display: inline-block; width: 15px; height: 15px; vertical-align: top; overflow: hidden; background-color: rgb(107, 82, 83); line-height: 0; box-sizing: border-box; visibility: visible;">
                                    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box; visibility: visible;">
                                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 15px; height: auto; box-sizing: border-box; visibility: visible;">
                                            <img data-s="300,640" data-ratio="1" data-w="300" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="4" src="https://cdn.umer.com.cn/umer-doctor/20240126/529c1b31-3b56-4ab5-b77f-6a954db278b71706249828963.gif" class="__bg_gif" _width="100%" data-order="0" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 15px !important; height: auto !important; visibility: visible !important;"/>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: top; width: auto; align-self: flex-start; flex: 100 100 0%; height: auto; margin: 2px 0px 0px; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box; visibility: visible;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box; visibility: visible;">
                                    <svg viewbox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; padding: 0px 20px; box-sizing: border-box; visibility: visible;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <span style="font-size: 14px; box-sizing: border-box; visibility: visible;">新年，总是和进步连在一起。<br style="box-sizing: border-box; visibility: visible;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <br style="box-sizing: border-box; visibility: visible;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <span style="font-size: 14px; box-sizing: border-box; visibility: visible;">进步，是一种提高。那么进化呢？答案是，一种演变。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <br style="box-sizing: border-box; visibility: visible;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <span style="font-size: 14px; box-sizing: border-box; visibility: visible;">在即将过去的2023年里，优麦科技一直在变，却初心不变。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <span style="font-size: 14px; box-sizing: border-box; visibility: visible;"><br style="box-sizing: border-box; visibility: visible;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            <span style="font-size: 14px; box-sizing: border-box; visibility: visible;"><br style="box-sizing: border-box; visibility: visible;"/></span>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="transform: rotateZ(355deg); box-sizing: border-box; visibility: visible;">
                        <section style="justify-content: center; display: flex; flex-flow: row; opacity: 1; margin: -20px 0px 20px; box-sizing: border-box; visibility: visible;">
                            <section style="display: inline-block; width: 885.594px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; box-sizing: border-box; visibility: visible;">
                                <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                                    <section style="display: inline-block; width: 885.594px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; margin: 0px; border-radius: 5px; overflow: hidden; background-position: 0% 0%; background-repeat: repeat-x; background-size: auto 100%; background-attachment: scroll; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062503267435000.png); box-sizing: border-box; visibility: visible;">
                                        <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 8px 0px; box-sizing: border-box; visibility: visible;">
                                            <section style="display: inline-block; width: 500px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; border-style: solid; border-width: 1px; border-color: rgb(0, 0, 0); border-radius: 5px; overflow: hidden; box-sizing: border-box; visibility: visible;">
                                                <section powered-by="xiumi.us" style="margin: 5px 0px; box-sizing: border-box; visibility: visible;">
                                                    <section style="padding: 0px 4px; font-size: 14px; color: rgb(0, 0, 0); letter-spacing: 1px; box-sizing: border-box; visibility: visible;">
                                                        <p style="margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            回眸2023，我们守正创新——
                                                        </p>
                                                        <p style="margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            &nbsp;“U直播”落地年会场景，“学术进口”牵手世皮会、欧皮会，“洞察报告”剖析行业现状与趋势……
                                                        </p>
                                                    </section>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="transform: rotateZ(5deg); box-sizing: border-box; visibility: visible;">
                        <section style="justify-content: center; display: flex; flex-flow: row; opacity: 1; margin: 20px 0px; box-sizing: border-box; visibility: visible;">
                            <section style="display: inline-block; width: 885.594px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; box-sizing: border-box; visibility: visible;">
                                <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                                    <section style="display: inline-block; width: 885.594px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; margin: 0px; border-radius: 5px; overflow: hidden; background-position: 0% 0%; background-repeat: repeat-x; background-size: auto 100%; background-attachment: scroll; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062503507279068.png); box-sizing: border-box; visibility: visible;">
                                        <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 8px 0px; box-sizing: border-box; visibility: visible;">
                                            <section style="display: inline-block; width: 500px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; border-style: solid; border-width: 1px; border-color: rgb(0, 0, 0); border-radius: 5px; overflow: hidden; box-sizing: border-box; visibility: visible;">
                                                <section powered-by="xiumi.us" style="margin: 5px 0px; box-sizing: border-box; visibility: visible;">
                                                    <section style="padding: 0px 4px; font-size: 14px; color: rgb(0, 0, 0); letter-spacing: 1px; box-sizing: border-box; visibility: visible;">
                                                        <p style="margin: 0px 0px 10px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            回眸2023，我们初心依旧——
                                                        </p>
                                                        <p style="margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            深耕皮肤领域，致力于打造不断完善的中国皮肤科医生价值平台。
                                                        </p>
                                                    </section>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="transform: rotateZ(355deg); box-sizing: border-box; visibility: visible;">
                        <section style="justify-content: center; display: flex; flex-flow: row; opacity: 1; margin: -10px 0px 20px; box-sizing: border-box; visibility: visible;">
                            <section style="display: inline-block; width: 885.594px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; box-sizing: border-box; visibility: visible;">
                                <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                                    <section style="display: inline-block; width: 885.594px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; margin: 0px; border-radius: 5px; overflow: hidden; background-position: 0% 0%; background-repeat: repeat-x; background-size: auto 100%; background-attachment: scroll; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250382335273.png); box-sizing: border-box; visibility: visible;">
                                        <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; margin: 8px 0px; box-sizing: border-box; visibility: visible;">
                                            <section style="display: inline-block; width: 500px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; border-style: solid; border-width: 1px; border-color: rgb(0, 0, 0); border-radius: 5px; overflow: hidden; box-sizing: border-box; visibility: visible;">
                                                <section powered-by="xiumi.us" style="margin: 5px 0px; box-sizing: border-box; visibility: visible;">
                                                    <section style="padding: 0px 4px; font-size: 14px; color: rgb(0, 0, 0); letter-spacing: 1px; box-sizing: border-box; visibility: visible;">
                                                        <p style="margin: 0px 0px 10px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            回眸2023，
                                                        </p>
                                                        <p style="margin: 0px 0px 10px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            我们感恩岁月，
                                                        </p>
                                                        <p style="margin: 0px; padding: 0px; box-sizing: border-box; visibility: visible;">
                                                            我们致“进”时代。
                                                        </p>
                                                    </section>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: right; justify-content: flex-end; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 100 100 0%; height: auto; margin: 0px 0px 2px; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box; visibility: visible;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box; visibility: visible;">
                                    <svg viewbox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px -10px 0px 0px; box-sizing: border-box; visibility: visible;">
                            <section powered-by="xiumi.us" style="transform: perspective(0px); transform-style: flat; box-sizing: border-box; visibility: visible;">
                                <section style="text-align: left; transform: rotateX(180deg) rotateY(180deg); box-sizing: border-box; visibility: visible;">
                                    <section style="display: inline-block; width: 15px; height: 15px; vertical-align: top; overflow: hidden; background-color: rgb(107, 82, 83); line-height: 0; box-sizing: border-box; visibility: visible;">
                                        <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box; visibility: visible;">
                                            <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 15px; height: auto; box-sizing: border-box; visibility: visible;">
                                                <img data-s="300,640" data-ratio="1" data-w="300" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="5" src="https://cdn.umer.com.cn/umer-doctor/20240126/8cd78871-321f-4775-bba5-33ae330b39bb1706249828968.gif" class="__bg_gif" _width="100%" data-order="1" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 15px !important; height: auto !important; visibility: visible !important;"/>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 10px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062504042079965.png); box-sizing: border-box; visibility: visible;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box; visibility: visible;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                    <br style="box-sizing: border-box; visibility: visible;"/>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
        <br style="box-sizing: border-box; visibility: visible;"/>
    </p>
    <section powered-by="xiumi.us" style="display: flex; width: calc(100% - 76px); margin-left: 35px; flex-flow: column; box-sizing: border-box; visibility: visible;">
        <section powered-by="xiumi.us" style="z-index: 1; box-sizing: border-box; visibility: visible;">
            <section style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box; visibility: visible;">
                <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; line-height: 1; overflow: hidden; margin: 0px 0px 0px 10px; box-sizing: border-box; visibility: visible;">
                    <section powered-by="xiumi.us" style="text-align: justify; font-size: 72px; line-height: 1; color: rgb(216, 203, 177); box-sizing: border-box; visibility: visible;">
                        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            2
                        </p>
                        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box; visibility: visible;">
                            0
                        </p>
                        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                            2
                        </p>
                        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                            3
                        </p>
                    </section>
                </section>
                <section style="display: inline-block; vertical-align: top; width: 20px; flex: 0 0 auto; height: auto; line-height: 1.8; z-index: 1; margin: 0px 0px 0px -20px; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; align-self: stretch; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062504211885575.png); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block; vertical-align: top; width: 20px; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; flex: 0 0 auto; height: auto; z-index: 1; margin: 0px -20px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062504445727456.png); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; padding: 0px; margin: 0px; background-position: 50% 50%; background-repeat: no-repeat; background-size: cover; background-attachment: scroll; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062504617944834.jpg); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; width: 989.812px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; background-color: rgba(255, 255, 255, 0.75); padding: 20px; margin: 49.4844px 0px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                                <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                                    <strong style="box-sizing: border-box;">我们“麦”步向前</strong>
                                </p>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                </section>
                <section style="display: inline-block; vertical-align: middle; width: 50px; align-self: center; flex: 0 0 auto; height: auto; z-index: 1; margin: 0px 0px 0px -20px; box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="text-align: right; margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                            <img data-s="300,640" data-ratio="1.8" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="6" src="https://cdn.umer.com.cn/umer-doctor/20240126/e7d42351-eedb-4e08-b56c-4d29f917ecda1706249829003.gif" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 100 100 0%; height: auto; margin: 0px -20px -35px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                    <img data-s="300,640" data-ratio="1.8" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="7" src="https://cdn.umer.com.cn/umer-doctor/20240126/12ac1988-a9e2-4d2c-991e-ed3a3b87464b1706249828983.gif" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; z-index: 1; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <span style="font-size: 32px; box-sizing: border-box;"><strong style="box-sizing: border-box;">荣誉</strong></span>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="font-size: 40px; color: rgb(236, 154, 144); line-height: 1; text-align: center; text-shadow: rgb(255, 255, 255) 1px 1px 0px, rgb(255, 255, 255) 1px -1px 0px, rgb(255, 255, 255) -1px 1px 0px, rgb(255, 255, 255) -1px -1px 0px, rgb(255, 255, 255) 0px 1.1875px 0px, rgb(255, 255, 255) 0px -1.1875px 0px, rgb(255, 255, 255) -1.1875px 0px 0px, rgb(255, 255, 255) 1.1875px 0px 0px, rgba(198, 185, 166, 0.25) 5px 5px 0px; letter-spacing: 0px; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <em style="box-sizing: border-box;"><strong style="box-sizing: border-box;">01</strong></em>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="transform: perspective(0px); transform-style: flat; box-sizing: border-box;">
        <section style="text-align: center; margin: 0px; line-height: 0; transform: rotateY(180deg); box-sizing: border-box;">
            <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 1084px; height: auto; box-sizing: border-box;">
                <img class="rich_pages wxw-img" data-ratio="0.12781954887218044" data-s="300,640" data-w="399" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="8" src="https://cdn.umer.com.cn/umer-doctor/20240126/0b05b813-bc62-456b-8119-9034fa7479221706249828974.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px 10px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062511395234217.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; width: 1024px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; padding: 20px; background-color: rgb(255, 255, 255); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; margin: 10px 0px; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; padding: 0px 10px; line-height: 0; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="box-sizing: border-box;">
                                <section style="display: inline-block; width: 10px; height: 10px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 100%; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(216, 203, 177); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box;">
                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
                            <img class="rich_pages wxw-img" data-ratio="0.6703629032258065" data-s="300,640" data-w="992" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="9" src="https://cdn.umer.com.cn/umer-doctor/20240126/78efc0e1-01d4-41e6-a270-a4fa147bc7051706249828981.png" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 577px !important; height: auto !important; visibility: visible !important;"/>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; padding: 0px; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;">● 上海市经济和信息化委员会公示“2022年上海市专精特新中小企业（第二批）”名单和复核通过名单。优麦科技所属公司上海麦色信息科技有限公司荣获2022年上海市“专精特新”企业称号。<br style="box-sizing: border-box;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;">●&nbsp;第七届未来医疗100强大会，“2023未来医疗100强·中国创新医疗服务榜”评选结果发布，优麦科技位列第50！这是优麦科技在“未来医疗100强”榜单上梅开二度。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;">●&nbsp;上海市经济和信息化委员会公示“2023上海软件和信息技术服务业百强”、 “2023上海软件和信息技术服务业高成长百家” 企业名单。优麦科技所属公司上海麦色信息科技有限公司荣登“2023上海软件和信息技术服务业高成长百家”，排名88位。这是自2021年以来，上海麦色信息科技有限公司连续3年入选该榜单。</span>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px 0px 0px -20px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; margin: 0px 10px; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="font-size: 12px; text-align: justify; color: rgb(216, 203, 177); box-sizing: border-box;">
                                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                    优麦科技
                                </p>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px -20px 0px 0px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 10px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250511808870.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 0px 0px 10px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="font-size: 40px; color: rgb(236, 154, 144); line-height: 1; text-align: center; text-shadow: rgb(255, 255, 255) 1px 1px 0px, rgb(255, 255, 255) 1px -1px 0px, rgb(255, 255, 255) -1px 1px 0px, rgb(255, 255, 255) -1px -1px 0px, rgb(255, 255, 255) 0px 1.1875px 0px, rgb(255, 255, 255) 0px -1.1875px 0px, rgb(255, 255, 255) -1.1875px 0px 0px, rgb(255, 255, 255) 1.1875px 0px 0px, rgba(198, 185, 166, 0.25) 5px 5px 0px; letter-spacing: 0px; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <strong style="box-sizing: border-box;"><em style="box-sizing: border-box;">02</em></strong>
                    </p>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 0px 0px 10px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; font-size: 32px; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <strong style="box-sizing: border-box;"><span style="box-sizing: border-box;">奋进</span></strong>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 100 100 0%; height: auto; margin: 0px 0px -35px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: right; margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                    <img class="rich_pages wxw-img" data-ratio="1.8" data-s="300,640" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="10" src="https://cdn.umer.com.cn/umer-doctor/20240126/fe1c9d6c-ad69-4984-8b54-a4bbbef5833f1706249828949.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 1084px; height: auto; box-sizing: border-box;">
            <img class="rich_pages wxw-img" data-ratio="0.12781954887218044" data-s="300,640" data-w="399" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="11" src="https://cdn.umer.com.cn/umer-doctor/20240126/5533d2ed-d912-4a5f-b6c6-3b0fbb8380ea1706249829151.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px 10px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062505300775451.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; width: 1024px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; padding: 20px; background-color: rgb(255, 255, 255); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; margin: 10px 0px; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; padding: 0px 10px; line-height: 0; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="box-sizing: border-box;">
                                <section style="display: inline-block; width: 10px; height: 10px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 100%; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(216, 203, 177); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="margin: 10px 0px; box-sizing: border-box;">
                        <section style="display: inline-block; width: 984px; vertical-align: top; overflow: hidden; align-self: flex-start; box-sizing: border-box;">
                            <svg style="display: block;box-sizing: border-box;" viewbox="0 0 1080 723" xml:space="default" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                <g style="box-sizing: border-box;">
                                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062505508227060.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 723" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                    </foreignobject>
                                </g>
                                <g style="box-sizing: border-box;">
                                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250601285415.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 723" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                    </foreignobject>
                                    <animate begin="0s" fill="freeze" dur="6s" values="1;1;1;1;1;1;0" restart="never" keytimes="0;0.25;0.33;0.58;0.67;0.92;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                                </g>
                                <g style="box-sizing: border-box;">
                                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062506173523773.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 723" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                    </foreignobject>
                                    <animate begin="0s" fill="freeze" dur="6s" values="1;1;1;1;0;0;0" restart="never" keytimes="0;0.25;0.33;0.58;0.67;0.92;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                                </g>
                                <g style="box-sizing: border-box;">
                                    <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                        <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062506569562729.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 723" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                    </foreignobject>
                                    <animate begin="0s" fill="freeze" dur="6s" values="1;1;0;0;0;0;0" restart="never" keytimes="0;0.25;0.33;0.58;0.67;0.92;1.00" attributename="opacity" repeatcount="indefinite" style="box-sizing: border-box;"></animate>
                                </g>
                            </svg>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; padding: 0px; font-size: 15px; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            ●&nbsp;上海医药行业协会化妆品专业委员会成立，优麦科技成为副主委单位。<br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            ●&nbsp;北京长江药学发展基金会银屑病防治研究教育专项基金管理第七届委员会换届会（全体委员工作会）召开，优麦科技成为副主委单位。
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            ● 中国非公立医疗机构协会皮肤专业委员会二届换届选举会议暨三届一次全委会议召开，优麦科技成为中国非公医协皮肤专委会副主委单位。
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px 0px 0px -20px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; margin: 0px 10px; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="font-size: 12px; text-align: justify; color: rgb(216, 203, 177); box-sizing: border-box;">
                                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                    优麦科技
                                </p>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px -20px 0px 0px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 10px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062507034909465.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 100 100 0%; height: auto; margin: 0px -20px -35px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                    <img data-s="300,640" data-ratio="1.8" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="12" src="https://cdn.umer.com.cn/umer-doctor/20240126/88bf06ee-48bd-459d-85d3-b05411829cee1706249828973.gif" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; z-index: 1; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <strong style="box-sizing: border-box;"><span style="font-size: 32px; box-sizing: border-box;">坚守</span></strong>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="font-size: 40px; color: rgb(236, 154, 144); line-height: 1; text-align: center; text-shadow: rgb(255, 255, 255) 1px 1px 0px, rgb(255, 255, 255) 1px -1px 0px, rgb(255, 255, 255) -1px 1px 0px, rgb(255, 255, 255) -1px -1px 0px, rgb(255, 255, 255) 0px 1.1875px 0px, rgb(255, 255, 255) 0px -1.1875px 0px, rgb(255, 255, 255) -1.1875px 0px 0px, rgb(255, 255, 255) 1.1875px 0px 0px, rgba(198, 185, 166, 0.25) 5px 5px 0px; letter-spacing: 0px; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <em style="box-sizing: border-box;"><strong style="box-sizing: border-box;">03</strong></em>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="transform: perspective(0px); transform-style: flat; box-sizing: border-box;">
        <section style="text-align: center; margin: 0px; line-height: 0; transform: rotateY(180deg); box-sizing: border-box;">
            <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 1084px; height: auto; box-sizing: border-box;">
                <img class="rich_pages wxw-img" data-ratio="0.12781954887218044" data-s="300,640" data-w="399" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="13" src="https://cdn.umer.com.cn/umer-doctor/20240126/b62c7e3f-1759-4995-9c76-c308d0915f9a1706249828966.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px 10px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250765730565.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; width: 1024px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; padding: 20px; background-color: rgb(255, 255, 255); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; margin: 10px 0px; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; padding: 0px 10px; line-height: 0; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="box-sizing: border-box;">
                                <section style="display: inline-block; width: 10px; height: 10px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 100%; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(216, 203, 177); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                        <section style="display: inline-block; width: 984px; vertical-align: top; align-self: flex-start; border-style: solid; border-width: 0px; border-color: rgb(62, 62, 62); box-sizing: border-box;">
                            <svg viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" style="pointer-events: none;display: block;-webkit-tap-highlight-color: transparent;user-select: none;box-sizing: border-box;-webkit-user-select: none;">
                                <g transform="translate(540 360)" style="box-sizing: border-box;">
                                    <g style="box-sizing: border-box;">
                                        <g transform="translate(-540 -360)" style="box-sizing: border-box;">
                                            <g style="box-sizing: border-box;">
                                                <g transform="translate(1100 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062508089918630.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509017768639.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062508089918630.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                                <animatetransform type="translate" attributename="transform" begin="0s" dur="10" calcmode="spline" fill="freeze" values="0 0;0 0;0 0;1100 0;1100 0;1100 0;1100 0;2200 0;2200 0;2200 0;2200 0;3300 0;3300 0;3300 0;3300 0;4400 0;4400 0" keytimes="0;0.20;0.21;0.24;0.25;0.45;0.47;0.49;0.50;0.70;0.72;0.74;0.75;0.95;0.97;0.99;1.00" keysplines="0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0" repeatcount="indefinite" style="box-sizing: border-box;"></animatetransform>
                                                <rect style="pointer-events: painted;box-sizing: border-box;" width="1080" height="720" fill="transparent"></rect>
                                                <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509344012820.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062508089918630.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509344012820.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                                <g transform="translate(-1100 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509658609841.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509344012820.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509658609841.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                                <g transform="translate(-2200 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509017768639.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509658609841.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509017768639.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                                <g transform="translate(-3300 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062508089918630.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509017768639.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062508089918630.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                                <g transform="translate(-4400 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509344012820.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062508089918630.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509344012820.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                                <g transform="translate(-5500 0)" style="box-sizing: border-box;">
                                                    <g transform="translate(0 -740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509658609841.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 0)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509344012820.png);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                    <g transform="translate(0 740)" style="box-sizing: border-box;">
                                                        <foreignobject width="100%" height="100%" x="0" y="0" style="box-sizing: border-box;">
                                                            <svg space="default" style="width: 100%;background-position: 0% 0%;background-repeat: no-repeat;background-size: 100%;background-attachment: scroll;-webkit-tap-highlight-color: transparent;user-select: none;visibility: visible;pointer-events: none;background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062509658609841.jpg);box-sizing: border-box;-webkit-user-select: none;" viewbox="0 0 1080 720" xlink="http://www.w3.org/1999/xlink" xml="" xmlns="http://www.w3.org/2000/svg"></svg>
                                                        </foreignobject>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <animatetransform type="scale" attributename="transform" begin="0s" additive="sum" calcmode="spline" fill="freeze" dur="10" keytimes="0;0.20;0.21;0.24;0.25;0.45;0.47;0.49;0.50;0.70;0.72;0.74;0.75;0.95;0.97;0.99;1.00" values="1;1;0.7;0.7;1;1;0.7;0.7;1;1;0.7;0.7;1;1;0.7;0.7;1" keysplines="0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0;0.421 0 0.581 1.0" repeatcount="indefinite" style="box-sizing: border-box;"></animatetransform>
                                    </g>
                                </g>
                            </svg>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; padding: 0px; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;">●&nbsp;优麦医生重磅打造品牌学术栏目“年会U直播”，亮相皮肤科学术年会。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;">- 中国医师协会皮肤科医师分会×优麦医生，联手呈现45场直播访谈。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;">- 中华医学会皮肤性病学分会×优麦医生，联手呈现45场直播访谈。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;">- 中国中西医结合学会皮肤性病专业委员会×优麦医生，联手呈现30场直播访谈。</span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 14px; box-sizing: border-box;">- 中国非公立医疗机构协会皮肤专业委员会×优麦医生，联手呈现25场直播访谈。</span>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px 0px 0px -20px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; margin: 0px 10px; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="font-size: 12px; text-align: justify; color: rgb(216, 203, 177); box-sizing: border-box;">
                                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                    优麦科技
                                </p>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px -20px 0px 0px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 10px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706251002925894.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 0px 0px 10px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="font-size: 40px; color: rgb(236, 154, 144); line-height: 1; text-align: center; text-shadow: rgb(255, 255, 255) 1px 1px 0px, rgb(255, 255, 255) 1px -1px 0px, rgb(255, 255, 255) -1px 1px 0px, rgb(255, 255, 255) -1px -1px 0px, rgb(255, 255, 255) 0px 1.1875px 0px, rgb(255, 255, 255) 0px -1.1875px 0px, rgb(255, 255, 255) -1.1875px 0px 0px, rgb(255, 255, 255) 1.1875px 0px 0px, rgba(198, 185, 166, 0.25) 5px 5px 0px; letter-spacing: 0px; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <strong style="box-sizing: border-box;"><em style="box-sizing: border-box;">04</em></strong>
                    </p>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 0px 0px 10px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <strong style="box-sizing: border-box;"><span style="font-size: 32px; box-sizing: border-box;">出海</span></strong>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 100 100 0%; height: auto; margin: 0px 0px -35px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: right; margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                    <img class="rich_pages wxw-img" data-ratio="1.8" data-s="300,640" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="14" src="https://cdn.umer.com.cn/umer-doctor/20240126/ee25abe5-b694-4770-9b4f-f386e0c6f7e31706249828955.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: center; margin: 0px; line-height: 0; box-sizing: border-box;">
        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 1084px; height: auto; box-sizing: border-box;">
            <img class="rich_pages wxw-img" data-ratio="0.12781954887218044" data-s="300,640" data-w="399" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="15" src="https://cdn.umer.com.cn/umer-doctor/20240126/ce00be23-d209-430f-975e-7b9a250f9efb1706249828946.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px 10px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250765730565.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; width: 1024px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; padding: 20px; background-color: rgb(255, 255, 255); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; margin: 10px 0px; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; padding: 0px 10px; line-height: 0; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="box-sizing: border-box;">
                                <section style="display: inline-block; width: 10px; height: 10px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 100%; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(216, 203, 177); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box;">
                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
                            <img class="rich_pages wxw-img" data-ratio="0.562962962962963" data-s="300,640" data-w="1080" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="16" src="https://cdn.umer.com.cn/umer-doctor/20240126/f96c31ce-8c86-49b7-9d8a-95f7b3e7a4121706249829112.jpeg" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 577px !important; height: auto !important; visibility: visible !important;"/>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; padding: 0px; font-size: 15px; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            ●&nbsp;优麦医生成为2023世界皮肤科大会（WCD2023）中国独家媒体战略合作伙伴。<br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            ●&nbsp;优麦医生受邀成为2023年第32届欧洲皮肤病与性病学会年会（EADV2023）支持媒体。
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            ●&nbsp;优麦医生APP海外应用市场上架。
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px 0px 0px -20px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; margin: 0px 10px; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="font-size: 12px; text-align: justify; color: rgb(216, 203, 177); box-sizing: border-box;">
                                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                    优麦科技
                                </p>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px -20px 0px 0px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 10px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706251002925894.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 100 100 0%; height: auto; margin: 0px -20px -35px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                    <img data-s="300,640" data-ratio="1.8" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="17" src="https://cdn.umer.com.cn/umer-doctor/20240126/c920d30b-590f-43fb-9c93-8fe093c022961706249828981.gif" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; z-index: 1; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; font-size: 32px; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <strong style="box-sizing: border-box;">深耕</strong>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                <section style="font-size: 40px; color: rgb(236, 154, 144); line-height: 1; text-align: center; text-shadow: rgb(255, 255, 255) 1px 1px 0px, rgb(255, 255, 255) 1px -1px 0px, rgb(255, 255, 255) -1px 1px 0px, rgb(255, 255, 255) -1px -1px 0px, rgb(255, 255, 255) 0px 1.1875px 0px, rgb(255, 255, 255) 0px -1.1875px 0px, rgb(255, 255, 255) -1.1875px 0px 0px, rgb(255, 255, 255) 1.1875px 0px 0px, rgba(198, 185, 166, 0.25) 5px 5px 0px; letter-spacing: 0px; box-sizing: border-box;">
                    <p style="margin: 0px; padding: 0px; box-sizing: border-box;">
                        <em style="box-sizing: border-box;"><strong style="box-sizing: border-box;">05</strong></em>
                    </p>
                </section>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="transform: perspective(0px); transform-style: flat; box-sizing: border-box;">
        <section style="text-align: center; margin: 0px; line-height: 0; transform: rotateY(180deg); box-sizing: border-box;">
            <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 1084px; height: auto; box-sizing: border-box;">
                <img class="rich_pages wxw-img" data-ratio="0.12781954887218044" data-s="300,640" data-w="399" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="18" src="https://cdn.umer.com.cn/umer-doctor/20240126/61fd0837-6e7c-4973-86c9-76cd3a4b7bea1706249828968.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 677px !important; height: auto !important; visibility: visible !important;"/>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px 10px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250765730565.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; width: 1024px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; padding: 20px; background-color: rgb(255, 255, 255); box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; margin: 10px 0px; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; height: auto; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; padding: 0px 10px; line-height: 0; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="box-sizing: border-box;">
                                <section style="display: inline-block; width: 10px; height: 10px; vertical-align: top; overflow: hidden; border-width: 0px; border-radius: 100%; border-style: none; border-color: rgb(62, 62, 62); background-color: rgb(216, 203, 177); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 100 100 0%; height: auto; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="margin-top: 10px; margin-bottom: 10px; line-height: 0; box-sizing: border-box;">
                        <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; box-sizing: border-box;">
                            <img class="rich_pages wxw-img" data-ratio="0.562037037037037" data-s="300,640" data-w="1080" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="19" src="https://cdn.umer.com.cn/umer-doctor/20240126/de583428-6fa2-4258-a1d7-01b198f07e5a1706249829066.jpeg" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 577px !important; height: auto !important; visibility: visible !important;"/>
                        </section>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; padding: 0px; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <span style="font-size: 15px; box-sizing: border-box;">●&nbsp;</span>优麦医生联合中国多位深耕功效性护肤领域的皮肤科专家发布业内首份《2022中国皮肤科功效性护肤品洞察报告解读》。
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <br style="box-sizing: border-box;"/>
                        </p>
                    </section>
                    <section powered-by="xiumi.us" style="justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px 0px 0px -20px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; margin: 0px 10px; align-self: center; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="font-size: 12px; text-align: justify; color: rgb(216, 203, 177); box-sizing: border-box;">
                                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                                    优麦科技
                                </p>
                            </section>
                        </section>
                        <section style="display: inline-block; vertical-align: middle; width: auto; flex: 100 100 0%; align-self: center; height: auto; margin: 0px -20px 0px 0px; box-sizing: border-box;">
                            <section powered-by="xiumi.us" style="margin: 0px; box-sizing: border-box;">
                                <section style="border-top: 1px dashed rgb(107, 82, 83); box-sizing: border-box;">
                                    <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 10px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706251002925894.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="box-sizing: border-box;">
        <p style="margin: 0px 0px 10px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
            <strong style="box-sizing: border-box;">优麦医生APP</strong><br style="box-sizing: border-box;"/>
        </p>
        <p style="margin: 0px 0px 10px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
            <strong style="box-sizing: border-box;">用户总数21万+</strong>
        </p>
        <p style="margin: 0px 0px 10px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
            <strong style="box-sizing: border-box;">年用户时长 (分钟) 7,000万+</strong>
        </p>
        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
            <span style="font-size: 12px; box-sizing: border-box;">数据统计截至2023年末</span>
        </p>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="box-sizing: border-box;"/>
    </p>
    <section powered-by="xiumi.us" style="transform: perspective(0px); transform-style: flat; box-sizing: border-box;">
        <section style="text-align: left; margin: 0px; line-height: 0; transform: rotateY(180deg); box-sizing: border-box;">
            <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 542px; height: auto; box-sizing: border-box;">
                <img class="rich_pages wxw-img" data-ratio="0.334" data-s="300,640" data-w="500" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="20" src="https://cdn.umer.com.cn/umer-doctor/20240126/d7ddb9be-8009-4cb4-919f-c48679be0b321706249829006.gif" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 338.5px !important; height: auto !important; visibility: visible !important;"/>
            </section>
        </section>
    </section>
    <section powered-by="xiumi.us" style="text-align: left; justify-content: flex-start; display: flex; flex-flow: row; box-sizing: border-box;">
        <section style="display: inline-block; vertical-align: middle; width: 50px; align-self: center; flex: 0 0 auto; height: auto; line-height: 1; margin: 0px -20px 0px 0px; z-index: 1; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="margin: 0px; line-height: 0; opacity: 1; box-sizing: border-box;">
                <section style="max-width: 100%; vertical-align: middle; display: inline-block; line-height: 0; width: 50px; height: auto; box-sizing: border-box;">
                    <img data-s="300,640" data-ratio="1.8" data-w="50" data-original-style="vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;" data-index="21" src="https://cdn.umer.com.cn/umer-doctor/20240126/3416f187-caa4-42b6-816d-02007d779c9b1706249828987.gif" class="" _width="100%" crossorigin="anonymous" alt="图片" data-fail="0" style="max-width: 100%; vertical-align: middle; box-sizing: border-box; width: 50px !important; height: auto !important; visibility: visible !important;"/>
                </section>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: 200px; align-self: center; flex: 100 100 0%; height: auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: cover; background-attachment: scroll; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/17062510330781978.jpg); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
            <section powered-by="xiumi.us" style="text-align: center; justify-content: center; display: flex; flex-flow: row; box-sizing: border-box;">
                <section style="display: inline-block; width: 989.812px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; background-color: rgba(255, 255, 255, 0.75); padding: 20px; margin: 49.4844px 0px; box-sizing: border-box;">
                    <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                        <p style="margin: 0px 0px 10px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <strong style="box-sizing: border-box;"><span style="text-align: left; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span></strong>
                        </p>
                        <p style="margin: 0px 0px 10px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <strong style="box-sizing: border-box;"><span style="text-align: left; box-sizing: border-box;"><br style="box-sizing: border-box;"/></span></strong>
                        </p>
                        <p style="margin: 0px 0px 10px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <strong style="box-sizing: border-box;"><span style="text-align: left; box-sizing: border-box;">你好，2024！</span><br style="box-sizing: border-box;"/></strong>
                        </p>
                        <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                            <strong style="box-sizing: border-box;"><span style="text-align: left; box-sizing: border-box;">我们，继续“麦”进！</span></strong>
                        </p>
                    </section>
                </section>
            </section>
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; margin: 0px 0px 0px -20px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706250765730565.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: top; width: 20px; align-self: stretch; flex: 0 0 auto; background-position: 50% 50%; background-repeat: no-repeat; background-size: 100% 100%; background-attachment: scroll; height: auto; z-index: 1; margin: 0px -20px 0px 0px; background-image: url(https://cdn.umer.com.cn/umer-doctor/20240126/1706251002925894.png); box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; box-sizing: border-box;">
                <p style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <br style="box-sizing: border-box;"/>
                </p>
            </section>
        </section>
        <section style="display: inline-block; vertical-align: middle; width: auto; align-self: center; flex: 0 0 auto; min-width: 5%; max-width: 100%; height: auto; margin: 0px 10px 0px 0px; box-sizing: border-box;">
            <section powered-by="xiumi.us" style="text-align: justify; font-size: 72px; line-height: 1; color: rgb(216, 203, 177); box-sizing: border-box;">
                <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <span style="text-decoration: none; box-sizing: border-box;">2</span>
                </p>
                <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <span style="text-decoration: none; box-sizing: border-box;">0</span>
                </p>
                <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <span style="text-decoration: none; box-sizing: border-box;">2</span>
                </p>
                <p style="margin: 0px; text-align: center; white-space: normal; padding: 0px; box-sizing: border-box;">
                    <span style="text-decoration: none; box-sizing: border-box;">4</span>
                </p>
            </section>
        </section>
    </section>
    <p powered-by="xiumi.us" style="margin: 0px; white-space: normal; padding: 0px; box-sizing: border-box;">
        <br style="color: rgb(107, 82, 83); font-family: sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: 1px; orphans: 2; text-align: justify; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; box-sizing: border-box;"/>
    </p>
</section>
</div>`

class Latestdevelopments4 extends Component {
    timer = null
  constructor(props) {
    super(props);
    this.state = {
        tab2active: 0,
    }
  }

    
    
    handleLoaded() {
    }
  maxShow = 222
  render() {
    return (
      <div className="aboutussection">
        <img
          className="latestdeveimg2"
          onLoad={this.handleLoaded.bind(this)}
          src={artificialintelligencebg11}
          alt=""
        ></img>
        <div className="latestdevelopments_main latestdevelopments16_main">
            {
                ReactHtmlParser(html1, { ignoreCase: false })
            }
        </div>
      </div>
    );
  }
}

export default Latestdevelopments4;
