import React, { Component } from 'react';
import artificialintelligencebg11 from '../../images/artificialintelligencebg11.jpg'
import './index.scss';

class Latestdevelopments4 extends Component {
    timer = null

    constructor(props) {
        super(props);
        this.state = {
            tab2active: 0,
            loaded: false
        }
    }
    
    handleLoaded() {
        console.log('aaa')
        this.setState({
            loaded: true
        })

        
        clearInterval(this.timer)

        this.timer = setInterval(() => {
            let newCur = this.state.cur + 1

            if (newCur > this.state.imageGroup.length - 1) {
                newCur = 0
            }

            this.setState({
                cur: newCur
            })
        }, 2000);
    }

    maxShow = 3

    render() {
        return (
            <div className='aboutussection'>
                <img className='latestdeveimg2' onLoad={this.handleLoaded.bind(this)}  src={artificialintelligencebg11} alt=""></img>
                {
                    this.state.loaded &&
                    <div className='latestdevelopments_main'>
                        <div className='aboutussection_div'>
                            <div className='aboutussection_div_div' >优麦医生成为2023世界皮肤科大会中国独家媒体战略合作伙伴</div>
                        </div>
                        <div className='aboutus_main_div'>                            
                            <img className="latest_image" src="https://cdn.umer.com.cn/umer-doctor/20230607/16861022202518177.jpg" alt=""/>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Latestdevelopments4;