import section2zxdt from '../../images/section2zxdt.png'
import React, {Component} from 'react';
import './ReactCarousel.scss';
import section2_2 from '../../images/section2_2.png'
import zhanlue from '../../images/zhanlue.jpg'
import Bgbutton from '../Bgbutton/Bgbutton'
import medicalServiceCompanies from '../../images/medical-service-companies.jpg'
import umerBg from '../../images/umer-bg.jpg'
class ReactCarousel extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: [
                {
                    img: 'https://cdn.umer.com.cn/umer-doctor/20240718/11002119_940_1721296402270_微信图片_20240718175314.png',
                    desc: '官宣丨优麦医生与亚洲皮肤科学会（ADA）达成战略合作',
                    href: '/latestdevelopments18'
                },
                // {
                //     img: 'https://cdn.umer.com.cn/umer-doctor/20240305/17096042338604988.jpeg',
                //     desc: '重磅 | 优麦医生成为美国皮肤科学会(AAD)官方授权合作伙伴',
                //     href: '/latestdevelopments17'
                // },
                {
                    img: 'https://cdn.umer.com.cn/umer-doctor/20240126/1706256001296355.jpg',
                    desc: '“麦”进星时代！第五届皮肤科在线教育卓越影响力年度盛典举行',
                    href: '/latestdevelopments15'
                },
                {
                    img: 'https://cdn.umer.com.cn/umer-doctor/20240126/17062559726208760.jpg',
                    desc: '回眸2023，致“进”时代',
                    href: '/latestdevelopments16'
                },
                {
                    img: 'https://cdn.umer.com.cn/umer-doctor/20230828/16932029565901412.jpg',
                    desc: '中国非公医协皮肤专委会二届换届选举会议暨三届一次全委会议在南京成功召开',
                    href: '/latestdevelopments14'
                },
                // {
                //     img: 'https://cdn.umer.com.cn/umer-doctor/20230809/16915694474729575.jpeg',
                //     desc: '优麦科技常江受聘银屑病防治研究教育专项基金委员会副主任委员',
                //     href: '/latestdevelopments13'
                // }
            ],
            showIndex: 0, //显示第几个图片
            timer: null,  // 定时器
            show: false   // 前后按钮显示
        }
    }
    render(){
        return (
            <div className="ReactCarousel">
                <div className="contain" 
                     onMouseEnter={()=>{this.stop()}} //鼠标进入停止自动播放
                     onMouseLeave={()=>{this.start()}}  //鼠标退出自动播放
                >
                    <ul className="ul">
                        {
                            this.state.data.map((value, index) => {
                                return (
                                    <li className={index === this.state.showIndex ? 'show' : ''}
                                        key={index} 
                                    > 
                                        <img src={value.img} alt="轮播图" />
                                    </li>
                                )
                            })
                        }
                    </ul>

                </div>
                <div className='section2div'>
                    <div>
                        <img className='section2tabimg' src={section2zxdt} alt="" />
                        <div>
                            {
                               
                                <div>
                                    <div className='section2divs'>                            
                                    
                                        {  this.state.data[this.state.showIndex].desc }
                                                                
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                        <ul className="dots"> 
                            {
                                this.state.data.map((value, index) => {
                                    return (
                                        <li key={index}  
                                            className={index === this.state.showIndex ? 'active' : ''} 
                                            onClick={()=>{this.change(index)}}>
                                        </li>)
                                })
                            }
                        </ul>
                        <div className='section2img'>
                            {
                               <div>
                                   <div className='section2divs'>                            
                                   
                                       {  <Bgbutton txt='查看详情' href={this.state.data[this.state.showIndex].href}></Bgbutton> }
                                                               
                                   </div>
                               </div>
                            }                            
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount(){ //一开始自动播放
        this.start();
    }
    componentWillUnmount() { //销毁前清除定时器
        this.stop();
    }
    stop = () => { //暂停
        let {timer} = this.state;
        clearInterval(timer);
    }
    start = () => { //开始
        let {timer} = this.state;
        timer = setInterval(() => {
            this.next();
        }, 5000);
        this.setState({
            timer
        })
    }
    change = (index) => { //点击下面的按钮切换当前显示的图片
        let {showIndex} = this.state;
        showIndex = index;
        this.setState({
            showIndex
        })
    }
    previous = (e) => { //上一张
        let {showIndex, data} = this.state;
        if(showIndex <= 0){
            showIndex = data.length - 1;
        }else{
            showIndex --;
        }
        this.setState({
            showIndex
        })
    }
    next = (e) => { //下一张
        let {showIndex, data} = this.state;
        if(showIndex >= data.length - 1){
            showIndex = 0;
        }else{
            showIndex ++;
        }
        this.setState({
            showIndex
        })
    }
}
export default ReactCarousel;